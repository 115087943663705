// Reset
a, .inteco-body a {
    color: $link-color;
    text-decoration: none;
    cursor: pointer;
    &:hover {
        color: $link-hover-color;
        text-decoration: underline;
    }
    &.tel {
        color: $text-color;
    }
}

img {
    vertical-align: middle;
}

button {
    // Reset
    background: none;
    border: none;
    box-shadow: none;
}

textarea {
    resize: vertical;
}

.tooltip {
    .tooltip-inner {
        font-size: 1.15em;
    }
}

.visible-xs {
    display: none;
    @include mobile {
        display: block;
    }
}
.hidden-xs {
    @include mobile {
        display: none;
    }
}

#content {
    .gdlr-core-pagination {
        a:hover {
            text-decoration: none;
        }
    }
    /* Kitchen block style */
    .gdlr-core-portfolio-grid-frame {
        background: #f5f5f5 !important;
    }

    .kitchen-block {
        position: relative;
        .sale, .price {
            position: absolute;
            color: #fff;
            right: 0;
            display: block;
            line-height: normal;
            font-weight: bold;
        }

        .price {
            background: $black;
            background: $roest;
            bottom: 0;
            padding: 10px 20px;
            z-index: 10;
            font-size: 21px;
            .sold {
                font-weight: normal;
                font-size: 19px;
            }
            .old-price {
                position: relative;
                display: inline-block;
                font-weight: normal;
                margin-right: 8px;
                &:before {
                    position: absolute;
                    z-index: 2;
                    content: '';
                    left: -3px;
                    top: 50%;
                    right: 0;
                    height: 2px;
                    background-color: #fff;
                    transform: rotate(-6deg);
                    box-shadow: 0 0 10px rgba(0,0,0, .5);
                }
            }
        }

        .sale {
            background: $taupe;
            background: rgba(0, 0, 0, .8);
            top: 0;
            right: 0;
            padding: 7px 16px 9px;
            z-index: 10;
            letter-spacing: .3px;
            font-family: $heading-font;
            font-size: 14px;
        }
    }

    .gdlr-core-item-list {
        &:hover {
            .gdlr-core-portfolio-title {
                color: $link-hover-color;
            }
        }
    }
    .gdlr-core-portfolio-content-wrap {
        display: block;
        text-decoration: none !important;
        .gdlr-core-portfolio-title {
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 0;
            text-transform: none;
            color: $text-color;
        }
        .gdlr-core-skin-caption {
            color: $taupe;
        }
    }
    /* End Kitchen block style */

    .sidebar-wrapper {
        .gdlr-core-sidebar-item {
            @include mobile {
                padding: 0;
            }
            @include tablet {
                padding-right: 0;
            }
        }
        .gdlr-core-pbf-background-wrap {
            background: #f3f3f3;
        }
        .textwidget {
            font-style: italic;
        }
        .widget {
            &.subscribe-newsletter, &.info-block {
                background-color: #fff;
            }
        }
    }
    .newsletter-wrapper {
        background: $dirt;
        color: #fff;
        padding: 30px 0;
        text-align: center;
        .inteco-content-wrap {
            padding: 0 20px;
        }
        .inteco-widget-title {
            font-size: 22px;
            color: #fff;
        }
        form {
            margin-top: 30px;
            .quform-element {
                text-align: left;
                display: inline-block;
                margin-bottom: 0;

                @include mobile {
                    margin-top: 20px;
                    &:first-child {
                        margin-top: 0;
                    }
                }

                input {
                    padding: 12px !important;
                }

                button {
                    padding-top: 12px;
                    padding-bottom: 13px;
                }
            }
        }
    }
    .intro-wrapper {
        font-size: 19px;
        max-width: 990px;
        margin: 0 auto;
        a {
            color: $link-color;
        }
    }
    .cta-wrapper {
        position: relative;
        padding: 100px 0;

        @include responsive($breakpoint-tablet-min, 1500px) {
            padding: 60px 0;
        }
        .overlay {
            background: rgba(0,0,0,.7);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
        .gdlr-core-pbf-column-content-margin {
            padding-top: 0 !important;
        }
        .gdlr-core-pbf-wrapper-content {
            color: #fff !important;
            h3 {
                color: #fff !important;
                font-size: 34px;
                font-weight: 700;
                letter-spacing: 0;
                text-transform: none;
            }

            .gdlr-core-text-box-item-content {
                font-size: 18px;
                font-weight: 400;
                text-transform: none;
                max-width: 800px;
                margin: 0 auto 25px;
            }
            a.cta-button {
                font-size: 19px;
                font-weight: 700;
                letter-spacing: 0;
                color: #ffffff;
                padding: 0 0 5px;
                text-transform: none;
                border-radius: 0;
                border-width: 0 0 9px 0;
                border-color: #968481;
                transition: all .2s ease-out;

                &:hover {
                    text-decoration: none;
                    border-color: $custard;
                }

                i {
                    color: #ffffff;
                }
            }
        }
    }
    .usp-wrapper-header {
        width: 100%;
        color: #fff;
        text-align: center;
        z-index: 1000;

        .usp-items {
            width: 100%;
            margin: 0 auto;
            background: rgba(84,104,86,.75);
            padding: 15px 0;
            text-align: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            @include mobile-tablet {
                font-size: 14px;
                padding: 10px 0;
            }
            @include desktop {
                font-size: 15px;
                padding: 10px 0;
            }

            .usp {
                display: inline-block;
                padding: 0 24px;
                font-weight: 600;

                @include mobile {
                    font-size: 13px;
                    width: 100%;

                    &:last-of-type {
                        margin-right: 0;
                    }

                    @include responsive-max(500px) {
                        display: block;
                    }
                }

                @include tablet {
                    padding: 0 12px !important;
                    font-size: 13px !important;
                }

                .icon {
                    margin-right: 8px;
                    font-size: .9em;

                    @include mobile {
                        margin-right: 4px;
                    }
                }
            }
        }
    }

    .cta-info-blocks {
        margin: -15px -15px -160px;
        position: relative;
        top: -100px;
        padding-top: 100px;

        @include mobile-tablet {
            padding-top: 50px;
            top: -120px;
        }
        @include mobile {
            margin: -15px 0 -200px;
            width: 100%;
        }
    }

    .info-block-wrapper {
        width: 33.333333%;
        padding: 15px;

        @include mobile {
            width: 100%;
        }
    }

    .cta-info-block {
        background: url('/dist/img/info-block-bg.jpg') center top / cover;
        padding: 150px 30px 25px;
        position: relative;
        height: 100%;

        @include mobile {
            margin-top: 80px;
            padding-top: 70px;
            height: auto;
        }
        @include tablet {
            padding-top: 100px;
        }

        img {
            position: absolute;
            top: -135px;
            width: 300px;
            height: auto;
            left: 50%;
            margin-left: -150px;

            @include mobile {
                width: 140px;
                margin-left: -70px;
                top: -62px
            }
            @include tablet {
                width: 200px;
                margin-left: -100px;
                top: -90px
            }
        }

        .heading {
            font-size: 24px;
            line-height: 28px;
            font-weight: 600;

            @include mobile {
                font-size: 20px;
                line-height: 24px;
            }
        }

        .text {
            font-size: 17px;
            margin-top: 10px;
            flex-grow: 1;

            br {
                display: none;

                @media (min-width: 1300px) {
                    display: block;
                }
            }
        }

        a {
            font-size: 17px;
            margin-top: 15px;
            display: block;
            font-weight: 600;
            color: $roest;
        }
    }

    .info-block-wrapper-sidebar {
        margin: 120px 0 50px;
        position: relative;

        &:first-child {
            margin-top: 60px;
        }

        .cta-info-block {
            background: #fff;
            padding-top: 110px;

            @include mobile {
                margin-top: 80px;
                padding-top: 70px;
                height: auto;
            }

            img {
                position: absolute;
                width: 200px;
                margin-left: -100px;
                top: -90px;
                left: 50%;

                @include mobile {
                    width: 140px;
                    margin-left: -70px;
                    top: -62px
                }
                @include tablet {
                    width: 200px;
                    margin-left: -100px;
                    top: -90px
                }
            }

            .heading {
                font-size: 20px;
                line-height: 24px;
                font-weight: 600;
            }
        }
    }
}
