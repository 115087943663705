body {
    &.content-class-faq #content {
        .header-block {
            padding-top: 220px;
            padding-bottom: 155px;

            .gdlr-core-pbf-wrapper-content .gdlr-core-skin-title {
                font-size: 40px;
            }
            .gdlr-core-title-item-caption {
                font-size: 18px;
                padding: 0 130px;
                margin-top: 40px;

                @include mobile {
                    padding: 0;
                }
                @include tablet {
                    padding: 0 30px;
                }
            }
        }

        .gdlr-core-column-20 {
            @include mobile {
                width: 100%;
                .gdlr-core-pbf-column-content-margin {
                    margin-top: 40px !important;
                    padding: 50px 15px 25px !important;
                    min-height: 0;

                    &.first-block {
                        margin-top: 0 !important;
                    }
                }
            }
            @include tablet {
                width: 50%;
                .gdlr-core-pbf-column-content-margin {
                    margin-top: 50px !important;
                }
            }
        }

        #gdlr-core-wrapper-2 {
            padding: 220px 0 200px;
        }

        #gdlr-core-wrapper-3 {
            margin: 0;
            padding: 0 0 70px;
        }

        .gdlr-core-accordion-item-title {
            font-size: 16px;
            font-weight: 700;
            text-transform: none;
        }

        .gdlr-core-accordion-item-content p {
            font-size: 15px;
        }

        .gdlr-core-pbf-column-content-margin {
            min-height: 380px;
            margin: -80px 26px 0 0;
            padding: 70px 30px 25px 30px;

            &.second-row {
                margin-top: 50px;
            }

            .gdlr-core-pbf-background-wrap {
                box-shadow: 0 0 40px rgba(10, 10, 10, 0.08);
                -moz-box-shadow: 0 0 40px rgba(10, 10, 10, 0.08);
                -webkit-box-shadow: 0 0 40px rgba(10, 10, 10, 0.08);
                background-color: #ffffff;
            }
        }

        .gdlr-core-accordion-item-title:hover {
            color: $link-hover-color;
        }
    }
}
