body {
    &.content-class-contact #content {
        .header-block {
            padding-top: 210px;
            padding-bottom: 160px;

            @include mobile {
                padding-bottom: 90px !important;

                @include responsive-max(500px) {
                    padding-bottom: 120px !important;
                }
            }
            @include tablet {
                padding-bottom: 110px !important;
            }
        }
        .first-block {
            padding: 10px 0;

            @include mobile {
                padding: 0;
            }
        }
        .gdlr-core-social-network-item {
            margin-top: 20px;
        }
        .gdlr-core-social-network-icon {
            font-size: 19px;
            margin-left: 15px;
            color: $taupe;
            &:first-of-type {
                margin-left: 0;
            }
            &:hover {
                text-decoration: none;
                color: $dirt;
            }
        }

        .quform.contact-form {
            background: #fff;
            padding: 30px 20px;
        }

        .contact-details {
            padding: 0 20px 0 20px;

            @include mobile {
                padding-top: 0;
            }

            h3.gdlr-core-title-item-title {
                font-size: 20px;
                font-weight: 700;
                letter-spacing: 0;
                text-transform: none;
                margin-bottom: 24px;
            }
        }
    }
}
