.editor {
    // Variables
    $spacing: 45px;
    $spacing-small: 40px;
    $content-small: 38%;
    $content-medium: 55%;
    $content-large: 70%;

    > *:first-child {
        margin-top: 0 !important;
    }

   .last {
       margin-bottom: 0 !important;
       .plyr {
           margin-bottom: 20px !important;
       }
   }

    h1.gdlr-core-title-item-title {
        font-size: 34px;
        letter-spacing: 0;
        text-transform: none;
        color: #111111;
    }

    h2, h3, h4 {
        text-transform: none;
        font-weight: 600;
        margin-top: 30px;
        &:first-child {
            margin-top: 0;
        }
    }
    h2 {
        font-size: 24px;
    }
    h3 {
        font-size: 20px;
    }
    h4 {
        font-size: 18px;
    }

    img.lazyload {
        opacity: 0;
        transform: scale(0.8);
    }

    img.lazyloaded {
        opacity: 1;
        transform: scale(1);
        transition: all .2s ease-out;
    }

    .gdlr-core-skin-caption {
        font-size: 21px;
        font-weight: 700;
        font-style: normal;
        margin-bottom: 0;
    }

    .gdlr-core-title-item-caption-top {
        padding-bottom: 32px;
    }

    .clear-block {
        clear: both;
    }

    .gallery {
        clear: both;
        margin: 0 0 $spacing;

        .inteco-item-rvpdlr {
            margin-left: -15px;
            margin-right: -15px;
        }

        .gdlr-core-item-pdlr {
            padding-right: 15px;
            padding-left: 15px;
        }

        .item {
            overflow: hidden;
            margin-top: 20px;
            transition: border .4s ease-out;
            @include mobile {
                margin-top: 20px;
            }

            .image {
                margin-bottom: 0 !important;

                img {
                    z-index: 1;
                }
            }

            &:hover {
                border-color: $taupe;
            }
        }

        // Fancybox images
        a.image.hover {
            display: block;
            position: relative;
            transition: border .4s ease-out;

            &:hover {
                .overlay {
                    opacity: 1;
                }
            }

            .overlay {
                opacity: 0;
                transition: opacity .2s ease-out;
                position: absolute;
                width: 100%;
                height: 100%;
                overflow: hidden;
                background-color: rgba(0, 0, 0, .4);
                text-align: center;
                z-index: 2;

                .fa {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    $size: 100px;
                    width: $size;
                    height: $size;
                    margin-left: -($size / 2);
                    margin-top: -($size / 2);
                    text-align: center;
                    line-height: $size;
                    color: #fff;
                    opacity: .8;
                    font-size: 2em;

                    &.large {
                        font-size: 4em;
                    }
                }
            }
        }
    }

    .plyr {
        clear: both;
        margin: $spacing 0;

        @include mobile-tablet {
            margin: $spacing-small;
            width: 100% !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }

    .contentimage {
        $smallwidth: 35%;
        position: relative;

        img {
            width: 100%;
        }

        &.full {
            clear: both;
            width: 100% !important;
            margin: $spacing 0;
            @include mobile-tablet {
                margin: $spacing-small 0;
            }
        }

        &.left {
            float: left;
            margin: 6px $spacing 20px 0;
            @include mobile-tablet {
                margin: 6px $spacing-small 20px 0;
            }
        }

        &.right {
            float: right;
            margin: 6px 0 20px $spacing;
            @include mobile-tablet {
                margin: 6px 0 20px $spacing-small;
            }
        }

        &.center {
            clear: both;
            margin: $spacing auto;
            text-align: center;
            @include mobile-tablet {
                margin: $spacing-small auto;
            }
        }

        &.small {
            width: $content-small;
        }

        &.medium {
            width: $content-medium;
        }

        &.large {
            width: $content-large;
        }

        &.original {
            width: auto !important;

            img {
                width: auto !important;

                &:after {
                    clear: both;
                }
            }

            &:after {
                clear: both;
            }
        }

        @include mobile {
            width: 100% !important;
            float: none !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
            display: block;
        }
    }

    blockquote {
        .author {
            margin-top: 10px;

            .content {
                .name {
                    font-style: normal;
                    font-family: $default-font;
                    font-weight: normal;
                    color: $taupe;
                }
            }
        }
    }

    a.link {
        clear: both;
        display: table; // Margins fix
        margin: 20px 0 ($spacing / 1.5);
        color: $link-color;
        font-weight: 600;

        span {
            margin-left: 8px;
            display: inline-block;
        }
    }

    .button {
        clear: both;
        display: table; // Margins fix
        margin: ($spacing / 1.5) 0;

        @include mobile-tablet {
            margin: 28px 0;
        }
    }

}
