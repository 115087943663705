body {
    &.content-class-home #content {

        .header-wrapper {
            position: relative;
            overflow: hidden;
            background: #000;
            .header-bg {
                position: absolute;
                right: 0;
                left: 0;
                top: 0;
                bottom: 0;
                opacity: .4;
            }
            .header-container {
                position: relative;

                .header-info {
                    color: #fff;
                }
            }

            .header-block {
                padding-top: 150px;
                padding-bottom: 100px;
                position: relative;
                z-index: 3;

                @include mobile {
                    padding-bottom: 80px !important;

                    @include responsive-max(500px) {
                        padding-top: 50px !important;
                        padding-bottom: 50px !important;
                    }
                }
                @include tablet {
                    padding-top: 80px !important;
                    padding-bottom: 80px !important;
                }

                .gdlr-core-pbf-wrapper-content .gdlr-core-skin-title p {
                    color: #fff;
                    font-weight: 600;
                    line-height: 55px;
                    letter-spacing: 0;
                    margin: 30px 0;

                    @include mobile {
                        margin-top: 0;
                        line-height: 40px;
                    }
                    span {
                        color: $taupe-light;
                    }
                }

                p {
                    max-width: 600px;
                    margin-bottom: 30px;
                    font-weight: 600;

                    @include tablet {
                        max-width: 500px;
                    }
                    @include desktop {
                        max-width: 550px;
                    }
                }
            }
        }
        .inteco-header-background-transparent {
           position: absolute;
            z-index: 4;
        }
        .inteco-header-style-plain {
            .sf-menu > li {
                &.current-menu-item > a, &.current-menu-ancestor > a {
                    color: #ce9b5f; //efc28e
                }

                > a {
                    color: #fff;
                }
            }
        }
        .usp-wrapper-header {
            position: static;
        }
        .first-text-block, .second-text-block {
            @include mobile {
                margin-bottom: 50px;
                padding-top: 0;
                padding-right: 40px !important;
                padding-left: 40px !important;
            }
        }

        .first-text-block {
            margin: 0 0 0 120px;
            padding: 80px 0 0;

            @include tablet {
                margin: 0 0 0 70px;
                padding-top: 40px;
                padding-bottom: 60px;
            }
        }
        .second-text-block {
            margin: 0 120px 0 0;
            padding: 105px 0 0;

            @include tablet {
                margin: 0;
                padding-top: 40px;
                padding-bottom: 40px;
            }
        }

        .gdlr-core-image-item-style-rectangle {
            z-index: 2;
        }

        .cta-blocks {
            margin: -140px 0 0;
            padding: 40px 0 30px;

            @include mobile {
                padding: 0;
            }

            .gdlr-core-column-20 {
                @include mobile-tablet {
                    width: 100%;
                    margin-top: 30px;
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }

        .kitchens-wrapper {
            padding: 85px 0;

            @include mobile-tablet {
                padding: 40px 0;
            }

            .kitchens-wrapper-heading {
                padding-bottom: 60px;

                @include mobile-tablet {
                    padding-bottom: 40px;
                }
            }
        }

        h2.block-title {
            font-size: 32px;
            letter-spacing: 0;
            text-transform: none;

            &.text-black {
                color: #111;
            }

            @include mobile {
                font-size: 28px;
            }
        }

        .quote-wrapper {
            padding: 80px 0 50px;

            @include mobile {
                padding: 40px 0 0;
            }
        }

        .gdlr-core-text-box-item-content {
            &.first-block {
                a {
                    font-size: 13.5px;
                    margin-top: 10px;
                    padding: 0 0 7px;
                    letter-spacing: 1px;
                    border-bottom-width: 3px;
                    border-bottom-style: solid;
                    background: transparent;
                    color: $taupe;
                    border-color: $taupe;
                    font-family: $heading-font;
                    font-weight: 700;
                    text-transform: uppercase;
                    &:hover {
                        text-decoration: none;
                        color: $dirt;
                        border-color: $dirt;
                    }
                }
            }
        }
        .gdlr-core-blog-grid-frame {
            .gdlr-core-blog-content {
                a {
                    display: block;
                    font-weight: bold;
                    margin-top: 15px;
                    color: $roest;
                }
            }
        }
        .gdlr-core-social-network-icon {
            font-size: 19px;
            color: #af938f;
            margin-left: 21px;
            &:first-of-type {
                margin-left: 0;
            }
            &:hover {
                text-decoration: none;
                color: $taupe;
            }
        }

        .gdlr-core-social-network-item, .contact-details {
            white-space: nowrap;
        }
        .gdlr-core-pbf-background.lamp-bg {
            @include mobile {
                width: 80%;
            }
        }

        .kbc-kdc-wrapper {
            &.with-bg {
                background: #f1f1f1;

                .kbc-kdc-items {
                    .item {
                        .widget {
                            background: #fff;
                        }
                    }
                }
            }
            .keukenboer-logo-slogan {
                margin-bottom: 20px;
            }
            .intro-keukenboer {
                .keukenboer-quote {
                    margin-bottom: 20px;

                    // Test styles for Van Geloof
                    // v1
                    font-style: normal;
                    font-size: 1.6em;
                    font-family: 'Barlow', sans-serif;
                    font-weight: 600;

                    // v2
                    // font-weight: normal;
                    //font-size: 1.8em;
                    //font-family: 'Patrick Hand', cursive;
                }
                .intro-text {
                    max-width: 750px;
                    margin: 0 auto 50px;
                    text-align: center;
                }
            }
        }

        .block-above-info-blocks {
            padding-bottom: 200px;

            @include mobile {
                padding-bottom: 50px;
            }
        }
    }
}
