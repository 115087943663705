body {
    &.content-class-reviews {
        .gdlr-core-column-30 .gdlr-core-blog-grid-with-frame .gdlr-core-blog-grid-frame {
            padding: 40px;
        }

        .inteco-content-area {
            padding-top: 0;
            padding-bottom: 0;
        }
        .gdlr-core-pbf-sidebar-right {
            padding: 0 20px 60px;

            @include mobile-tablet {
                padding: 60px 20px;
            }
        }

        .review-block {
            .gdlr-core-blog-content {
                margin-bottom: 0;
                font-style: italic;
            }
            .gdlr-core-blog-grid-frame {
                position: relative;
                &:after {
                    content: "”";
                    position: absolute;
                    right: 40px;
                    top: 0;
                    color: $taupe;
                    font-size: 80px;
                }
                .gdlr-core-blog-content {
                    a {
                        display: block;
                        font-weight: bold;
                        margin-top: 15px;
                        color: $roest;
                    }
                }
                .gdlr-core-blog-title {
                    font-size: 25px;
                    font-weight: 700;
                    letter-spacing: 0;
                    padding-right: 40px;
                }
            }
        }

        &.content-class-reviews-entry {
            .content-info {
                padding: 60px 0;
                .gdlr-core-title-item-title  {
                    font-size: 19px;
                    letter-spacing: 0;
                    text-transform: none;
                }
                .gdlr-core-pbf-column-content-margin {
                    padding-right: 30px;
                    .gdlr-core-divider-container {
                        max-width: 140px;
                        .gdlr-core-divider-line {
                            border-color: #968481;
                        }
                    }
                }
                .gdlr-core-text-box-item {
                    padding-bottom: 0;
                    .gdlr-core-text-box-item-content {
                        font-size: 18px;
                        font-weight: 400;
                        text-transform: none;
                    }
                }
                .social-share {
                    margin: 30px 0;
                }
            }

            .reviewcontent {
                margin-bottom: 10px;
            }

            .other-reviews {
                .gdlr-core-title-item-title {
                    font-size: 32px;
                    letter-spacing: 0;
                    text-transform: none;
                    margin-bottom: 40px;

                    @include mobile-tablet {
                        font-size: 26px;
                        margin-bottom: 20px;
                    }
                }

                .gdlr-core-blog-grid {
                    margin-bottom: 0;
                }
            }
        }
    }
}
