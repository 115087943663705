// Download form
.download-form-wrapper {
    margin: 20px 0 30px;
    display: block;
    background: #f1f1f1;
    padding: 30px;

    h2 {
        margin-bottom: 20px;
    }
    button.btn-default.send {
        margin: 0 !important;
    }
    .download-link {
        margin-bottom: 0;
    }
    a.send {
        margin: 20px 0 0 0 !important;
    }
    .hidden-mailchimp {
        display: none;
    }

    // Form style
    .quform-elements {
        margin-left: -10px;
        margin-right: -10px;
        input {
            background: #fff;
        }
    }
}