.button, a.button {
    font-size: 16px;
    font-weight: 600;
    padding: 15px 30px;
    display: block;
    -webkit-appearance: none;
    background-color: $text-color;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-style: normal;
    text-align: center;
    line-height: 20px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    z-index: 1; // Fix for template elements with underlaying style

    @include mobile {
        display: block;
        width: 100%;
        text-align: center;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        border-radius: 5px;
        z-index: -1;
    }

    &:after {
        opacity: 0;
        transition: opacity.3s ease-in-out;
    }

    &:hover {
        border-color: transparent;
        //background-color: darken($taupe, 10%);
        color: #fff !important;
        text-decoration: none;

        &:after {
            opacity: 1;
        }
    }
    &.margin-top {
        margin-top: 15px;
    }

    &.button-inline {
        display: inline-block;
        margin: 0 auto;
    }

    &.button-green {
        color: #fff;
        background: none;

        &:before, &:after {
            background-image: linear-gradient(90deg, $green 0%, $green-dark 100%);
        }
        &:after {
            background-image: linear-gradient(to right,$green-dark,$green-dark);
        }
    }
    &.button-taupe {
        background-color: $taupe;
        color: #fff;
        &:hover {
            background-color: darken($taupe, 10%);
        }
    }
    &.button-roest {
        background: none !important;
        color: #fff;

        &:before, &:after {
            background-image: linear-gradient(90deg, $roest 0%, $soot 100%);
        }
        &:after {
            background-image: linear-gradient(to right,$soot,$soot);
        }
    }
    &.button-terra {
        background-color: $terra;
        color: #fff;
        &:hover {
            background-color: darken($terra, 10%);
        }
    }
    &.button-dirt {
        background-color: $dirt;
        color: #fff;
        &:hover {
            background-color: darken($dirt, 10%);
        }
    }
    &.button-outline {
        background-color: transparent;
        color: $text-color;
        border: 2px solid $text-color;

        &.button-taupe {
            border-color: $taupe;
            color: $taupe;
            &:hover {
                background-color: $taupe;
                border-color: $taupe;
            }
        }
        &.button-roest {
            border-color: $roest;
            color: $roest;
            &:hover {
                background-color: $roest;
                border-color: $roest;
            }
        }
        &.button-terra {
            border-color: $terra;
            color: $terra;
            &:hover {
                background-color: $terra;
                border-color: $terra;
            }
        }
        &.button-custard {
            border-color: $custard;
            color: $taupe;
            &:hover {
                background-color: $taupe;
                border-color: $taupe;
                color: #fff;
            }
        }
        &.button-white {
            border-color: #fff;
            color: #fff;
            &:hover {
                background-color: #fff;
                color: $text-color !important;
                border-color: #fff;
            }
        }
        &:hover {
            background-color: $text-color;
            border-color: $text-color;
            color: #fff;
        }
    }
}

.nav-button-wrapper {
    @include mobile-tablet {
        display: none!important;
    }
}

.nav-button {
    display: inline-block !important;
    color: #fff !important;
    font-weight: 600;
    padding: 12px 24px 14px !important;
    line-height: 20px !important;
    -webkit-appearance: none;
    background-color: $taupe-light;
    border-radius: 5px;
    text-decoration: none !important;

    &:hover {
        background-color: $custard !important;
    }

    @include mobile-tablet {
        margin-top: 20px !important;
    }

    @media only screen and (min-width: 1000px) and (max-width: 1170px) {
        padding: 10px 24px 12px !important;
        font-size: 14px !important;
        top: -7px !important;
        margin-left: 20px;
    }

    @media only screen and (min-width: 1171px) and (max-width: 1400px) {
        padding: 11px 24px 14px !important;
        font-size: 15.5px !important;
        top: -7px !important;
    }
    @media only screen and (min-width: 1401px) {
        padding: 12px 24px 14px !important;
        font-size: 16px !important;
        top: -6px !important;
    }
}