// ------
// Mixins
// ------

// Vertical align
@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    transform: translateY(-50%);
}

// Horizontal align
@mixin horizontal-align($position: relative) {
    position: $position;
    left: 50%;
    transform: translateX(-50%);
}

// Placeholder
@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}
}

// Clearfix
@mixin clearfix() {
    &::after {
        clear: both;
        content: ' ';
        display: block;
    }
}
