// ----
// Base
// ----

// Import Functions
@import 'import/functions';

// Import Variables
@import 'import/variables';

// Import Mixins
@import 'mixins/base';
@import 'mixins/responsive';

// Import Elements + Helpers
@import 'import/elements';
@import 'import/helpers';

// ----
// Template
// ----

// Import Template CSS
@import 'template/block';
@import 'template/style-core';
@import 'template/page-builder';
@import 'template/inteco-style-custom';

// ----
// Vendors
// ----

// Import Ionicons
@import 'vendor/ionicons';

// ----
// Components
// ----

@import 'components/form';
@import 'components/buttons';
@import 'components/links';
@import 'components/editor';
@import 'components/widgets';
@import 'components/download-form';
@import 'components/datetimepicker';

// ----
// Layouts
// ----

@import 'layout/overrides';
@import 'layout/default';

// -------
// Pages
// -------

@import 'pages/home';
@import 'pages/kitchens';
@import 'pages/blog';
@import 'pages/faq';
@import 'pages/contact';
@import 'pages/landingpage';
@import 'pages/downloads';
@import 'pages/reviews';
@import 'pages/accessories';
@import 'pages/brands';
@import 'pages/about-us';