#content {
    .bootstrap-datetimepicker-widget {
        tr {
            background: none;
        }
        th {
            background: #fff;
            color: $taupe;

            &.picker-switch {
                font-weight: 700;
                &:hover {
                    background: #eee;
                }
            }

            &.next:hover, &.prev:hover {
                background: #eee;
            }
            &.dow {
                background: #fff;
                color: $text-color;
                font-weight: 600;
            }
        }
        td {
            &.today:before {
                border-bottom-color: $terra;
            }
            &.day {
                font-size: 16px;
                color: tint($text-color, 10%);
                &.active {
                    background: $terra;
                    color: #fff;
                }
            }
        }
        span {
            &.day, &.month, &.year, &.decade {
                &.active {
                    background: $terra;
                }
            }
        }
    }
    .disabled {
        color: #ccc !important;
    }
}