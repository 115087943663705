body {
    &.content-class-blog {
        .gdlr-core-blog-grid-frame {
            .gdlr-core-blog-content {
                a {
                    display: block;
                    font-weight: bold;
                    margin-top: 15px;
                    color: $roest;
                }
            }
        }

        .categories-tag-wrapper {
            padding: 10px 0;
            background: $black;
            .categories-tags {
                $tablet-padding: 6px 0;

                @include mobile-tablet {
                    padding: 14px 0;
                }

                .heading {
                    display: inline-block;
                    margin-right: 20px;
                    font-weight: 600;
                    font-family: $heading-font;
                    color: #fff;
                    @include mobile {
                        margin-bottom: 7px;
                    }
                }

                ul.categories {
                    display: inline-block;
                    list-style-type: none;
                    margin: 0;
                    li {
                        display: inline-block;
                        margin: 0 11px;

                        &.active a, &.active:hover a {
                            font-weight: 700;
                            color: $custard;
                            border-color: $custard;
                            text-decoration: none;
                        }

                        a {
                            display: block;
                            text-decoration: none;
                            padding: 13px 0 2px;
                            margin-bottom: 7px;
                            font-size: 17px;
                            color: #fff;
                            border-bottom: 2px solid transparent;

                            @include tablet {
                                padding: $tablet-padding;
                            }

                            &:hover {
                                color: $custard;
                                border-color: $custard;
                            }
                        }
                    }
                }

                .dropdown {
                    $padding-lr: 20px;

                    &.open {
                        ul.dropdown-menu {
                            display: block;
                        }
                        .caret {
                            transform: rotate(180deg);
                        }
                    }

                    button {
                        display: block;
                        width: 100%;
                        text-align: left;
                        padding: 9px $padding-lr;
                        &:hover {
                            background: #444;
                        }

                        .caret {
                            float: right;
                            margin: 8px -6px 0 0;
                            display: inline-block;
                            width: 0;
                            height: 0;
                            margin-left: 2px;
                            vertical-align: middle;
                            border-top: 4px dashed;
                            border-top: 4px solid;
                            border-right: 4px solid transparent;
                            border-left: 4px solid transparent;
                        }
                    }

                    ul.dropdown-menu {
                        display: none;
                        padding: 5px 0;
                        width: 100%;
                        background: #555;
                        max-width: 400px;
                        margin: 0 auto;
                        transition: all .3s ease-out;

                        li {
                            display: block;
                            margin: 0;
                            width: auto;

                            a {
                                font-size: 14px;
                                background-color: transparent;
                                padding: 7px $padding-lr;
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }

        &.entry {
            .inteco-content-area {
                padding-top: 0;
                padding-bottom: 0;
            }

            .gdlr-core-pbf-sidebar-right {
                padding: 60px 20px !important;
            }

            .gdlr-core-blog-grid-frame {
                .gdlr-core-blog-title {
                    font-size: 25px;
                    font-weight: 700;
                    letter-spacing: 0;
                }
            }

            .inteco-single-related-post-wrap {
                margin-top: 30px;
            }
        }
    }
}