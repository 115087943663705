body {
    &.content-class-brands {
        .inteco-content-area {
            padding-top: 0;
            padding-bottom: 0;
        }
        .gdlr-core-pbf-sidebar-right {
            padding: 60px 20px !important;
        }

        .brands-wrapper {

            .category-heading {
                margin: 30px 0;
                clear: both;
                &:first-of-type {
                    margin-top: 15px;
                }
            }

            .brands {
                margin: 30px -15px 50px;

                .gdlr-core-column-15 {
                    padding-left: 15px;
                    padding-right: 15px;
                }

                .brand {
                    position: relative;
                    display: inline-block;
                    width: 100%;
                    height: 110px;
                    border: 1px solid #ccc;
                    transition: all .2s ease-out;
                    margin-bottom: 20px;

                    &:hover {
                        border: 1px solid $taupe;
                    }

                    a.logo {
                        @include vertical-align(absolute);
                        display: inline-block;
                        padding: 20px;
                        text-align: center;
                        vertical-align: middle;
                        width: 100%;

                        @include desktop {
                            padding: 25px;
                        }

                        &:hover {
                            img {
                                filter: grayscale(0%);
                            }
                        }

                        img {
                            margin: 0 auto;
                            max-width: 100%;
                            max-height: 70px;
                            filter: grayscale(100%);
                        }

                        @include clearfix;
                    }
                }
            }
        }
    }
}
