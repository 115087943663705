body {
    &.content-class-landingpage {
        .inteco-content-area {
            padding-top: 0;
            padding-bottom: 0;
        }
        .gdlr-core-pbf-sidebar-right {
            padding: 60px 20px !important;
        }

        .search-results {
            h3 {
                margin-top: 10px;
            }
            .kitchen-results {
                margin-left: -15px;
                margin-right:-15px;
                margin-bottom: -20px;
                h3 {
                    margin-top: 0;
                }
            }
        }

        .gdlr-core-pbf-sidebar-right {
            .widget {
                &.info-block {
                    .textwidget {
                        padding-right: 100px;
                        @include tablet {
                            padding-right: 0;
                        }
                        @include desktop {
                            padding-right: 50px;
                        }
                    }
                }
            }
        }
    }
}
