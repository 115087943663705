// ----------
// Typography
// ----------

$alternative-font: 'Barlow', sans-serif;;
$default-font: 'Barlow', sans-serif;;
$heading-font: 'Barlow', sans-serif;;
//$heading-font: $alternative-font;
$base-font-size: 16px;

// -----
// Icons
// -----

$fa-font-path: "/node_modules/@fortawesome/fontawesome-free/webfonts" !default;

// ------
// Colors
// ------

$grey: #444;
$terra: #d46953;
$custard: #ce9b5f;
//$roest: #7E3C33;
//$taupe: #806D66;
$aubergine: #472A31;
//$dirt: #473C3B;
//$green: #84b111;
$black: #1D1D1B;
$white: #f9f9f9;


// New style Keukenboer
$green: #546856;
$green-dark: #425444;
$roest: #7d3b33;
$soot: #3f2021;

// Supporting colors
$dirt: #473C3B;
$taupe: #806D66;
$taupe-light: #bb986d;

// ---------------
// Color variables
// ---------------

$primary-color: $roest;
$primary-color: $roest;
$text-color: $grey;
$error-label-color: #ab1923;
$link-color: $roest;
$link-hover-color: darken($roest, 12%);
$link-hover-color: $roest;

$nav-active-color: $custard;
$divider-color: $custard;

// -------------------
// Social media colors
// -------------------

$facebook-color: #3b5998;
$twitter-color: #55acee;
$linkedin-color: #007bb6;
$youtube-color: #bb0000;
$pinterest-color: #cb2027;
$instagram-color: #125688;

$border-radius: 4px;

// -------------
// Grid settings
// -------------

@import 'breakpoints';
