.wp-block-audio figcaption {
    margin-top: .5em;
    margin-bottom: 1em;
    color: #555d66;
    text-align: center;
    font-size: 13px
}

.wp-block-audio audio {
    width: 100%;
    min-width: 300px
}

.editor-block-list__layout .reusable-block-edit-panel {
    align-items: center;
    background: #f8f9f9;
    color: #555d66;
    display: flex;
    flex-wrap: wrap;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 13px;
    position: relative;
    top: -14px;
    margin: 0 -14px;
    padding: 8px 14px;
    position: relative;
    z-index: 7
}

.editor-block-list__layout .editor-block-list__layout .reusable-block-edit-panel {
    margin: 0 -14px;
    padding: 8px 14px
}

.editor-block-list__layout .reusable-block-edit-panel .reusable-block-edit-panel__spinner {
    margin: 0 5px
}

.editor-block-list__layout .reusable-block-edit-panel .reusable-block-edit-panel__info {
    margin-right: auto
}

.editor-block-list__layout .reusable-block-edit-panel .reusable-block-edit-panel__label {
    margin-right: 8px;
    white-space: nowrap;
    font-weight: 600
}

.editor-block-list__layout .reusable-block-edit-panel .reusable-block-edit-panel__title {
    flex: 1 1 100%;
    font-size: 14px;
    height: 30px;
    margin: 4px 0 8px
}

.editor-block-list__layout .reusable-block-edit-panel .components-button.reusable-block-edit-panel__button {
    flex-shrink: 0
}

@media (min-width: 960px) {
    .editor-block-list__layout .reusable-block-edit-panel {
        flex-wrap: nowrap
    }
    .editor-block-list__layout .reusable-block-edit-panel .reusable-block-edit-panel__title {
        margin: 0
    }
    .editor-block-list__layout .reusable-block-edit-panel .components-button.reusable-block-edit-panel__button {
        margin: 0 0 0 5px
    }
}

.editor-block-list__layout .reusable-block-indicator {
    background: #fff;
    border-left: 1px dashed #e2e4e7;
    color: #555d66;
    border-bottom: 1px dashed #e2e4e7;
    top: -14px;
    height: 30px;
    padding: 4px;
    position: absolute;
    z-index: 1;
    width: 30px;
    right: -14px
}

.wp-block-button {
    color: #fff;
    margin-bottom: 1.5em
}

.wp-block-button.aligncenter {
    text-align: center
}

.wp-block-button.alignright {
    text-align: right
}

.wp-block-button__link {
    background-color: #32373c;
    border: none;
    border-radius: 28px;
    box-shadow: none;
    color: inherit;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    margin: 0;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    white-space: normal;
    overflow-wrap: break-word
}

.wp-block-button__link:active, .wp-block-button__link:focus, .wp-block-button__link:hover {
    color: inherit
}

.is-style-squared .wp-block-button__link {
    border-radius: 0
}

.is-style-outline {
    color: #32373c
}

.is-style-outline .wp-block-button__link {
    background: 0 0;
    border: 2px solid currentcolor
}

.wp-block-categories.alignleft {
    margin-right: 2em
}

.wp-block-categories.alignright {
    margin-left: 2em
}

.wp-block-columns {
    display: flex;
    flex-wrap: wrap
}

@media (min-width: 782px) {
    .wp-block-columns {
        flex-wrap: nowrap
    }
}

.wp-block-column {
    flex: 1;
    margin-bottom: 1em;
    flex-basis: 100%;
    min-width: 0;
    word-break: break-word;
    overflow-wrap: break-word
}

@media (min-width: 600px) {
    .wp-block-column {
        flex-basis: 50%;
        flex-grow: 0
    }
}

@media (min-width: 600px) {
    .wp-block-column:nth-child(odd) {
        margin-right: 32px
    }
    .wp-block-column:nth-child(even) {
        margin-left: 32px
    }
    .wp-block-column:not(:first-child) {
        margin-left: 32px
    }
    .wp-block-column:not(:last-child) {
        margin-right: 32px
    }
}

.wp-block-cover, .wp-block-cover-image {
    position: relative;
    background-color: #000;
    background-size: cover;
    background-position: center center;
    min-height: 430px;
    width: 100%;
    margin: 0 0 1.5em 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
}

.wp-block-cover-image.has-left-content, .wp-block-cover.has-left-content {
    justify-content: flex-start
}

.wp-block-cover-image.has-left-content .wp-block-cover-image-text, .wp-block-cover-image.has-left-content .wp-block-cover-text, .wp-block-cover-image.has-left-content h2, .wp-block-cover.has-left-content .wp-block-cover-image-text, .wp-block-cover.has-left-content .wp-block-cover-text, .wp-block-cover.has-left-content h2 {
    margin-left: 0;
    text-align: left
}

.wp-block-cover-image.has-right-content, .wp-block-cover.has-right-content {
    justify-content: flex-end
}

.wp-block-cover-image.has-right-content .wp-block-cover-image-text, .wp-block-cover-image.has-right-content .wp-block-cover-text, .wp-block-cover-image.has-right-content h2, .wp-block-cover.has-right-content .wp-block-cover-image-text, .wp-block-cover.has-right-content .wp-block-cover-text, .wp-block-cover.has-right-content h2 {
    margin-right: 0;
    text-align: right
}

.wp-block-cover .wp-block-cover-image-text, .wp-block-cover .wp-block-cover-text, .wp-block-cover h2, .wp-block-cover-image .wp-block-cover-image-text, .wp-block-cover-image .wp-block-cover-text, .wp-block-cover-image h2 {
    color: #fff;
    font-size: 2em;
    line-height: 1.25;
    z-index: 1;
    margin-bottom: 0;
    max-width: 610px;
    padding: 14px;
    text-align: center
}

.wp-block-cover .wp-block-cover-image-text a, .wp-block-cover .wp-block-cover-image-text a:active, .wp-block-cover .wp-block-cover-image-text a:focus, .wp-block-cover .wp-block-cover-image-text a:hover, .wp-block-cover .wp-block-cover-text a, .wp-block-cover .wp-block-cover-text a:active, .wp-block-cover .wp-block-cover-text a:focus, .wp-block-cover .wp-block-cover-text a:hover, .wp-block-cover h2 a, .wp-block-cover h2 a:active, .wp-block-cover h2 a:focus, .wp-block-cover h2 a:hover, .wp-block-cover-image .wp-block-cover-image-text a, .wp-block-cover-image .wp-block-cover-image-text a:active, .wp-block-cover-image .wp-block-cover-image-text a:focus, .wp-block-cover-image .wp-block-cover-image-text a:hover, .wp-block-cover-image .wp-block-cover-text a, .wp-block-cover-image .wp-block-cover-text a:active, .wp-block-cover-image .wp-block-cover-text a:focus, .wp-block-cover-image .wp-block-cover-text a:hover, .wp-block-cover-image h2 a, .wp-block-cover-image h2 a:active, .wp-block-cover-image h2 a:focus, .wp-block-cover-image h2 a:hover {
    color: #fff
}

.wp-block-cover-image.has-parallax, .wp-block-cover.has-parallax {
    background-attachment: fixed
}

@supports (-webkit-overflow-scrolling:touch) {
    .wp-block-cover-image.has-parallax, .wp-block-cover.has-parallax {
        background-attachment: scroll
    }
}

.wp-block-cover-image.has-background-dim::before, .wp-block-cover.has-background-dim::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: inherit;
    opacity: .5;
    z-index: 1
}

.wp-block-cover-image.has-background-dim.has-background-dim-10::before, .wp-block-cover.has-background-dim.has-background-dim-10::before {
    opacity: .1
}

.wp-block-cover-image.has-background-dim.has-background-dim-20::before, .wp-block-cover.has-background-dim.has-background-dim-20::before {
    opacity: .2
}

.wp-block-cover-image.has-background-dim.has-background-dim-30::before, .wp-block-cover.has-background-dim.has-background-dim-30::before {
    opacity: .3
}

.wp-block-cover-image.has-background-dim.has-background-dim-40::before, .wp-block-cover.has-background-dim.has-background-dim-40::before {
    opacity: .4
}

.wp-block-cover-image.has-background-dim.has-background-dim-50::before, .wp-block-cover.has-background-dim.has-background-dim-50::before {
    opacity: .5
}

.wp-block-cover-image.has-background-dim.has-background-dim-60::before, .wp-block-cover.has-background-dim.has-background-dim-60::before {
    opacity: .6
}

.wp-block-cover-image.has-background-dim.has-background-dim-70::before, .wp-block-cover.has-background-dim.has-background-dim-70::before {
    opacity: .7
}

.wp-block-cover-image.has-background-dim.has-background-dim-80::before, .wp-block-cover.has-background-dim.has-background-dim-80::before {
    opacity: .8
}

.wp-block-cover-image.has-background-dim.has-background-dim-90::before, .wp-block-cover.has-background-dim.has-background-dim-90::before {
    opacity: .9
}

.wp-block-cover-image.has-background-dim.has-background-dim-100::before, .wp-block-cover.has-background-dim.has-background-dim-100::before {
    opacity: 1
}

.wp-block-cover-image.alignleft, .wp-block-cover-image.alignright, .wp-block-cover.alignleft, .wp-block-cover.alignright {
    max-width: 305px;
    width: 100%
}

.wp-block-cover-image::after, .wp-block-cover::after {
    display: block;
    content: "";
    font-size: 0;
    min-height: inherit
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
    .wp-block-cover-image::after, .wp-block-cover::after {
        content: none
    }
}

.wp-block-cover-image.aligncenter, .wp-block-cover-image.alignleft, .wp-block-cover-image.alignright, .wp-block-cover.aligncenter, .wp-block-cover.alignleft, .wp-block-cover.alignright {
    display: flex
}

.wp-block-cover__video-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    height: 100%;
    z-index: 0;
    -o-object-fit: cover;
    object-fit: cover
}

.editor-block-list__block[data-type="core/embed"][data-align=left] .editor-block-list__block-edit, .editor-block-list__block[data-type="core/embed"][data-align=right] .editor-block-list__block-edit, .wp-block-embed.alignleft, .wp-block-embed.alignright {
    max-width: 360px;
    width: 100%
}

.wp-block-embed {
    margin-bottom: 1em
}

.wp-block-embed figcaption {
    margin-top: .5em;
    margin-bottom: 1em;
    color: #555d66;
    text-align: center;
    font-size: 13px
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper, .wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper, .wp-embed-responsive .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper, .wp-embed-responsive .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper, .wp-embed-responsive .wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper, .wp-embed-responsive .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper, .wp-embed-responsive .wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper {
    position: relative
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper::before, .wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper::before, .wp-embed-responsive .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper::before, .wp-embed-responsive .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper::before, .wp-embed-responsive .wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper::before, .wp-embed-responsive .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper::before, .wp-embed-responsive .wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper::before {
    content: "";
    display: block;
    padding-top: 50%
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper iframe, .wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper iframe, .wp-embed-responsive .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper iframe, .wp-embed-responsive .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper iframe, .wp-embed-responsive .wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper iframe, .wp-embed-responsive .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper iframe, .wp-embed-responsive .wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper::before {
    padding-top: 42.85%
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper::before {
    padding-top: 50%
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper::before {
    padding-top: 56.25%
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper::before {
    padding-top: 75%
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper::before {
    padding-top: 100%
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-9-6 .wp-block-embed__wrapper::before {
    padding-top: 66.66%
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper::before {
    padding-top: 200%
}

.wp-block-file {
    margin-bottom: 1.5em
}

.wp-block-file.aligncenter {
    text-align: center
}

.wp-block-file.alignright {
    text-align: right
}

.wp-block-file .wp-block-file__button {
    background: #32373c;
    border-radius: 2em;
    color: #fff;
    font-size: 13px;
    padding: .5em 1em
}

.wp-block-file a.wp-block-file__button {
    text-decoration: none
}

.wp-block-file a.wp-block-file__button:active, .wp-block-file a.wp-block-file__button:focus, .wp-block-file a.wp-block-file__button:hover, .wp-block-file a.wp-block-file__button:visited {
    box-shadow: none;
    color: #fff;
    opacity: .85;
    text-decoration: none
}

.wp-block-file * + .wp-block-file__button {
    margin-left: .75em
}

.wp-block-gallery {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0
}

.wp-block-gallery .blocks-gallery-image, .wp-block-gallery .blocks-gallery-item {
    margin: 0 16px 16px 0;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    position: relative
}

.wp-block-gallery .blocks-gallery-image figure, .wp-block-gallery .blocks-gallery-item figure {
    margin: 0;
    height: 100%
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
    .wp-block-gallery .blocks-gallery-image figure, .wp-block-gallery .blocks-gallery-item figure {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start
    }
}

.wp-block-gallery .blocks-gallery-image img, .wp-block-gallery .blocks-gallery-item img {
    display: block;
    max-width: 100%;
    height: auto
}

.wp-block-gallery .blocks-gallery-image img, .wp-block-gallery .blocks-gallery-item img {
    width: 100%
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
    .wp-block-gallery .blocks-gallery-image img, .wp-block-gallery .blocks-gallery-item img {
        width: auto
    }
}

.wp-block-gallery .blocks-gallery-image figcaption, .wp-block-gallery .blocks-gallery-item figcaption {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    overflow: auto;
    padding: 40px 10px 5px;
    color: #fff;
    text-align: center;
    font-size: 13px;
    background: linear-gradient(0deg, rgba(0, 0, 0, .7) 0, rgba(0, 0, 0, .3) 60%, transparent)
}

.wp-block-gallery .blocks-gallery-image figcaption img, .wp-block-gallery .blocks-gallery-item figcaption img {
    display: inline
}

.wp-block-gallery.is-cropped .blocks-gallery-image a, .wp-block-gallery.is-cropped .blocks-gallery-image img, .wp-block-gallery.is-cropped .blocks-gallery-item a, .wp-block-gallery.is-cropped .blocks-gallery-item img {
    width: 100%
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
    .wp-block-gallery.is-cropped .blocks-gallery-image a, .wp-block-gallery.is-cropped .blocks-gallery-image img, .wp-block-gallery.is-cropped .blocks-gallery-item a, .wp-block-gallery.is-cropped .blocks-gallery-item img {
        height: 100%;
        flex: 1;
        -o-object-fit: cover;
        object-fit: cover
    }
}

.wp-block-gallery .blocks-gallery-image, .wp-block-gallery .blocks-gallery-item {
    width: calc((100% - 16px) / 2)
}

.wp-block-gallery .blocks-gallery-image:nth-of-type(even), .wp-block-gallery .blocks-gallery-item:nth-of-type(even) {
    margin-right: 0
}

.wp-block-gallery.columns-1 .blocks-gallery-image, .wp-block-gallery.columns-1 .blocks-gallery-item {
    width: 100%;
    margin-right: 0
}

@media (min-width: 600px) {
    .wp-block-gallery.columns-3 .blocks-gallery-image, .wp-block-gallery.columns-3 .blocks-gallery-item {
        width: calc((100% - 16px * 2) / 3);
        margin-right: 16px
    }
    .wp-block-gallery.columns-4 .blocks-gallery-image, .wp-block-gallery.columns-4 .blocks-gallery-item {
        width: calc((100% - 16px * 3) / 4);
        margin-right: 16px
    }
    .wp-block-gallery.columns-5 .blocks-gallery-image, .wp-block-gallery.columns-5 .blocks-gallery-item {
        width: calc((100% - 16px * 4) / 5);
        margin-right: 16px
    }
    .wp-block-gallery.columns-6 .blocks-gallery-image, .wp-block-gallery.columns-6 .blocks-gallery-item {
        width: calc((100% - 16px * 5) / 6);
        margin-right: 16px
    }
    .wp-block-gallery.columns-7 .blocks-gallery-image, .wp-block-gallery.columns-7 .blocks-gallery-item {
        width: calc((100% - 16px * 6) / 7);
        margin-right: 16px
    }
    .wp-block-gallery.columns-8 .blocks-gallery-image, .wp-block-gallery.columns-8 .blocks-gallery-item {
        width: calc((100% - 16px * 7) / 8);
        margin-right: 16px
    }
    .wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n), .wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n) {
        margin-right: 0
    }
    .wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n), .wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n) {
        margin-right: 0
    }
    .wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n), .wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n) {
        margin-right: 0
    }
    .wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n), .wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n) {
        margin-right: 0
    }
    .wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n), .wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n) {
        margin-right: 0
    }
    .wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n), .wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n) {
        margin-right: 0
    }
    .wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n), .wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n) {
        margin-right: 0
    }
    .wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n), .wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n) {
        margin-right: 0
    }
}

.wp-block-gallery .blocks-gallery-image:last-child, .wp-block-gallery .blocks-gallery-item:last-child {
    margin-right: 0
}

.wp-block-gallery .blocks-gallery-item.has-add-item-button {
    width: 100%
}

.wp-block-gallery.alignleft, .wp-block-gallery.alignright {
    max-width: 305px;
    width: 100%
}

.wp-block-gallery.aligncenter, .wp-block-gallery.alignleft, .wp-block-gallery.alignright {
    display: flex
}

.wp-block-gallery.aligncenter .blocks-gallery-item figure {
    justify-content: center
}

.wp-block-image {
    max-width: 100%;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0
}

.wp-block-image img {
    max-width: 100%
}

.wp-block-image.aligncenter {
    text-align: center
}

.wp-block-image.alignfull img, .wp-block-image.alignwide img {
    width: 100%
}

.wp-block-image .aligncenter, .wp-block-image .alignleft, .wp-block-image .alignright, .wp-block-image.is-resized {
    display: table;
    margin-left: 0;
    margin-right: 0
}

.wp-block-image .aligncenter > figcaption, .wp-block-image .alignleft > figcaption, .wp-block-image .alignright > figcaption, .wp-block-image.is-resized > figcaption {
    display: table-caption;
    caption-side: bottom
}

.wp-block-image .alignleft {
    float: left;
    margin-right: 1em
}

.wp-block-image .alignright {
    float: right;
    margin-left: 1em
}

.wp-block-image .aligncenter {
    margin-left: auto;
    margin-right: auto
}

.wp-block-image figcaption {
    margin-top: .5em;
    margin-bottom: 1em;
    color: #555d66;
    text-align: center;
    font-size: 13px
}

.wp-block-latest-comments__comment {
    font-size: 15px;
    line-height: 1.1;
    list-style: none;
    margin-bottom: 1em
}

.has-avatars .wp-block-latest-comments__comment {
    min-height: 36px;
    list-style: none
}

.has-avatars .wp-block-latest-comments__comment .wp-block-latest-comments__comment-excerpt, .has-avatars .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta {
    margin-left: 52px
}

.has-dates .wp-block-latest-comments__comment, .has-excerpts .wp-block-latest-comments__comment {
    line-height: 1.5
}

.wp-block-latest-comments__comment-excerpt p {
    font-size: 14px;
    line-height: 1.8;
    margin: 5px 0 20px
}

.wp-block-latest-comments__comment-date {
    color: #8f98a1;
    display: block;
    font-size: 12px
}

.wp-block-latest-comments .avatar, .wp-block-latest-comments__comment-avatar {
    border-radius: 24px;
    display: block;
    float: left;
    height: 40px;
    margin-right: 12px;
    width: 40px
}

.wp-block-latest-posts.alignleft {
    margin-right: 2em
}

.wp-block-latest-posts.alignright {
    margin-left: 2em
}

.wp-block-latest-posts.is-grid {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none
}

.wp-block-latest-posts.is-grid li {
    margin: 0 16px 16px 0;
    width: 100%
}

@media (min-width: 600px) {
    .wp-block-latest-posts.columns-2 li {
        width: calc((100% / 2) - 16px)
    }
    .wp-block-latest-posts.columns-3 li {
        width: calc((100% / 3) - 16px)
    }
    .wp-block-latest-posts.columns-4 li {
        width: calc((100% / 4) - 16px)
    }
    .wp-block-latest-posts.columns-5 li {
        width: calc((100% / 5) - 16px)
    }
    .wp-block-latest-posts.columns-6 li {
        width: calc((100% / 6) - 16px)
    }
}

.wp-block-latest-posts__post-date {
    display: block;
    color: #6c7781;
    font-size: 13px
}

.wp-block-media-text {
    display: grid
}

.wp-block-media-text {
    grid-template-rows: auto;
    align-items: center;
    grid-template-areas: "media-text-media media-text-content";
    grid-template-columns: 50% auto
}

.wp-block-media-text.has-media-on-the-right {
    grid-template-areas: "media-text-content media-text-media";
    grid-template-columns: auto 50%
}

.wp-block-media-text .wp-block-media-text__media {
    grid-area: media-text-media;
    margin: 0
}

.wp-block-media-text .wp-block-media-text__content {
    word-break: break-word;
    grid-area: media-text-content;
    padding: 0 8% 0 8%
}

.wp-block-media-text > figure > img, .wp-block-media-text > figure > video {
    max-width: unset;
    width: 100%;
    vertical-align: middle
}

@media (max-width: 600px) {
    .wp-block-media-text.is-stacked-on-mobile {
        grid-template-columns: 100% !important;
        grid-template-areas: "media-text-media" "media-text-content"
    }
    .wp-block-media-text.is-stacked-on-mobile.has-media-on-the-right {
        grid-template-areas: "media-text-content" "media-text-media"
    }
}

p.is-small-text {
    font-size: 14px
}

p.is-regular-text {
    font-size: 16px
}

p.is-large-text {
    font-size: 36px
}

p.is-larger-text {
    font-size: 48px
}

p.has-drop-cap:not(:focus)::first-letter {
    float: left;
    font-size: 8.4em;
    line-height: .68;
    font-weight: 100;
    margin: .05em .1em 0 0;
    text-transform: uppercase;
    font-style: normal
}

p.has-background {
    padding: 20px 30px
}

p.has-text-color a {
    color: inherit
}

.wp-block-pullquote {
    padding: 3em 0;
    margin-left: 0;
    margin-right: 0;
    text-align: center
}

.wp-block-pullquote.alignleft, .wp-block-pullquote.alignright {
    max-width: 305px
}

.wp-block-pullquote.alignleft p, .wp-block-pullquote.alignright p {
    font-size: 20px
}

.wp-block-pullquote p {
    font-size: 28px;
    line-height: 1.6
}

.wp-block-pullquote cite, .wp-block-pullquote footer {
    position: relative
}

.wp-block-pullquote .has-text-color a {
    color: inherit
}

.wp-block-pullquote:not(.is-style-solid-color) {
    background: 0 0
}

.wp-block-pullquote.is-style-solid-color {
    border: none
}

.wp-block-pullquote.is-style-solid-color blockquote {
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    max-width: 60%
}

.wp-block-pullquote.is-style-solid-color blockquote p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 32px
}

.wp-block-pullquote.is-style-solid-color blockquote cite {
    text-transform: none;
    font-style: normal
}

.wp-block-pullquote cite {
    color: inherit
}

.wp-block-quote.is-large, .wp-block-quote.is-style-large {
    margin: 0 0 16px;
    padding: 0 1em
}

.wp-block-quote.is-large p, .wp-block-quote.is-style-large p {
    font-size: 24px;
    font-style: italic;
    line-height: 1.6
}

.wp-block-quote.is-large cite, .wp-block-quote.is-large footer, .wp-block-quote.is-style-large cite, .wp-block-quote.is-style-large footer {
    font-size: 18px;
    text-align: right
}

.wp-block-separator.is-style-wide {
    border-bottom-width: 1px
}

.wp-block-separator.is-style-dots {
    background: 0 0;
    border: none;
    text-align: center;
    max-width: none;
    line-height: 1;
    height: auto
}

.wp-block-separator.is-style-dots::before {
    content: "\00b7 \00b7 \00b7";
    color: #191e23;
    font-size: 20px;
    letter-spacing: 2em;
    padding-left: 2em;
    font-family: serif
}

p.wp-block-subhead {
    font-size: 1.1em;
    font-style: italic;
    opacity: .75
}

.wp-block-table.has-fixed-layout {
    table-layout: fixed;
    width: 100%
}

.wp-block-table.aligncenter, .wp-block-table.alignleft, .wp-block-table.alignright {
    display: table;
    width: auto
}

.wp-block-table.is-style-stripes {
    border-spacing: 0;
    border-collapse: inherit;
    border-bottom: 1px solid #f3f4f5
}

.wp-block-table.is-style-stripes tr:nth-child(odd) {
    background-color: #f3f4f5
}

.wp-block-table.is-style-stripes td {
    border-color: transparent
}

.wp-block-text-columns {
    display: flex
}

.wp-block-text-columns.aligncenter {
    display: flex
}

.wp-block-text-columns .wp-block-column {
    margin: 0 16px;
    padding: 0
}

.wp-block-text-columns .wp-block-column:first-child {
    margin-left: 0
}

.wp-block-text-columns .wp-block-column:last-child {
    margin-right: 0
}

.wp-block-text-columns.columns-2 .wp-block-column {
    width: calc(100% / 2)
}

.wp-block-text-columns.columns-3 .wp-block-column {
    width: calc(100% / 3)
}

.wp-block-text-columns.columns-4 .wp-block-column {
    width: calc(100% / 4)
}

pre.wp-block-verse {
    white-space: nowrap;
    overflow: auto
}

.wp-block-video {
    margin-left: 0;
    margin-right: 0
}

.wp-block-video video {
    max-width: 100%
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
    .wp-block-video [poster] {
        -o-object-fit: cover;
        object-fit: cover
    }
}

.wp-block-video.aligncenter {
    text-align: center
}

.wp-block-video figcaption {
    margin-top: .5em;
    margin-bottom: 1em;
    color: #555d66;
    text-align: center;
    font-size: 13px
}

.has-pale-pink-background-color.has-pale-pink-background-color {
    background-color: #f78da7
}

.has-vivid-red-background-color.has-vivid-red-background-color {
    background-color: #cf2e2e
}

.has-luminous-vivid-orange-background-color.has-luminous-vivid-orange-background-color {
    background-color: #ff6900
}

.has-luminous-vivid-amber-background-color.has-luminous-vivid-amber-background-color {
    background-color: #fcb900
}

.has-light-green-cyan-background-color.has-light-green-cyan-background-color {
    background-color: #7bdcb5
}

.has-vivid-green-cyan-background-color.has-vivid-green-cyan-background-color {
    background-color: #00d084
}

.has-pale-cyan-blue-background-color.has-pale-cyan-blue-background-color {
    background-color: #8ed1fc
}

.has-vivid-cyan-blue-background-color.has-vivid-cyan-blue-background-color {
    background-color: #0693e3
}

.has-very-light-gray-background-color.has-very-light-gray-background-color {
    background-color: #eee
}

.has-cyan-bluish-gray-background-color.has-cyan-bluish-gray-background-color {
    background-color: #abb8c3
}

.has-very-dark-gray-background-color.has-very-dark-gray-background-color {
    background-color: #313131
}

.has-pale-pink-color.has-pale-pink-color {
    color: #f78da7
}

.has-vivid-red-color.has-vivid-red-color {
    color: #cf2e2e
}

.has-luminous-vivid-orange-color.has-luminous-vivid-orange-color {
    color: #ff6900
}

.has-luminous-vivid-amber-color.has-luminous-vivid-amber-color {
    color: #fcb900
}

.has-light-green-cyan-color.has-light-green-cyan-color {
    color: #7bdcb5
}

.has-vivid-green-cyan-color.has-vivid-green-cyan-color {
    color: #00d084
}

.has-pale-cyan-blue-color.has-pale-cyan-blue-color {
    color: #8ed1fc
}

.has-vivid-cyan-blue-color.has-vivid-cyan-blue-color {
    color: #0693e3
}

.has-very-light-gray-color.has-very-light-gray-color {
    color: #eee
}

.has-cyan-bluish-gray-color.has-cyan-bluish-gray-color {
    color: #abb8c3
}

.has-very-dark-gray-color.has-very-dark-gray-color {
    color: #313131
}

.has-small-font-size {
    font-size: 13px
}

.has-normal-font-size, .has-regular-font-size {
    font-size: 16px
}

.has-medium-font-size {
    font-size: 20px
}

.has-large-font-size {
    font-size: 36px
}

.has-huge-font-size, .has-larger-font-size {
    font-size: 42px
}