// -------
// Devices
// -------

@mixin mobile {
    @media (max-width: #{$breakpoint-mobile-max}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$breakpoint-tablet-min}) and (max-width: #{$breakpoint-tablet-max}) {
        @content;
    }
}

@mixin mobile-tablet {
    @media (max-width: #{$breakpoint-tablet-max}) {
        @content;
    }
}

@mixin tablet-desktop {
    @media (min-width: #{$breakpoint-tablet-min}) and (max-width: #{$breakpoint-desktop-max}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$breakpoint-desktop-min}) and (max-width: #{$breakpoint-desktop-max}) {
        @content;
    }
}

@mixin large-desktop {
    @media (min-width: #{$breakpoint-large-desktop-min}) {
        @content;
    }
}

// -------
// Up/Down
// -------

@mixin tablet-up {
    // Tablets and larger devices
    @media (min-width: #{$breakpoint-tablet-min}) {
        @content;
    }
}

@mixin tablet-down {
    // Tablets and smaller devices
    @media (max-width: #{$breakpoint-tablet-max}) {
        @content;
    }
}

@mixin desktop-up {
    // Desktops and larger devices
    @media (min-width: #{$breakpoint-desktop-min}) {
        @content;
    }
}

@mixin desktop-down {
    // Desktops and smaller devices
    @media (max-width: #{$breakpoint-desktop-max}) {
        @content;
    }
}

// --------------------
// Responsive utilities
// --------------------

@mixin responsive-invisibility($parent) {
    #{$parent} {
        display: none !important;
    }
}

@media (max-width: $breakpoint-mobile-max) {
    @include responsive-invisibility('.hidden-xs');
}

@media (min-width: $breakpoint-tablet-min) and (max-width: $breakpoint-tablet-max) {
    @include responsive-invisibility('.hidden-sm');
}

@media (min-width: $breakpoint-desktop-min) and (max-width: $breakpoint-desktop-max) {
    @include responsive-invisibility('.hidden-md');
}

@media (min-width: $breakpoint-large-desktop-min) {
    @include responsive-invisibility('.hidden-lg');
}
// ------
// Custom
// ------

@mixin responsive($min, $max) {
    @media (min-width: #{$min}) and (max-width: #{$max}) {
        @content;
    }
}

@mixin responsive-min($min) {
    @media (min-width: #{$min}) {
        @content;
    }
}

@mixin responsive-max($max) {
    @media (max-width: #{$max}) {
        @content;
    }
}
