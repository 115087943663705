#content {
    .widget {
        &.subscribe-newsletter, &.info-block, &.quotation-block, &.make-appointment {
            padding: 25px;
            &.color-bg {
                background-color: tint($taupe, 85%) !important;
                @include tablet {
                    .textwidget {
                        background-color: transparent;
                    }
                }
            }
            h3 {
                font-size: 17.5px;
                margin-bottom: 15px;
            }
            .textwidget {
                line-height: 27px;
                font-style: italic;
                padding-right: 75px;

                @include tablet {
                    padding-right: 0;
                    background: rgba(255,255,255,0.7);
                }
            }

            &.outline {
                padding: 30px 25px;
                background: #f7f7f7;

                .handwritten {
                    font-size: 1.3em;
                    line-height: 1em;
                    font-weight: 600;
                    margin-bottom: 15px;
                }
            }
        }
        &.quotation-block {
            position: relative;
            overflow: hidden;
            background: lighten($taupe, 40%);
            color: #333;

            &.color-bg {
                background: $taupe !important;
                color: #fff !important;

                .inteco-widget-title, a {
                    color: #fff !important;
                }
            }

            .content {
                padding: 4px;
                z-index: 1;
                position: relative;

                .textwidget {
                    padding-right: 0;
                    background: none !important;
                }
            }
            .quotation-img {
                position: absolute;
                right: 0;
                bottom: 0;
                line-height: 0;
                img {
                    width: 100px;

                    @include tablet-desktop {
                        width: 80px;
                    }
                }
            }
        }
        &.subscribe-newsletter {
            .form-wrapper {
                .result-message {
                    &.success {
                        color: $green;
                    }
                }
            }
            .textwidget {
                padding-right: 0;
            }
        }

        &.make-appointment {
            background: $roest;

            @include mobile {
                position: relative !important;
                max-width: 100% !important;
                margin: 0 !important;
                top: 0 !important;
            }

            h3, label {
                color: #fff;
            }
            button[type=submit] {
                background: darken($roest, 10%);
                &:hover {
                    background: darken($roest, 15%);
                }
            }
            .textwidget {
                color: #fff;
                padding-right: 0;

                @include tablet {
                    background: transparent;
                }
            }
        }
    }
}