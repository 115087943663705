// Overrides

/*
    Inteco Style Custom (scss)
*/

.gdlr-core-divider-line, .gdlr-core-skin-divider {
    border-color: $divider-color;
}

.inteco-navigation .sf-menu > .inteco-normal-menu .sub-menu {
    font-size: 16.5px;
}

.inteco-navigation .sf-menu > li {
    padding: 0 20px;

    @include responsive($breakpoint-desktop-min, 1070px) {
        padding: 0 8px !important;
    }
    @include responsive(1070px, 1170px) {
        padding: 0 12px !important;
    }
    @include responsive(1170px, 1400px) {
        padding: 0 15px;
    }
}

.inteco-navigation .sf-menu > li > a, .inteco-navigation .sf-vertical > li > a {
    font-size: 17.5px;

    @include responsive($breakpoint-desktop-min, 1215px) {
        font-size: 15px;
    }
    @include responsive(1215px, 1400px) {
        font-size: 16px;
    }

    &:hover {
        text-decoration: none;
    }
}

.inteco-copyright-text, .inteco-copyright-left, .inteco-copyright-right {
    font-size: 16px;
}

.inteco-copyright-left a {
    &:hover {
        text-decoration: none !important;
    }
    i {
        font-size: 17px; margin-right: 12px;
    }
}

.inteco-copyright-left a:hover {
    text-decoration: none !important;
}

.inteco-page-title-wrap.inteco-style-custom .inteco-page-title-content {
    padding-top: 90px;
}

.inteco-page-title-wrap.inteco-style-custom .inteco-page-title-content {
    padding-bottom: 115px;
}

.inteco-blog-title-wrap.inteco-feature-image .inteco-blog-title-top-overlay {
    height: 235px;
}

.inteco-blog-title-wrap.inteco-feature-image .inteco-blog-title-bottom-overlay {
    height: 225px;
}

.inteco-body h1, .inteco-body h2, .inteco-body h3, .inteco-body h4, .inteco-body h5, .inteco-body h6, .inteco-body .inteco-title-font, .inteco-body .gdlr-core-title-font {
    font-family: $heading-font;
    font-weight: 700;
}

.gdlr-core-body .gdlr-core-load-more, .gdlr-core-body .gdlr-core-button, .inteco-body .inteco-button, .inteco-body input[type="button"], .inteco-body input[type="submit"], .gdlr-core-title-item .gdlr-core-title-item-link, .gdlr-core-block-item-title-wrap .gdlr-core-block-item-read-more, .gdlr-core-filterer-wrap, .woocommerce-breadcrumb, .woocommerce span.onsale, .gdlr-core-port-info-item, .single-product.woocommerce div.product p.price .woocommerce-Price-amount, .single-product.woocommerce #review_form #respond label {
    font-family: $heading-font;
    font-weight: 700;
}

.inteco-navigation .sf-menu > li > a, .inteco-navigation .sf-vertical > li > a, .inteco-navigation-font {
    font-family: $heading-font;
    font-weight: 600;
}

.inteco-body, .inteco-body .gdlr-core-content-font, .inteco-body input, .inteco-body textarea, .inteco-body button, .inteco-body select, .inteco-body .inteco-content-font, .gdlr-core-audio .mejs-container * {
    font-family: $default-font;
}

.inteco-body .gdlr-core-info-font, .inteco-body .inteco-info-font {
    font-family: $heading-font;
    font-weight: 400;
}

.inteco-body .gdlr-core-blog-info-font, .inteco-body .inteco-blog-info-font {
    font-family: $default-font
}

.inteco-body .gdlr-core-quote-font, blockquote {
    font-family: $default-font;
}

.inteco-body .gdlr-core-testimonial-content {
    font-family: $heading-font;
    font-weight: 600;
}

.inteco-header-background, .inteco-sticky-menu-placeholder, .inteco-header-style-boxed.inteco-fixed-navigation {
    background-color: $green;
}

.sf-menu > li > a:hover, .sf-menu > li.current-menu-item > a, .sf-menu > li.current-menu-ancestor > a, .sf-vertical > li > a:hover, .sf-vertical > li.current-menu-item > a, .sf-vertical > li.current-menu-ancestor > a {
    color: $nav-active-color;
}

.inteco-body, .inteco-body span.wpcf7-not-valid-tip {
    color: $text-color;
}

.inteco-navigation {
    padding-top: 35px;

    @include desktop {
        padding-top: 30px;
    }
}

.inteco-header-wrap {
    overflow: visible !important;
}
.inteco-body-outer-wrapper {
    overflow: visible;
}

.inteco-logo {
    padding: 0 15px !important;
}

.inteco-main-menu-search {
    float: left;
    margin-top: -9px;
    margin-left: 25px;
    border: 2px solid #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: all 0.3s ease-out;

    @include desktop {
        margin-left: 15px;
    }

    &:hover {
        background: #fff;
        i {
            color: $roest;
        }
    }

    i {
        position: relative;
        left: 11px;
        top: 4px;
        color: #fff;

        @include mobile-tablet {
            top: 3px;
        }
    }
}

.inteco-logo-inner {
    max-width: 260px;
    background: #fff;
    padding: 15px 20px;
    margin-top: -51px;
    z-index: 1;

    @include mobile-tablet {
        background: none;
        padding: 0;
        margin-top: 0;
        position: relative;
        top: 9px;
    }

    @include responsive($breakpoint-desktop-min, 1385px) {
        max-width: 190px;
        padding: 15px 20px;
    }

    &.sticky-nav {
        display: none;
    }
}

header.inteco-fixed-navigation {
    .inteco-logo-inner {
        display: none;

        &.sticky-nav {
            display: block;
            margin-top: 17px;
            padding: 0;
            background: none;
            width: 220px;
        }
    }
}

.inteco-mobile-header .inteco-logo-inner {
    @include mobile {
        max-width: 150px !important;
    }
    @include tablet {
        max-width: 175px !important;
    }
}

.inteco-animate-fixed-navigation.inteco-header-style-plain .inteco-logo-inner, .inteco-animate-fixed-navigation.inteco-header-style-boxed .inteco-logo-inner {
    max-width: 200px;
}
.inteco-page-title-wrap .inteco-page-title-overlay {
    opacity: 0.55;
}
.gdlr-core-column-30 .gdlr-core-blog-grid-with-frame .gdlr-core-blog-grid-frame {
    padding: 25px 40px 20px;
}

.inteco-header-container.inteco-header-full {
    @include responsive($breakpoint-desktop-min, 1170px) {
        padding-right: 10px;
        padding-left: 10px;
    }
}

.inteco-header-style-plain.inteco-style-float-left .inteco-logo-bottom-divider {
    background: -webkit-linear-gradient(to left, rgba(231, 193, 148, 1), rgba(128, 109, 102, 0.1));
    background: -o-linear-gradient(to left, rgba(231, 193, 148, 1), rgba(128, 109, 102, 0.1));
    background: -moz-linear-gradient(to left, rgba(231, 193, 148, 1), rgba(128, 109, 102, 0.1));
    background: linear-gradient(to left, rgba(231, 193, 148, 1), rgba(128, 109, 102, 0.1));
}

.inteco-copyright-wrapper {
    color: #999;
    a, a:hover {
        color: #ccc;
    }
}

.inteco-content-wrap {
    padding-left: 20px;
    padding-right: 40px;

    @include mobile-tablet {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.inteco-page-title-wrap.inteco-style-custom .inteco-page-caption {
    font-size: 22px;
    font-family: $heading-font;
}

.gdlr-core-title-item .gdlr-core-title-item-title, .gdlr-core-title-item .gdlr-core-title-item-title a {
    color: $roest;
    letter-spacing: 0;

    &.secondary-color {
        color: $terra;
    }
}

.gdlr-core-title-item .gdlr-core-title-item-caption {
    color: $taupe-light;

    &.secondary-color {
        color: $roest;
    }
}

.inteco-sidebar-area a:hover, .inteco-sidebar-area .current-menu-item > a {
    color: $link-hover-color;
}

.gdlr-core-blog-title a, .inteco-body .inteco-single-article-title a {
    color: #444;
    &:hover {
        color: $link-hover-color;
        text-decoration: none;
    }
}

.inteco-single-article .inteco-blog-info-wrapper, .inteco-single-article .inteco-blog-info-wrapper a, .inteco-single-article .inteco-blog-info-wrapper a:hover, .inteco-single-article .inteco-blog-info-wrapper i, .gdlr-core-blog-info-wrapper, .gdlr-core-blog-info-wrapper a, .gdlr-core-blog-info-wrapper a:hover, .gdlr-core-blog-info-wrapper i, .gdlr-core-blog-grid .gdlr-core-blog-info-date a {
    color: $roest;
    letter-spacing: 1px;
}

.inteco-body .tagcloud a:hover {
    background-color: $taupe;
    border-color: $taupe;
    text-decoration: none;
}

@font-face {
    font-family: "ArcaMajora Bold";
    src: url("/base/fonts/ArcaMajora3-Bold.eot");
    src: url("/base/fonts/ArcaMajora3-Boldd41d.eot?#iefix") format("embedded-opentype"),
    url("/base/fonts/ArcaMajora3-Bold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "ArcaMajora Heavy";
    src: url("/base/fonts/ArcaMajora3-Heavy.eot");
    src: url("/base/fonts/ArcaMajora3-Heavyd41d.eot?#iefix") format("embedded-opentype"),
    url("/base/fonts/ArcaMajora3-Heavy.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

/*
    Style Core (scss)
*/

.inteco-single-article {
    padding-bottom: 0;
}

.inteco-sidebar-area .inteco-widget {
    margin-bottom: 45px;
    &:last-of-type {
        margin-bottom: 0;
    }
}

.inteco-blog-title-wrap .inteco-single-article-title {
    @include mobile {
        font-size: 34px;
    }
    @include tablet {
        font-size: 40px;
    }
}


/*
    Page Builder (css from goodlayers plugin)
*/

.gdlr-core-blog-grid.gdlr-core-style-2 .gdlr-core-blog-info-wrapper .gdlr-core-blog-info {
    font-size: 12.5px!important;
    text-transform: uppercase;
    letter-spacing: .8px;
}

/* blockquote */
.gdlr-core-page-builder-body blockquote {
    margin: 18px 0 !important;
}

.gdlr-core-blog-grid .gdlr-core-blog-title {
    font-weight: 700;
}

.inteco-fixed-navigation {

}

.sf-menu > li > a:hover, .sf-menu > li.current-menu-item > a {
    color: #dda360;
}
.inteco-animate-fixed-navigation.inteco-header-style-plain .inteco-navigation .sf-menu > li.current-menu-item > a {
    color: #efc28e;
}
