body {
    &.content-class-kitchens-index {
        .inteco-page-title-wrap.inteco-style-medium .inteco-page-title-content {
            padding-top: 85px;
            padding-bottom: 110px;
        }

        .gdlr-core-pbf-sidebar-wrapper {
            .inteco-widget-title {
                margin-bottom: 15px;
            }
        }

        .gdlr-core-item-list.no-link {
            .kitchen-block-diy {
                border: 1px solid #e3e3e3;

                .price span {
                    font-weight: 400;
                    font-size: .85em;
                    margin-right: 8px;
                    position: relative;
                    bottom: 2px;
                }
            }
            .gdlr-core-portfolio-title {
                color: #444 !important;
            }
        }

        .widget {
            margin-bottom: 30px !important;

            &.last-widget {
                margin-bottom: 0 !important;
            }

            &.cta-widget {
                .textwidget {
                    font-size: 16px;
                    font-style: italic;
                }
            }
        }

        .filter-option {
            margin-bottom: 35px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .gdlr-core-pbf-background-wrap {
            background: #fff;
        }

        .gdlr-core-pbf-sidebar-content-inner {
            padding-left: 20px;

            @include mobile {
                padding-left: 0;
            }
        }

        .gdlr-core-sidebar-item {
            padding: 30px;
            background: #f3f3f3;
        }

        .gdlr-core-pbf-sidebar-left {
            padding: 60px 20px !important;

            @include mobile  {
                padding: 0 20px !important;
                margin-bottom: 30px;
                margin-top: -30px;
            }
            @include tablet {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }

        form {
            input[type=checkbox], input[type=radio] {
                position: absolute;
                margin-left: -20px;
                margin-top: 5px;
            }

            .checkbox label, .radio label {
                min-height: 20px;
                padding-left: 20px;
                margin-bottom: 0;
                font-weight: 400;
                cursor: pointer;
            }

            .radio, .checkbox {
                margin-bottom: 10px;
                margin-top: 10px;
                position: relative;
                display: block;
                line-height: 22px;

                &:last-of-type {
                    margin-bottom: 0;
                }

                &.active {
                    label {
                        font-weight: 600;
                    }
                }

                .checkbox-inline {
                    width: 100px;
                    position: relative;
                    display: inline-block;
                    padding-left: 20px;
                    margin-bottom: 0;
                    vertical-align: middle;
                    font-weight: 400;
                    cursor: pointer;
                }

                label {
                    font-weight: 400;
                }
            }
        }
    }

    &.content-class-kitchens-entry #content {
        $priceblock-padding-lr: 26px;
        $content-padding-top: 100px;
        $content-padding-top-mobile: 40px;

        .header-block {
            .gdlr-core-title-item-title {
                font-family: $heading-font !important;
            }
        }

        .price-wrapper {
            background: $custard;
            padding: 10px 20px;
            border-radius: 2px;
            font-size: 22px;
            margin-top: 15px; // Fix for lineheight
            font-weight: 500;
            color: #fff;
            display: inline-block;

            @media only screen and (max-width: 767px) {
                margin-top: 0;
                padding: 8px 16px;
                font-size: 20px;
            }

            .collection-price, .sold {
                font-size: 18px;
                margin-right: 12px;
            }
            .old-price {
                text-decoration: line-through;
            }
            .price {
                font-weight: 600;
            }
        }
        .extra-info {
            font-style: italic;
            font-size: 13px;
            margin-top: 10px;
        }

        // Design page
        .design-page-wrapper {
            .kitchen-title {
                font-size: 54px;
                letter-spacing: 0;
                text-transform: none;
                color: $black;
                margin-bottom: 10px;

                @include mobile {
                    font-size: 28px;
                }
                @include tablet {
                    font-size: 40px;
                }
                @include desktop {
                    font-size: 48px;
                }
            }
            .product-subtitle {
                font-size: 30px;
                letter-spacing: 0;
                text-transform: none;
                color: $roest;
                font-weight: 500;

                @include mobile {
                    font-size: 22px;
                }
            }
            .heading-2 {
                font-weight: 600;
                font-size: 54px;
                color: $black;
            }
            .heading-3 {
                font-weight: 700;
                font-size: 32px;
                color: $roest;
            }

            // Spacing for sections
            .section-spacing {
                margin: 120px 0;
                @include mobile {
                    margin: 48px 0;
                }
                @include tablet {
                    margin: 60px 0;
                }
            }
            .section-spacing-top {
                margin-top: 120px;

                @include mobile {
                    margin-top: 48px;
                }
                @include tablet {
                    margin-top: 60px;
                }
            }
            .section-spacing-bottom {
                margin-bottom: 120px;
                @include mobile {
                    margin-bottom: 48px;
                }
                @include tablet {
                    margin-bottom: 60px;
                }
            }
            .section-spacing-md {
                margin: 80px 0;
                @include mobile {
                    margin: 48px 0;
                }
                @include tablet {
                    margin: 60px 0;
                }
            }
            .section-spacing-top-md {
                margin-top: 80px;
                @include mobile {
                    margin-top: 48px;
                }
                @include tablet {
                    margin-top: 60px;
                }
            }
            .section-spacing-bottom-md {
                margin-bottom: 80px;
                @include mobile {
                    margin-bottom: 48px;
                }
                @include tablet {
                    margin-bottom: 60px;
                }
            }

            // Spacing for blocks
            .item-spacing {
                margin: 60px 0;
                @include mobile {
                    margin: 40px 0;
                }
            }
            .item-spacing-top {
                margin-top: 60px;
                @include mobile {
                    margin-top: 40px;
                }
            }
            .item-spacing-bottom {
                margin-bottom: 60px;
                @include mobile {
                    margin-bottom: 40px;
                }
            }
            .item-spacing-md {
                margin: 40px 0;
            }
            .item-spacing-top-md {
                margin-top: 40px;
            }
            .item-spacing-bottom-md {
                margin-bottom: 40px;
            }

            .smaller-text-column {
                max-width: 900px;
                text-align: center;
                margin: 0 auto;

                .button {
                    display: inline-block;
                }
            }

            .images-wrapper {
                margin: -20px;

                @media only screen and (max-width: 500px) {
                    margin: -10px;
                }

                .item-block {
                    padding: 20px;

                    @include mobile {
                        width: 50% !important;
                    }
                    @media only screen and (max-width: 500px) {
                        width: 50% !important;
                        padding: 10px;
                    }
                }
                .item-block-full {
                    width: 100% !important;
                }
                .item-block-half {
                    width: 50%;
                    @media only screen and (max-width: 500px) {
                        width: 100% !important;
                    }
                    @include mobile {
                        img {
                            max-height: 400px;
                        }
                    }
                }
                .item-block-one-third {
                    width: 33.333333%;

                    @include mobile {
                        img {
                            max-height: 400px;
                        }
                    }
                }
                .item-block-two-third {
                    width: 66.66667%;

                    @include mobile {
                        img {
                            max-height: 500px;
                        }
                    }

                }
                .item-block-one-fourth {
                    width: 25%;

                    @include mobile {
                        img {
                            max-height: 250px;
                        }
                    }
                }
                .text-block {
                    @include mobile {
                        width: 100% !important;
                    }

                    p {
                        font-size: 18px;
                    }
                    &.text-block-left {
                        padding-right: 40px;
                    }
                    &.text-block-right {
                        padding-left: 40px;
                    }

                    @include mobile-tablet {
                        padding: 40px;
                    }
                }
            }

        }

        .gdlr-core-pbf-column-content-margin {
            padding-top: $content-padding-top;

            @include mobile-tablet {
                padding-top: $content-padding-top-mobile;
            }
        }

        .kitchen-core-wrapper {
            padding: 0 0 80px;
            @include mobile {
                padding-bottom: 40px;
            }
            @include tablet {
                padding: 20px 0 80px;
            }
        }

        .kitchen-main-image {
            position: relative;
            z-index: 2;
            padding-top: $content-padding-top - 10px;

            @include mobile-tablet {
                padding-top: $content-padding-top-mobile - 10px;
            }

            .gdlr-core-image-item {
                padding-left: 50px;

                @include mobile-tablet {
                    padding-left: 20px;
                }

                img {
                    width: 100%;
                }
            }

            .kitchen-image {
                position: relative;

                &.kitchen-image-border {
                    border: 1px solid #e3e3e3 !important;
                }

                .sale, .price {
                    position: absolute;
                    color: #fff;
                    right: 0;
                    display: block;
                    line-height: normal;
                    font-weight: bold;
                }

                .price {
                    background: $black;
                    background: $roest;
                    bottom: 0;
                    padding: 10px 20px;
                    z-index: 10;
                    font-size: 21px;
                    .sold {
                        font-weight: normal;
                        font-size: 19px;
                    }
                    .collection-price {
                        position: relative;
                        top: -1px;
                        font-size: .9em;
                        font-weight: 400;
                        margin-right: 8px;
                    }
                    .old-price {
                        position: relative;
                        display: inline-block;
                        font-weight: normal;
                        margin-right: 8px;
                        &:before {
                            position: absolute;
                            z-index: 2;
                            content: '';
                            left: -3px;
                            top: 50%;
                            right: 0;
                            height: 2px;
                            background-color: #fff;
                            transform: rotate(-6deg);
                            box-shadow: 0 0 10px rgba(0,0,0, .5);
                        }
                    }
                }

                .sale {
                    background: $taupe;
                    background: rgba(0, 0, 0, .8);
                    top: 0;
                    right: 0;
                    padding: 7px 16px 9px;
                    z-index: 10;
                    letter-spacing: .3px;
                    font-family: $heading-font;
                    font-size: 14px;
                }
            }

            .price-description {
                clear: both;
                text-align: right;
                font-style: italic;
                margin-top: 12px;

                p {
                    font-size: .9em;
                }
            }
        }

        .kitchen-text {
            .kitchen-title {
                font-size: 34px;
                letter-spacing: 0;
                text-transform: none;
                color: #111;

                @include mobile {
                    font-size: 28px;
                }
            }

            .back-button {
                margin: 25px 0 10px;
            }

            .gdlr-core-text-box-item {
                padding-bottom: 0 !important;
            }

            .gdlr-core-title-item-caption {
                font-size: 21px;
                font-weight: 500;
                font-style: normal;
                margin-bottom: 5px;

                @include mobile-tablet {
                    margin-top: 0;
                }
            }

            .kitchen-intro {
                font-size: 21px;
                margin-bottom: 25px;
                font-weight: 400;
            }

            h2 {
                font-family: $heading-font;
                font-size: 22px;
                font-weight: bold;
                margin-top: 28px;
            }

            h3 {
                font-family: $heading-font;
                font-size: 20px;
                font-weight: 700;
                margin: 28px 0 14px;
                color: $taupe;
            }
        }

        .social-share {
            clear: both;
            margin-top: 30px;
            border: 1px solid $custard;
            padding: 30px ($priceblock-padding-lr - 2px);

            @include mobile-tablet {
                padding: 20px ($priceblock-padding-lr - 10px);
            }

            @include desktop {
                padding: 30px ($priceblock-padding-lr - 10px);
            }

            .text {
                font-weight: 600;
                float: left;

                @include mobile-tablet {
                    float: none;
                    text-align: center;
                    line-height: normal;
                }
            }

            .share-buttons {
                $size: 35px;

                float: right;
                margin-top: -18px;

                @include mobile-tablet {
                    float: none;
                    margin-top: 15px;
                    text-align: center;
                }

                .at-resp-share-element .at-share-btn {
                    overflow: visible !important;
                }
                .at-resp-share-element .at-share-btn {
                    background: #888 !important;
                }
                .addthis_inline_share_toolbox .at-share-btn {
                    height: $size !important;
                    max-width: $size !important;
                    max-height: $size !important;
                    margin-left: 10px !important;
                }
                .addthis_inline_share_toolbox .at-share-btn span.at-icon-wrapper {
                    line-height: 53px!important;
                }
                .addthis_inline_share_toolbox .at-share-btn .at-icon-wrapper, .addthis_inline_share_toolbox .at-share-btn .at-icon-wrapper:hover {
                    height: $size !important;
                    width: $size !important;
                    text-align: center !important;
                    background: transparent !important;
                    border-radius: 50%;
                    transition: background .2s ease-out;
                    border: none;
                    overflow: visible !important;
                }
                .at-style-responsive .at-share-btn {
                    padding: 4px 0;
                }
                .addthis_inline_share_toolbox .at-share-btn .at-icon-wrapper svg {
                    height: 25px !important;
                    width: 25px !important;
                    fill: #fff !important;
                }
            }
        }

        .usps {
            text-align: left;
            margin-top: 40px;
            h3 {
                font-size: 24px;
                color: $roest;
            }
            ul {
                margin-left: 20px;
                padding: 0;
                list-style-type: none;

                @include responsive-min(1350px) {
                    columns: 2;
                    margin-left: 0;
                }
                li {
                    line-height: 28px;

                    @include responsive-min(1350px) {
                        margin-left: 20px;
                    }

                    &:before {
                        content: "\2022";
                        color: $terra;
                        font-weight: bold;
                        display: inline-block;
                        width: 1.1em;
                        margin-left: -1em;
                    }
                }
            }
        }

        section.kitchen-gallery {
            padding: 70px 0 55px;

            .gdlr-core-gallery-item-style-grid {
                padding-bottom: 0;
            }

            .gdlr-core-title-item {
                text-align: left;
                padding-bottom: 40px !important;

                .gdlr-core-title-item-title {
                    font-size: 28px;
                    color: $text-color;
                    letter-spacing: 0;
                }
            }
        }

        section.other-kitchens {
            padding: 80px;

            .gdlr-core-title-item {
                padding-bottom: 50px !important;

                .gdlr-core-title-item-title {
                    font-size: 32px;
                }
            }

            // Alternatieve stijl, mooi of niet? Moet wel geactiveerd worden wanneer de achtergrond grijs wordt van de section.
            .gdlr-core-portfolio-grid-frame {
                background: #fff !important;
            }

            .kitchen-block {
                box-shadow: 0 0 45px rgba(0, 0, 0, 0.1);
            }

            .button {
                margin: 10px 0;
            }
        }
    }
}
