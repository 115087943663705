body {
    &.content-class-about {
        .about-first-block {
            .gdlr-core-title-item-title {
                font-size: 39px;
                font-weight: 600;
                letter-spacing: 0;
                text-transform: none;
            }
        }

        .about-second-third-block {
            .gdlr-core-title-item-title {
                font-size: 24px;
                font-weight: 500;
                letter-spacing: 0;
                text-transform: none;
            }
        }

        .about-fourth-block {
            .gdlr-core-title-item-title {
                font-size: 19px;
                letter-spacing: 0;
                text-transform: none;
                color: #000000;
            }
        }
    }
}
