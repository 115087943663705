// Default

#content {
    overflow-x: hidden;

    .hide-desktop {
        display: block;

        @include responsive-min(1300px) {
            display: none;
        }
    }
    .show-desktop {
        @include responsive-max(1300px) {
            display: none;
        }
    }

    .gdlr-core-text-box-item-content {
        h2, h3, h4 {
            text-transform: none;
            font-weight: 600;
        }
        h2 {
            font-size: 24px;
        }
        h3 {
            font-size: 20px;
        }
        h4 {
            font-size: 18px;
        }
    }

    .spacing-bottom-small {
        padding-bottom: 18px !important;
    }
    .background-lighter {
        opacity: .2;
    }
    .gdlr-core-blog-title-small {
        font-size: 16px !important;
    }
    .inteco-page-title-wrap .inteco-page-title {
        letter-spacing: 0;
        font-size: 50px;
    }

    .inteco-sidebar-area .inteco-widget-title, .contact-details h3.gdlr-core-title-item-title, .landingspage-sidebar .inteco-widget-title {
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0;
        text-transform: none;

        &.colorized {
            color: $roest;
        }
    }

    .topbar {
        padding: 10px 0;
        background: $green;
        color: #fff;
        z-index: 1;
        position: relative;
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, .2);

        @include mobile-tablet {
            display: none;
        }

        a {
            color: #fff;
            font-size: 15.5px;

            @include desktop {
                font-size: 15px;
            }
        }

        ul {
            list-style-type: none;
            margin: 0;

            li {
                display: inline-block;

                span {
                    margin: 0 8px;
                    color: rgba(255,255,255,.3);

                    @include desktop {
                        margin: 0 6px;
                    }
                    @include responsive-max(1425px) {
                        margin: 0 8px;
                    }
                }
            }
        }

        .left-content {
            float: left;
            margin-left: 290px;

            @include responsive-max(1385px) {
                margin-left: 210px;
                padding-right: 0;
            }

            .show-xl {
                display: none;
                @include responsive-min(1540px) {
                    display: inline-block;
                }
            }
            a {
                text-align: left;
                display: inline-block;
            }
            span {
                margin: 0 10px;
                color: rgba(255,255,255,.4);

                @include responsive-max(1425px) {
                    margin: 0 10px;
                }
                @include desktop {
                    margin: 0 7px;
                }
            }
        }
        .right-content {
            float: right;
            padding-left: 0;

            a {
                margin-left: 8px;
                &:first-of-type {
                    margin-left: 0;
                }
            }
            .menu-links {
                text-align: right;
                display: inline-block;
                li.active-item {
                    text-decoration: underline;
                }

                .text-white {
                    color: #fff;
                }
                .text-smaller {
                    @include desktop {
                        font-size: 15px;
                    }
                }
            }
            .social {
                margin-left: 20px;
                display: inline-block;
                a {
                    font-size: 16px;
                }
            }
        }
        @include clearfix;
    }

    .header-block {
        position: relative;
        padding-top: 250px;
        padding-bottom: 110px;
        z-index: -1;

        @include mobile {
            padding-top: 60px !important;
            padding-bottom: 60px !important;
        }
        @include tablet {
            padding-top: 90px !important;
            padding-bottom: 90px !important;
        }

        &.small-header {
            padding-top: 170px;
            padding-bottom: 120px;
        }

        .gdlr-core-pbf-background {
            background-size: cover;
            background-position: center;
        }

        .header-overlay {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(0,0,0,.55);
        }

        .gdlr-core-pbf-wrapper-content {
            z-index: 2;
            .gdlr-core-skin-title {
                font-size: 50px;
                font-weight: 700;
                letter-spacing: 0;
                text-transform: none;
                color: #ffffff;
                line-height: 1.3em;

                p {
                    line-height: 1.3em;
                    padding: 0;
                    margin-bottom: 0;
                }

                @include mobile {
                    font-size: 34px;
                }
                @include tablet {
                    font-size: 40px;
                }
            }

            .gdlr-core-skin-caption {
                font-size: 22px;
                font-style: normal;
                font-family: $heading-font;
                color: #f2f2f2;
                margin-top: 13px;

                p {
                    line-height: 1.3em;
                    padding: 0;
                }
            }
        }
    }

    .gdlr-core-pbf-background-wrap {
        &.colorized {
            background-color: #f5f5f5 ;
        }
    }

    .keukenboer-logo-slogan {
        max-width: 260px;
        margin: 0 auto 50px;
        display: block;
    }
    .keukenboer-quote {
        font-size: 1.5em;
        line-height: 1.2em;
        color: #000;
        text-align: center;
        margin: 0 auto 60px;

        // Test for meeting 15-09
        font-weight: normal;
        font-size: 1.8em;
        font-family: 'Patrick Hand', cursive;
    }

    .kbc-kdc-wrapper {
        //background: rgb(249,249,249);

        .kbc-kdc-items {
            margin: -15px -15px 30px;
            .item {
                width: 33.33%;
                padding: 15px;

                @include mobile {
                    width: 100%;
                    margin-bottom: 0;
                }
                @include tablet {
                    width: 50%;
                }
                .widget {
                    background: #f7f8f9;
                }
            }
        }

        .info-block {
            .textwidget {
                padding: 0;
            }
            img {
                max-height: 30px;
                margin-bottom: 17px;
            }
        }
        .gdlr-core-column-second {
            @include mobile {
                margin-top: 30px;
            }
        }
    }
    // Footer
    footer {
        .inteco-footer-wrapper {
            @include mobile {
                padding: 40px 0;
            }
            a {
                color: #fff;
            }

            .inteco-item-mglr {
                @include mobile {
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                }
            }

            .inteco-footer-column {
                padding: 0;

                @include mobile {
                    margin-bottom: 30px;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }

                @include tablet {
                    &.first {
                        width: 100%;
                        margin-bottom: 40px;
                    }
                    &.second {
                        width: 28%;
                        margin-left: 0;
                    }
                    &.third {
                        width: 28%;
                    }
                    &.fourth {
                        width: 30%;
                        margin-right: 0;
                        margin-left: 30px;
                    }
                }
            }
        }
    }
    .custom-row {
        display: block;

        @include desktop-up {
            clear: both;
        }
    }
}

// ElegantIcons
@font-face {
    font-family: ElegantIcons;
    src: url(/base/plugins/goodlayers-core/plugins/combine/elegant/ElegantIcons.eot);
    src: url(/base/plugins/goodlayers-core/plugins/combine/elegant//ElegantIconsd41d.eot?#iefix) format('embedded-opentype'), url(/base/plugins/goodlayers-core/plugins/combine/elegant//ElegantIcons.woff) format('woff'), url(/base/plugins/goodlayers-core/plugins/combine/elegant//ElegantIcons.ttf) format('truetype'), url(/base/plugins/goodlayers-core/plugins/combine/elegant//ElegantIcons.svg#ElegantIcons) format('svg');
    font-weight: 400;
    font-style: normal
}

[data-icon]:before {
    font-family: ElegantIcons;
    content: attr(data-icon);
    speak: none;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.icon_close, .icon_close_alt, .icon_close_alt2 {
    font-family: ElegantIcons;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased
}

.icon_close:before {
    content: "\4d"
}

.inteco-overlay-menu-content.inteco-navigation-font.inteco-active a {
    &:after {
        display: none !important;
    }

    .icon {
        margin-left: 13px;
    }
}

body .inteco-navigation .sf-menu > li a .icon {
    display: none;
}

.inteco-overlay-menu-content ul.sub-menu > li {
    font-size: 16px;
}