#content {
    .link-with-border, a.link-with-border {
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0;
        color: #1b1b1b;
        padding: 0 0 2px 0;
        text-transform: none;
        border-radius: 0;
        border-width: 0 0 5px 0;
        border-color: lighten($taupe, 30%);
        &:hover {
            color: $taupe;
        }

        &.margin-top {
            margin-top: 15px;
        }
    }
}