body {
    &.content-class-accessories {
        .gdlr-core-blog-grid-frame {
            .gdlr-core-blog-content {
                margin-bottom: 10px;
                a {
                    display: block;
                    font-weight: bold;
                    margin-top: 15px;
                    color: $roest;
                }
            }

            .gdlr-core-blog-title {
                font-size: 20px;
                font-weight: 600;
                letter-spacing: 0;
                margin-bottom: 10px;
            }
        }

        .subcategory-title {
            .gdlr-core-title-item-caption-top {
                margin-top: 20px;
                padding-bottom: 30px;
                &.first-category {
                    margin-top: 0;
                }
            }
            .gdlr-core-title-item-title {
                font-size: 32px;
                letter-spacing: 0;
                text-transform: none;
            }
        }
    }
}
