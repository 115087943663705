#content {
    .form-wrapper {
        &.hide-error-labels {
            input {
                &.error {
                    background: tint($error-label-color, 80%);
                }
            }
            label.error {
                display: none !important;
            }
        }

        .result-message {
            margin: 10px 0;

            &.no-btm {
                margin-bottom: 0;
            }
            &.success {
                color: $custard;
            }
            &.error {
                color: $terra;
            }
            .icon {
                display: inline-block;
                margin-right: 6px;
            }
        }

        ul.errors {
            margin: 0;
            list-style: none;
            li {
                color: $error-label-color !important;
                font-style: italic;
                font-size: 16px;
                margin: 8px 0;
                font-weight: 600;
            }
        }
    }
    .quform {
        margin-top: 15px;

        .inline {
            padding: 0 10px;
        }

        .quform-elements {

            &.no-whitesace-no-wrap {
                label {
                    white-space: normal;
                }
                .input-fields label {
                    margin-left: 5px;
                }
            }
            .form-row {
                display: table;
                clear: both;
                width: 100%;
            }
            .quform-element {
                margin-bottom: 15px;

                .instructions {
                    line-height: 22px !important;
                    display: block;
                    margin: 5px 0;
                    font-size: 80%;
                    font-style: italic;
                }

                &.last {
                    margin-bottom: 0;
                }
            }

            label {
                margin-bottom: 8px;
                font-weight: 600;
                display: block;
                line-height: normal;

                @include tablet {
                    white-space: nowrap;
                }

                &.error {
                    color: $error-label-color !important;
                    font-style: italic;
                    font-size: 16px;
                    margin: 8px 0;
                }

                small {
                    float: right;
                    margin-bottom: 0;
                }
            }

            .checkbox-group {
                padding-left: 15px;

                &.inline {
                    label {
                        display: inline-block;
                        width: 30%;
                        margin-right: 40px;
                        @include mobile {
                            width: 100%;
                            margin-right: 0;
                        }
                        @include tablet-desktop {
                            width: 40%;
                            white-space: nowrap;
                            margin-right: 10px;
                        }
                    }
                }

                input[type="radio"], input[type="checkbox"] {
                    position: absolute;
                    margin-left: -20px;
                }
            }

            input:not([type="radio"]):not([type="checkbox"]), textarea, select {
                width: 100%;
                padding: 10px !important;
                font-size: 16px !important;
                border: 1px solid transparent !important;
                border-radius: 5px;

                @include tablet {
                    padding: 8px !important;
                }

                &::placeholder {
                    color: #9f9f9f;
                }
                &.error {
                    border-color: $error-label-color !important;
                }
                &.preferred-date {
                    width: 75%;
                    @include responsive-max($breakpoint-desktop-max) {
                        width: 55%;
                    }
                }
                &.preferred-time {
                    width: 20%;
                    margin-left: 10px;
                    @include responsive-max($breakpoint-desktop-max) {
                        width: 35%;
                    }
                }
            }

            select {
                height: 45px;
                background: #f3f3f3;
            }

            textarea {
                min-height: 140px;
            }

            button[type=submit] {
                background: $taupe;
                border-radius: 5px;
                letter-spacing: 0;
                font-size: 16px;
                font-weight: 600;
                padding-top: 10px;
                padding-bottom: 12px;

                &:hover {
                    background: darken($taupe, 10%);
                }
            }
        }
    }

    // FreeForm form rendering
    .freeform-pages {
        display: -ms-flexbox;
        display: flex;
        padding: 0;
        margin: 0 0 10px;
        list-style: none;
        li {
            margin: 0 10px 0 0;
        }
    }
    .freeform-row {
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin: 0 -15px 20px;

        @include mobile {
            display: block;
        }

        &:last-of-type {
            margin-bottom: -10px;
        }

        .freeform-column {
            flex: 1 0;
            padding: 10px 0;
            margin: 0 15px;
            box-sizing: border-box;

            @include mobile {
                flex: none;
            }
            >.freeform-row {
                &:first-child {
                    margin-top: -10px;
                }
            }
            label {
                margin-bottom: 8px;
                font-weight: 600;
                display: block;
                line-height: normal;

                &.error {
                    color: $error-label-color !important;
                    font-style: italic;
                    font-size: 16px;
                    margin: 8px 0;
                }
            }
            .input-group-one-line {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                label {
                    padding-right: 10px;
                }
            }
            .freeform-label {
                margin-bottom: 8px;
                font-weight: 600;
                display: block;
                line-height: normal;
            }
            .freeform-label.freeform-required {
                &:after {
                    content: "*";
                    margin-left: 5px;
                    color: red;
                }
            }
            .freeform-input {
                width: 100%;
                display: block;
                box-sizing: border-box;
                padding: 10px !important;
                font-size: 16px !important;
                border: 1px solid transparent !important;

                &.error {
                    border-color: $error-label-color !important;
                }
            }
            .freeform-input[type=checkbox], .freeform-input[type=radio] {
                width: auto;
                display: inline;
                margin-right: 8px;
                margin-top: -3px;
            }
            .freeform-input.StripeElement {
                -moz-appearance: textfield;
                -webkit-appearance: textfield;
                appearance: textfield;
                border: 1px solid;
                padding-top: 5px;
                height: 26px;
            }
            .freeform-input-only-label {
                font-weight: normal;
                >.freeform-input {
                    display: inline-block;
                    width: auto;
                    margin-right: 5px;
                }
            }
            .freeform-errors {
                list-style: none;
                padding: 0;
                margin: 5px 0 0;
                > li {
                    color: $error-label-color !important;
                }
            }
            .freeform-instructions {
                margin: 0 0 5px;
                font-size: 13px;
                font-weight: 600;
                font-style: italic;
                color: #555;
            }
        }
        h6 {
            margin-bottom: -10px;
        }
        .freeform-column.freeform-column-content-align-left {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-pack: start;
            justify-content: flex-start;
            >button {
                &:not(:first-of-type) {
                    margin-left: 5px;
                }
            }
        }
        .freeform-column.freeform-column-content-align-center {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-pack: center;
            justify-content: center;
            >button {
                &:not(:first-of-type) {
                    margin-left: 5px;
                }
            }
        }
        .freeform-column.freeform-column-content-align-right {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-pack: end;
            justify-content: flex-end;
            >button {
                &:not(:first-of-type) {
                    margin-left: 5px;
                }
            }
        }
        .freeform-column.freeform-column-content-align-spread {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-pack: justify;
            justify-content: space-between;
            >button {
                &:not(:first-of-type) {
                    margin-left: 5px;
                }
            }
        }
    }
    .freeform-form-has-errors {
        color: $error-label-color !important;
    }

    button[type="submit"] {
        font-size: 15px;
        font-weight: 700;
        letter-spacing: .8px;
        padding: 15px;
        width: 100%;
        display: block;
        -webkit-appearance: none;
        background-color: #806d66;
        border: 0;
        color: #fff;
        font-style: normal!important;
        cursor: pointer;
        font-style: normal;
    }

}