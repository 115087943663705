.text-center {
    text-align: center !important;
    .button {
        margin-left: auto;
        margin-right: auto;
    }
}

.text-right {
    text-align: right !important;
}

.hidden {
    display: none;
}

.handwritten {
    font-family: 'Permanent Marker', cursive;
    font-weight: 500;

    &.secondary-font {
        font-family: 'Caveat', cursive;
    }
}

// Tailwind

.flex {
    display: flex;
}
.flex-wrap {
    flex-wrap: wrap;
}
.flex-col {
    flex-direction: column;
}
.items-center {
    align-items: center;
}
.items-start {
    align-items: start;
}
.justify-center {
    justify-content: center;
}
.justify-around {
    justify-content: space-around;
}
.justify-between {
    justify-content: space-between;
}
.items-stretch {
    align-items: stretch;
}
.items-end {
    align-items: end;
}
.mb-40 {
    margin-bottom: 40px;
}
.mt-15 {
    margin-top: 15px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-40 {
    margin-top: 40px;
}
.mt-60 {
    margin-top: 60px;
}
.mb-60 {
    margin-bottom: 60px;
}
.w-1\/3 {
    width: 33.333333%;
}
.mx-auto {
    margin: 0 auto;
}
.p-15 {
    padding: 15px;
}
.relative {
    position: relative;
}
.object-cover  {
    object-fit: cover;
}
.w-full {
    width: 100%;
}
.h-full {
    height: 100%;
}