.inteco-body h1, .inteco-body h2, .inteco-body h3, .inteco-body h4, .inteco-body h5, .inteco-body h6 {
    margin-top: 0px;
    margin-bottom: 20px;
    line-height: 1.2;
    font-weight: 700;
}

#poststuff .gdlr-core-page-builder-body h2 {
    padding: 0px;
    margin-bottom: 20px;
    line-height: 1.2;
    font-weight: 700;
}

#poststuff .gdlr-core-page-builder-body h1 {
    padding: 0px;
    font-weight: 700;
}

.gdlr-core-blog-date-wrapper .gdlr-core-blog-date-day {
    font-size: 27px;
    font-weight: 800;
    letter-spacing: 0px;
    margin-bottom: 5px;
}

.gdlr-core-blog-date-wrapper .gdlr-core-blog-date-month {
    font-size: 17px;
    letter-spacing: 0px;
    text-transform: none;
    font-weight: 600;
}

.gdlr-core-blog-date-wrapper {
    border-right: 0px;
    padding-right: 0px;
    width: 40px;
    margin-right: 14px;
    margin-top: 0px;
}

.gdlr-core-blog-full .gdlr-core-blog-title {
    margin-bottom: 8px;
}

.gdlr-core-blog-full .gdlr-core-blog-date-wrapper {
    margin-top: 10px;
}

.gdlr-core-blog-full .gdlr-core-blog-date-wrapper .gdlr-core-blog-date-day {
    font-size: 30px;
    margin-bottom: 8px;
}

.gdlr-core-blog-grid .gdlr-core-blog-grid-title-wrap {
    overflow: hidden;
}

.gdlr-core-blog-grid .gdlr-core-blog-title {
    margin-bottom: 6px;
}

.gdlr-core-blog-grid .gdlr-core-blog-info-wrapper {
    border: none;
    padding: 0px;
    margin-bottom: 20px;
}

.gdlr-core-blog-grid.gdlr-core-with-divider {
    border-bottom-width: 3px;
    border-bottom-style: solid;
}

.gdlr-core-blog-grid.gdlr-core-blog-grid-with-frame {
    border-bottom-width: 0px;
}

.gdlr-core-blog-info-wrapper .gdlr-core-head {
    vertical-align: baseline;
    margin-right: 7px;
}

.gdlr-core-blog-info-wrapper .gdlr-core-blog-info {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
    font-weight: 600;
    margin-right: 14px;
}

.gdlr-core-column-30 .gdlr-core-blog-grid-with-frame .gdlr-core-blog-grid-frame {
    padding: 45px 40px 30px;
}

.gdlr-core-excerpt-read-more.gdlr-core-button {
    padding: 12px 28px;
}

.gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-icon {
    float: none;
    display: inline-block;
}

.gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-title {
    margin-top: 0px;
    margin-bottom: 20px;
}

.gdlr-core-accordion-style-icon .gdlr-core-accordion-item-tab {
    padding-top: 0px;
}

.gdlr-core-accordion-style-icon .gdlr-core-accordion-item-icon {
    font-size: 27px;
    margin-right: 26px;
}

.gdlr-core-accordion-style-icon .gdlr-core-accordion-item-icon {
    float: none;
    display: inline-block;
    vertical-align: middle;
}

.gdlr-core-accordion-style-icon .gdlr-core-accordion-item-title {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding-bottom: 20px;
    letter-spacing: 0px;
}

.gdlr-core-filterer-wrap.gdlr-core-style-text {
    font-size: 16px;
    font-weight: bold;
    text-transform: none;
    letter-spacing: 1px;
    letter-spacing: 1px;
}

.gdlr-core-filterer-wrap.gdlr-core-style-text .gdlr-core-filterer {
    margin: 0px 12px;
}

.gdlr-core-personnel-item .gdlr-core-personnel-list-title {
    margin-bottom: 5px;
}

.gdlr-core-personnel-style-grid .gdlr-core-personnel-list-divider {
    padding-top: 8px;
}

.gdlr-core-personnel-style-grid .gdlr-core-personnel-list-position {
    margin-bottom: 5px;
}

.gdlr-core-personnel-style-grid .gdlr-core-personnel-list-divider {
    width: auto;
}

.gdlr-core-social-network-item .gdlr-core-social-network-icon {
    margin-left: 15px;
    font-size: 18px;
}

.gdlr-core-title-item .gdlr-core-title-item-divider {
    display: inline-block;
    width: 230px;
    margin-left: 25px;
    position: static;
    margin-top: 0px;
    top: auto;
}

.gdlr-core-title-item-caption-top.gdlr-core-left-align .gdlr-core-title-item-link,
.gdlr-core-title-item-caption-bottom.gdlr-core-left-align .gdlr-core-title-item-link,
.gdlr-core-title-item-caption-right.gdlr-core-left-align .gdlr-core-title-item-link {
    font-style: normal;
    top: auto;
    bottom: 5px;
}

.gdlr-core-block-item-title-wrap .gdlr-core-block-item-read-more {
    position: absolute;
    right: 0px;
    bottom: 5px;
    font-weight: bold;

    span {
        margin-left: 15px;
    }
}

.gdlr-core-block-item-title-wrap.gdlr-core-left-align .gdlr-core-separator {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    width: 230px;
    border-right: 0px;
    height: 0px;
    transform: none;
    -moz-transform: none;
    -webkit-transform: none;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-content {
    font-style: normal;
    font-weight: 800;
    margin-bottom: 31px;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-title {
    font-size: 22px;
    text-transform: none;
    letter-spacing: 0px;
    line-height: 1;
    margin-bottom: 4px;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-position {
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
}

.gdlr-core-testimonial-style-center .gdlr-core-testimonial-quote {
    font-size: 100px;
    font-weight: 700;
    padding: 15px 0px 15px;
}

html .gdlr-core-product-thumbnail span.onsale {
    font-size: 15px;
    width: 70px;
    height: 70px;
    text-align: center;
    padding: 12px 0px 0px;
    left: 15px;
    top: 15px;
    z-index: 0;
}

.gdlr-core-product-grid .gdlr-core-product-title {
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 8px;
}

.gdlr-core-product-grid .gdlr-core-product-price {
    font-size: 18px;
}

.gdlr-core-product-item.woocommerce .gdlr-core-product-thumbnail-info {
    position: absolute;
    top: 0;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(255, 255, 255, 0.8);
}

.gdlr-core-product-item.woocommerce .gdlr-core-product-thumbnail-info > a {
    float: none;
    width: auto;
    height: auto;
    padding: 0px;
    margin-top: -38px;
    font-size: 19px;
    font-weight: 700;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50%;
    bottom: auto;
    color: #0c0c0c;
    background: transparent;
}

.gdlr-core-product-item.woocommerce .gdlr-core-product-thumbnail-info .icon_cart_alt:before {
    content: "\e013";
}

.gdlr-core-product-item.woocommerce .gdlr-core-product-thumbnail-info i {
    font-size: 34px;
    font-weight: normal;
    line-height: 34px;
    display: block;
    margin-bottom: 23px;
    margin-right: 0px;
}

.gdlr-core-product-item.woocommerce .gdlr-core-product-thumbnail-info > a.added_to_cart:before {
    font-size: 34px;
    font-weight: normal;
    line-height: 34px;
    display: block;
    margin-bottom: 25px;
    margin-right: 0px;
}

.gdlr-core-portfolio-grid .gdlr-core-portfolio-content-wrap .gdlr-core-portfolio-info {
    font-size: 15px;
    font-style: normal;
}

[data-layout="masonry"] .gdlr-core-item-list .gdlr-core-portfolio-thumbnail {
    margin-right: -1px;
}

.gdlr-core-body .gdlr-core-load-more-wrap .gdlr-core-load-more {
    font-size: 13.5px;
    margin-top: 10px;
    padding: 0px 0px 7px;
    letter-spacing: 1px;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    background: transparent;
}

.gdlr-core-body .gdlr-core-load-more-wrap .gdlr-core-load-more:hover {
    background: transparent;
}

.gdlr-core-counter-item .gdlr-core-counter-item-number {
    margin-bottom: 5px;
    letter-spacing: 3px;
}

.gdlr-core-counter-item .gdlr-core-counter-item-bottom-text {
    letter-spacing: 2px;
}

.gdlr-core-gallery-item .gdlr-core-gallery-caption {
    font-style: normal;
}

.gdlr-core-price-table .gdlr-core-price-table-price {
    padding: 40px 20px 40px;
}

.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-price {
    padding: 51px 20px 50px;
}

.gdlr-core-skill-bar-item.gdlr-core-size-small .gdlr-core-skill-bar-head {
    font-size: 15px;
}

.gdlr-core-skill-bar-item .gdlr-core-skill-bar-title {
    text-transform: none;
    letter-spacing: 0px;
}

.gdlr-core-flexslider .flex-control-nav li {
    margin: 0px 4px;
}

.gdlr-core-recent-portfolio-widget {
    max-width: 79px;
    margin-right: 15px;
    margin-bottom: 15px;
}

.gdlr-core-input-wrap.gdlr-core-large input:not([type="button"]):not([type="submit"]):not([type="file"]) {
    padding: 18px 22px;
}

.gdlr-core-input-wrap input[type="button"], .gdlr-core-input-wrap input[type="submit"], .gdlr-core-input-wrap input[type="reset"] {
    padding: 19px 33px;
}

.gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-info {
    font-style: normal;
}

.gdlr-core-portfolio-thumbnail.gdlr-core-style-title-tag .gdlr-core-portfolio-info {
    margin-top: 10px;
}

@media only screen and (max-width: $breakpoint-tablet-max) {
    .gdlr-core-title-item .gdlr-core-title-item-divider {
        margin-left: 0px;
    }

    .gdlr-core-title-item .gdlr-core-title-item-title {
        display: block;
    }

    .gdlr-core-block-item-title-wrap .gdlr-core-block-item-read-more {
        position: static;
    }

    .gdlr-core-block-item-title-wrap {
        margin-left: 0px !important;
    }
}

body.inteco-boxed .inteco-body-wrapper {
    margin-top: 0px;
    margin-bottom: 0px;
}

.inteco-body-wrapper.inteco-with-frame, body.inteco-full .inteco-fixed-footer {
    margin: 0px;
}

.inteco-body-background {
    opacity: 1;
}

.inteco-background-pattern .inteco-body-outer-wrapper {
   // background-image: url(../plugins/goodlayers-core/include/images/pattern/pattern-1.png);
}

.inteco-item-pdlr, .gdlr-core-item-pdlr {
    padding-left: 20px;
    padding-right: 20px;
}

.inteco-item-rvpdlr, .gdlr-core-item-rvpdlr {
    margin-left: -20px;
    margin-right: -20px;
}

.gdlr-core-metro-rvpdlr {
    margin-top: -20px;
    margin-right: -20px;
    margin-bottom: -20px;
    margin-left: -20px;
}

.inteco-item-mglr, .gdlr-core-item-mglr, .inteco-navigation .sf-menu > .inteco-mega-menu .sf-mega, .sf-menu.inteco-top-bar-menu > .inteco-mega-menu .sf-mega {
    margin-left: 20px;
    margin-right: 20px;
}

.inteco-body .gdlr-core-personnel-item .gdlr-core-flexslider.gdlr-core-with-outer-frame-element .flex-viewport, .inteco-body .gdlr-core-hover-box-item .gdlr-core-flexslider.gdlr-core-with-outer-frame-element .flex-viewport, .inteco-body .gdlr-core-blog-item .gdlr-core-flexslider.gdlr-core-with-outer-frame-element .flex-viewport {
    padding-top: 20px;
    margin-top: -20px;
    padding-right: 20px;
    margin-right: -20px;
    padding-left: 20px;
    margin-left: -20px;
    padding-bottom: 20px;
    margin-bottom: -20px;
}

.gdlr-core-pbf-wrapper-container-inner {
    width: calc(100% - 20px - 20px);
}

.inteco-container, .gdlr-core-container, body.inteco-boxed .inteco-body-wrapper, body.inteco-boxed .inteco-fixed-footer .inteco-footer-wrapper, body.inteco-boxed .inteco-fixed-footer .inteco-copyright-wrapper {
    max-width: 1340px;
}

.inteco-body-front .gdlr-core-container, .inteco-body-front .inteco-container {
    padding-left: 20px;
    padding-right: 20px;
}

.inteco-body-front .inteco-container .inteco-container, .inteco-body-front .inteco-container .gdlr-core-container, .inteco-body-front .gdlr-core-container .gdlr-core-container {
    padding-left: 0px;
    padding-right: 0px;
}

.inteco-top-bar-container.inteco-top-bar-custom-container {
    max-width: 1140px;
}

.inteco-top-bar-container.inteco-top-bar-full {
    padding-right: 15px;
    padding-left: 15px;
}

.inteco-top-bar {
    padding-top: 10px;
}

.inteco-top-bar {
    padding-bottom: 10px;
}

.inteco-top-bar .inteco-top-bar-menu > li > a {
    padding-bottom: 10px;
}

.sf-menu.inteco-top-bar-menu > .inteco-mega-menu .sf-mega, .sf-menu.inteco-top-bar-menu > .inteco-normal-menu ul {
    margin-top: 10px;
}

.inteco-top-bar {
    font-size: 15px;
}

.inteco-top-bar {
    border-bottom-width: 0px;
}

.inteco-header-style-plain {
    border-bottom-width: 0px;
}

.inteco-header-background-transparent .inteco-top-bar-background {
    opacity: 0.5;
}

.inteco-header-background-transparent .inteco-header-background {
    opacity: 0;
}

.inteco-navigation-bar-wrap.inteco-style-transparent .inteco-navigation-background {
    opacity: 0.5;
}

.inteco-header-boxed-wrap .inteco-top-bar-background {
    opacity: 0;
}

.inteco-header-boxed-wrap .inteco-top-bar-background {
    margin-bottom: -0px;
}

.inteco-header-style-boxed {
    margin-top: 0px;
}

.inteco-header-container.inteco-header-custom-container {
    max-width: 1140px;
}

.inteco-header-container.inteco-header-full {
    padding-right: 60px;
    padding-left: 60px;
}

.inteco-header-boxed-wrap .inteco-header-background {
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}

.inteco-header-style-boxed .inteco-header-container-item {
    padding-left: 30px;
    padding-right: 30px;
}

.inteco-navigation-right {
    right: 30px;
}

.inteco-navigation-left {
    left: 30px;
}

.inteco-header-style-plain.inteco-style-splitted-menu .inteco-navigation .sf-menu > li > a {
    padding-top: 0px;
}

.inteco-header-style-plain.inteco-style-splitted-menu .inteco-main-menu-left-wrap, .inteco-header-style-plain.inteco-style-splitted-menu .inteco-main-menu-right-wrap {
    padding-top: 0px;
}

.inteco-header-style-boxed.inteco-style-splitted-menu .inteco-navigation .sf-menu > li > a {
    padding-top: 0px;
}

.inteco-header-style-boxed.inteco-style-splitted-menu .inteco-main-menu-left-wrap, .inteco-header-style-boxed.inteco-style-splitted-menu .inteco-main-menu-right-wrap {
    padding-top: 0px;
}

.inteco-navigation .sf-menu > li {
    padding-left: 16px;
    padding-right: 16px;
}

.inteco-navigation .inteco-main-menu {
    margin-left: 0px;
}

.inteco-header-side-nav {
    width: 340px;
}

.inteco-header-side-content.inteco-style-left {
    margin-left: 340px;
}

.inteco-header-side-content.inteco-style-right {
    margin-right: 340px;
}

.inteco-header-side-nav.inteco-style-side {
    padding-left: 70px;
    padding-right: 70px;
}

.inteco-header-side-nav.inteco-style-left .sf-vertical > li > ul.sub-menu {
    padding-left: 70px;
}

.inteco-header-side-nav.inteco-style-right .sf-vertical > li > ul.sub-menu {
    padding-right: 70px;
}

.inteco-navigation .sf-vertical > li {
    padding-top: 16px;
    padding-bottom: 16px;
}

.inteco-header-style-bar .inteco-logo-right-text {
    padding-top: 30px;
}

.inteco-logo {
    padding-top: 82px;
}

.inteco-logo {
    padding-bottom: 60px;
}

.inteco-logo-inner {
    max-width: 125px;
}

@media only screen and (max-width: $breakpoint-tablet-max) {
    .inteco-mobile-header .inteco-logo-inner {
        max-width: 90px;
    }
}

@media only screen and (max-width: $breakpoint-tablet-max) {
    .inteco-mobile-header .inteco-logo-inner {
        max-width: 70px;
    }
}

.inteco-navigation, .inteco-header-right-social {
    padding-top: 88px;
}

.inteco-navigation-top {
    top: 88px;
}

.inteco-navigation .sf-menu > li > a {
    padding-bottom: 20px;
}

.inteco-navigation .inteco-main-menu {
    padding-right: 0px;
}

.inteco-main-menu-right-wrap {
    margin-top: 4px;
}

.inteco-fixed-navigation.inteco-style-slide .inteco-logo-inner img {
    max-height: none !important;
}

.inteco-animate-fixed-navigation.inteco-header-style-plain .inteco-logo-inner, .inteco-animate-fixed-navigation.inteco-header-style-boxed .inteco-logo-inner {
    max-width: 80px;
}

.inteco-animate-fixed-navigation.inteco-header-style-plain .inteco-logo, .inteco-animate-fixed-navigation.inteco-header-style-boxed .inteco-logo {
    padding-top: 23px;
}

.inteco-animate-fixed-navigation.inteco-header-style-plain .inteco-logo, .inteco-animate-fixed-navigation.inteco-header-style-boxed .inteco-logo {
    padding-bottom: 30px;
}

.inteco-animate-fixed-navigation.inteco-header-style-plain .inteco-navigation, .inteco-animate-fixed-navigation.inteco-header-style-boxed .inteco-navigation, .inteco-animate-fixed-navigation.inteco-header-style-plain .inteco-header-right-social {
    padding-top: 28px;
}

.inteco-animate-fixed-navigation.inteco-header-style-plain .inteco-navigation-top, .inteco-animate-fixed-navigation.inteco-header-style-boxed .inteco-navigation-top {
    top: 28px;
}

.inteco-animate-fixed-navigation.inteco-header-style-plain .inteco-navigation .sf-menu > li > a, .inteco-animate-fixed-navigation.inteco-header-style-boxed .inteco-navigation .sf-menu > li > a {
    padding-bottom: 28px;
}

.inteco-page-title-wrap.inteco-style-custom .inteco-page-title-content {
    padding-top: 120px;
}

.inteco-page-title-wrap.inteco-style-custom .inteco-page-title-content {
    padding-bottom: 155px;
}

.inteco-page-title-wrap.inteco-style-custom .inteco-page-caption {
    margin-top: 13px;
}

.inteco-page-title-wrap .inteco-page-title {
    text-transform: none;
}

.inteco-page-title-wrap.inteco-style-custom .inteco-page-title {
    font-size: 60px;
}

.inteco-page-title-wrap.inteco-style-custom .inteco-page-title {
    letter-spacing: 0px;
}

.inteco-page-title-wrap.inteco-style-custom .inteco-page-caption {
    font-size: 20px;
}

.inteco-page-title-wrap.inteco-style-custom .inteco-page-caption {
    letter-spacing: 0px;
}

.inteco-page-title-wrap .inteco-page-title-top-gradient {
    height: 413px;
}

.inteco-page-title-wrap .inteco-page-title-bottom-gradient {
    height: 413px;
}

.inteco-page-title-wrap .inteco-page-title-overlay {
    opacity: 0;
}

.inteco-page-title-wrap, body.single-product .inteco-header-transparent-substitute {
   // background-image: url(../upload/port-title-bg.jpg);
}

body.single-portfolio .inteco-page-title-wrap {
    //background-image: url(../upload/port-title-bg-1.jpg);
}

body.single-product .inteco-header-transparent-substitute {
    //background-image: url(../upload/port-title-bg-1.jpg);
}

.inteco-not-found-wrap .inteco-not-found-background {
   // background-image: url(2016/06/image-bg-2.jpg);
}

.inteco-not-found-wrap .inteco-not-found-background {
    opacity: 0.27;
}

.inteco-blog-title-wrap.inteco-style-custom .inteco-blog-title-content {
    padding-top: 65px;
}

.inteco-blog-title-wrap.inteco-style-custom .inteco-blog-title-content {
    padding-bottom: 130px;
}

.inteco-blog-title-wrap {
   // background-image: url(../upload/port-title-bg-1.jpg);
}

.inteco-blog-title-wrap.inteco-feature-image .inteco-blog-title-top-overlay {
    height: 413px;
}

.inteco-blog-title-wrap.inteco-feature-image .inteco-blog-title-bottom-overlay {
    height: 413px;
}

.inteco-blog-title-wrap .inteco-blog-title-overlay {
    opacity: 0.12;
}

body.single-post .inteco-sidebar-style-none, body.blog .inteco-sidebar-style-none, .inteco-blog-style-2 .inteco-comment-content {
    max-width: 900px;
}

.gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-icon {
    font-size: 30px;
}

.inteco-footer-wrapper {
    padding-top: 80px;
}

.inteco-footer-wrapper {
    padding-bottom: 55px;
}

.inteco-copyright-container {
    padding-top: 33px;
}

.inteco-copyright-container {
    padding-bottom: 33px;
}

.inteco-body h1, .inteco-body h2, .inteco-body h3, .inteco-body h4, .inteco-body h5, .inteco-body h6, .inteco-body .inteco-title-font, .inteco-body .gdlr-core-title-font {
    font-family: ArcaMajora Heavy;
}

.gdlr-core-body .gdlr-core-load-more, .gdlr-core-body .gdlr-core-button, .inteco-body .inteco-button, .inteco-body input[type="button"], .inteco-body input[type="submit"], .gdlr-core-title-item .gdlr-core-title-item-link, .gdlr-core-block-item-title-wrap .gdlr-core-block-item-read-more, .gdlr-core-filterer-wrap, .woocommerce-breadcrumb, .woocommerce span.onsale, .gdlr-core-port-info-item, .single-product.woocommerce div.product p.price .woocommerce-Price-amount, .single-product.woocommerce #review_form #respond label {
    font-family: ArcaMajora Heavy;
}

.inteco-navigation .sf-menu > li > a, .inteco-navigation .sf-vertical > li > a, .inteco-navigation-font {
    font-family: ArcaMajora Bold;
}

.inteco-body, .inteco-body .gdlr-core-content-font, .inteco-body input, .inteco-body textarea, .inteco-body button, .inteco-body select, .inteco-body .inteco-content-font, .gdlr-core-audio .mejs-container * {
    font-family: "Source Sans Pro", sans-serif;
}

.inteco-body .gdlr-core-info-font, .inteco-body .inteco-info-font {
    font-family: ArcaMajora Bold;
}

.inteco-body .gdlr-core-blog-info-font, .inteco-body .inteco-blog-info-font {
    font-family: "Source Sans Pro", sans-serif;
}

.inteco-body .gdlr-core-quote-font, blockquote {
    font-family: "Source Sans Pro", sans-serif;
}

.inteco-body .gdlr-core-testimonial-content {
    font-family: ArcaMajora Bold;
}

.inteco-body h1 {
    font-size: 52px;
}

.inteco-body h2, #poststuff .gdlr-core-page-builder-body h2 {
    font-size: 48px;
}

.inteco-body h3 {
    font-size: 36px;
}

.inteco-body h4 {
    font-size: 28px;
}

.inteco-body h5 {
    font-size: 22px;
}

.inteco-body h6 {
    font-size: 18px;
}

.inteco-body {
    font-size: 17px;
}

.inteco-body, .inteco-body p, .inteco-line-height, .gdlr-core-line-height {
    line-height: 1.8;
}

.inteco-navigation .sf-menu > li > a, .inteco-navigation .sf-vertical > li > a {
    font-size: 16px;
}

.inteco-navigation .sf-menu > li > a, .inteco-navigation .sf-vertical > li > a {
    font-weight: 600;
}

.inteco-navigation .sf-menu > li > a, .inteco-navigation .sf-vertical > li > a {
    text-transform: none;
}

.inteco-main-menu-right-button {
    font-size: 11px;
}

.inteco-main-menu-right-button {
    text-transform: none;
}

.inteco-sidebar-area .inteco-widget-title {
    font-size: 13px;
}

.inteco-sidebar-area .inteco-widget-title {
    font-weight: 800;
}

.inteco-sidebar-area .inteco-widget-title {
    letter-spacing: 2px;
}

.inteco-sidebar-area .inteco-widget-title {
    text-transform: uppercase;
}

.inteco-footer-wrapper .inteco-widget-title {
    font-size: 20px;
}

.inteco-footer-wrapper .inteco-widget-title {
    font-weight: 700;
}

.inteco-footer-wrapper .inteco-widget-title {
    letter-spacing: 0px;
}

.inteco-footer-wrapper .inteco-widget-title {
    text-transform: none;
}

.inteco-footer-wrapper {
    font-size: 16px;
}

.inteco-footer-wrapper .widget_text {
    font-weight: 400;
}

.inteco-footer-wrapper .widget_text {
    text-transform: none;
}

.inteco-copyright-text, .inteco-copyright-left, .inteco-copyright-right {
    font-size: 17px;
}

.inteco-copyright-text, .inteco-copyright-left, .inteco-copyright-right {
    letter-spacing: 0px;
}

.inteco-copyright-text, .inteco-copyright-left, .inteco-copyright-right {
    text-transform: none;
}

.inteco-top-bar-background {
    background-color: #222222;
}

.inteco-body .inteco-top-bar {
    border-bottom-color: #ffffff;
}

.inteco-top-bar {
    color: #ffffff;
}

.inteco-body .inteco-top-bar a {
    color: #ffffff;
}

.inteco-body .inteco-top-bar a:hover {
    color: #ffffff;
}

.inteco-top-bar .inteco-top-bar-right-social a, .inteco-body .inteco-header-right-social a {
    color: #ffffff;
}

.inteco-top-bar .inteco-top-bar-right-social a:hover, .inteco-body .inteco-header-right-social a:hover {
    color: #b39a96;
}

.inteco-header-background, .inteco-sticky-menu-placeholder, .inteco-header-style-boxed.inteco-fixed-navigation {
    background-color: #0f0f0f;
}

.inteco-header-wrap.inteco-header-style-plain {
    border-color: #e8e8e8;
}

.inteco-header-side-nav.inteco-style-side-toggle .inteco-logo {
    background-color: #ffffff;
}

.inteco-header-style-plain.inteco-style-float-left .inteco-logo-bottom-divider {
    background: #b39a96;
}

.inteco-header-style-plain.inteco-style-float-left .inteco-logo-bottom-divider {
    background: -webkit-linear-gradient(to left, rgba(179, 154, 150, 1), rgba(179, 154, 150, 0.1));
    background: -o-linear-gradient(to left, rgba(179, 154, 150, 1), rgba(179, 154, 150, 0.1));
    background: -moz-linear-gradient(to left, rgba(179, 154, 150, 1), rgba(179, 154, 150, 0.1));
    background: linear-gradient(to left, rgba(179, 154, 150, 1), rgba(179, 154, 150, 0.1));
}

.inteco-top-menu-button i, .inteco-mobile-menu-button i {
    color: #ffffff;
}

.inteco-mobile-button-hamburger:before, .inteco-mobile-button-hamburger:after, .inteco-mobile-button-hamburger span {
    background: #ffffff;
}

.inteco-main-menu-right .inteco-top-menu-button, .inteco-mobile-menu .inteco-mobile-menu-button {
    border-color: #ffffff;
}

.inteco-top-search-wrap {
    background-color: #000000;
    background-color: rgba(0, 0, 0, 0.88);
}

.inteco-top-cart-content-wrap .inteco-top-cart-content {
    background-color: #232323;
}

.inteco-top-cart-content-wrap .inteco-top-cart-content span, .inteco-top-cart-content-wrap .inteco-top-cart-content span.woocommerce-Price-amount.amount {
    color: #b5b5b5;
}

.inteco-top-cart-content-wrap .inteco-top-cart-button, .inteco-top-cart-content-wrap .inteco-top-cart-button:hover {
    color: #ffffff;
}

.inteco-top-cart-content-wrap .inteco-top-cart-checkout-button, .inteco-top-cart-content-wrap .inteco-top-cart-checkout-button:hover {
    color: #b39a96;
}

.inteco-body .inteco-breadcrumbs, .inteco-body .inteco-breadcrumbs a span, .gdlr-core-breadcrumbs-item, .gdlr-core-breadcrumbs-item a span {
    color: #c0c0c0;
}

.inteco-body .inteco-breadcrumbs span, .inteco-body .inteco-breadcrumbs a:hover span, .gdlr-core-breadcrumbs-item span, .gdlr-core-breadcrumbs-item a:hover span {
    color: #777777;
}

.inteco-navigation-background {
    background-color: #f4f4f4;
}

.inteco-navigation-bar-wrap {
    border-color: #e8e8e8;
}

.inteco-navigation .inteco-navigation-slide-bar {
    border-color: #b39a96;
}

.inteco-navigation .inteco-navigation-slide-bar:before {
    border-bottom-color: #b39a96;
}

.sf-menu > li > a, .sf-vertical > li > a {
    color: #ffffff;
}

.sf-menu > li > a:hover, .sf-menu > li.current-menu-item > a, .sf-menu > li.current-menu-ancestor > a, .sf-vertical > li > a:hover, .sf-vertical > li.current-menu-item > a, .sf-vertical > li.current-menu-ancestor > a {
    color: #ffffff;
}

.sf-menu > .inteco-normal-menu li, .sf-menu > .inteco-mega-menu > .sf-mega, .sf-vertical ul.sub-menu li, ul.sf-menu > .menu-item-language li {
    background-color: #191919;
}

.sf-menu > li > .sub-menu a, .sf-menu > .inteco-mega-menu > .sf-mega a, .sf-vertical ul.sub-menu li a {
    color: #bebebe;
}

.sf-menu > li > .sub-menu a:hover, .sf-menu > li > .sub-menu .current-menu-item > a, .sf-menu > li > .sub-menu .current-menu-ancestor > a, .sf-menu > .inteco-mega-menu > .sf-mega a:hover, .sf-menu > .inteco-mega-menu > .sf-mega .current-menu-item > a, .sf-vertical > li > .sub-menu a:hover, .sf-vertical > li > .sub-menu .current-menu-item > a, .sf-vertical > li > .sub-menu .current-menu-ancestor > a {
    color: #ffffff;
}

.sf-menu > li > .sub-menu a:hover, .sf-menu > li > .sub-menu .current-menu-item > a, .sf-menu > li > .sub-menu .current-menu-ancestor > a, .sf-menu > .inteco-mega-menu > .sf-mega a:hover, .sf-menu > .inteco-mega-menu > .sf-mega .current-menu-item > a, .sf-vertical > li > .sub-menu a:hover, .sf-vertical > li > .sub-menu .current-menu-item > a, .sf-vertical > li > .sub-menu .current-menu-ancestor > a {
    background-color: #1c1c1c;
}

.inteco-navigation .sf-menu > .inteco-mega-menu .sf-mega-section-inner > a {
    color: #ffffff;
}

.inteco-navigation .sf-menu > .inteco-mega-menu .sf-mega-section {
    border-color: #424242;
}

.mm-navbar .mm-title, .mm-navbar .mm-btn, ul.mm-listview li > a, ul.mm-listview li > span {
    color: #979797;
}

ul.mm-listview li a {
    border-color: #979797;
}

.mm-arrow:after, .mm-next:after, .mm-prev:before {
    border-color: #979797;
}

.mm-navbar .mm-title:hover, .mm-navbar .mm-btn:hover, ul.mm-listview li a:hover, ul.mm-listview li > span:hover, ul.mm-listview li.current-menu-item > a, ul.mm-listview li.current-menu-ancestor > a, ul.mm-listview li.current-menu-ancestor > span {
    color: #ffffff;
}

.mm-menu {
    background-color: #1f1f1f;
}

ul.mm-listview li {
    border-color: #626262;
}

.inteco-overlay-menu-content {
    background-color: #000000;
    background-color: rgba(0, 0, 0, 0.88);
}

.inteco-overlay-menu-content ul.menu > li, .inteco-overlay-menu-content ul.sub-menu ul.sub-menu {
    border-color: #424242;
}

.inteco-overlay-menu-content ul li a, .inteco-overlay-menu-content .inteco-overlay-menu-close {
    color: #ffffff;
}

.inteco-overlay-menu-content ul li a:hover {
    color: #a8a8a8;
}

.inteco-bullet-anchor a:before {
    background-color: #777777;
}

.inteco-bullet-anchor a:hover, .inteco-bullet-anchor a.current-menu-item {
    border-color: #ffffff;
}

.inteco-bullet-anchor a:hover:before, .inteco-bullet-anchor a.current-menu-item:before {
    background: #ffffff;
}

.inteco-main-menu-search i, .inteco-main-menu-cart i {
    color: #ffffff;
}

.inteco-main-menu-cart > .inteco-top-cart-count {
    background-color: #b39a96;
}

.inteco-main-menu-cart > .inteco-top-cart-count {
    color: #ffffff;
}

.inteco-body .inteco-main-menu-right-button {
    color: #3d3d3d;
}

.inteco-body .inteco-main-menu-right-button:hover {
    color: #555555;
}

.inteco-body .inteco-main-menu-right-button {
    background-color: #ffffff;
}

.inteco-body .inteco-main-menu-right-button {
    border-color: #ffffff;
}

.inteco-body .inteco-main-menu-right-button:hover {
    border-color: #555555;
}

.inteco-page-preload {
    background-color: #ffffff;
}

.inteco-body-outer-wrapper, body.inteco-full .inteco-body-wrapper, .inteco-header-side-content {
    background-color: #ffffff;
}

body.inteco-boxed .inteco-body-wrapper, .gdlr-core-page-builder .gdlr-core-page-builder-body.gdlr-core-pb-livemode {
    background-color: #ffffff;
}

.inteco-page-title-wrap .inteco-page-title {
    color: #ffffff;
}

.inteco-page-title-wrap .inteco-page-caption {
    color: #ffffff;
}

.inteco-page-title-wrap .inteco-page-caption .woocommerce-breadcrumb, .inteco-page-title-wrap .inteco-page-caption .woocommerce-breadcrumb a {
    color: #ffffff;
}

.inteco-page-title-wrap .inteco-page-title-overlay {
    background-color: #000000;
}

.inteco-page-title-wrap .inteco-page-title-top-gradient {
    background: -webkit-linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    background: -o-linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    background: -moz-linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}

.inteco-page-title-wrap .inteco-page-title-bottom-gradient {
    background: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    background: -o-linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    background: -moz-linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}

.inteco-body, .inteco-body span.wpcf7-not-valid-tip {
    color: #6b6b6b;
}

.inteco-body h1, .inteco-body h2, .inteco-body h3, .inteco-body h4, .inteco-body h5, .inteco-body h6 {
    color: #161616;
}

.woocommerce table.shop_attributes th, .woocommerce table.shop_table th, .single-product.woocommerce div.product .product_meta .inteco-head {
    color: #161616;
}

.inteco-body a {
    color: #3d3d3d;
}

.inteco-body a:hover {
    color: #606060;
}

.inteco-body * {
    border-color: #e6e6e6;
}

.inteco-body .gdlr-core-portfolio-single-nav-wrap {
    border-color: #e6e6e6;
}

.gdlr-core-columnize-item .gdlr-core-columnize-item-content {
    column-rule-color: #e6e6e6;
    -moz-column-rule-color: #e6e6e6;
    -webkit-column-rule-color: #e6e6e6;
}

.inteco-body input, .inteco-body textarea {
    background-color: #f3f3f3;
}

.inteco-body input, .inteco-body textarea {
    border-color: #e0e0e0;
}

.inteco-body input, .inteco-body textarea {
    color: #4e4e4e;
}

.inteco-body ::-webkit-input-placeholder {
    color: #5e5e5e;
}

.inteco-body ::-moz-placeholder {
    color: #5e5e5e;
}

.inteco-body :-ms-input-placeholder {
    color: #5e5e5e;
}

.inteco-body :-moz-placeholder {
    color: #5e5e5e;
}

.inteco-not-found-wrap {
    background-color: #23618e;
}

.inteco-not-found-wrap .inteco-not-found-head {
    color: #ffffff;
}

.inteco-not-found-wrap .inteco-not-found-title {
    color: #ffffff;
}

.inteco-not-found-wrap .inteco-not-found-caption {
    color: #c3e7ff;
}

.inteco-not-found-wrap form.search-form input.search-field {
    background-color: #000000;
    background-color: rgba(0, 0, 0, 0.4)
}

.inteco-not-found-wrap form.search-form input.search-field, .inteco-not-found-wrap .inteco-top-search-submit {
    color: #ffffff;
}

.inteco-not-found-wrap input::-webkit-input-placeholder {
    color: #ffffff;
}

.inteco-not-found-wrap input:-moz-placeholder {
    color: #ffffff;
}

.inteco-not-found-wrap input::-moz-placeholder {
    color: #ffffff;
}

.inteco-not-found-wrap input:-ms-input-placeholder {
    color: #ffffff;
}

.inteco-not-found-wrap .inteco-not-found-back-to-home a, .inteco-not-found-wrap .inteco-not-found-back-to-home a:hover {
    color: #ffffff;
}

.inteco-sidebar-area .inteco-widget-title {
    color: #383838;
}

.inteco-sidebar-area a {
    color: #484848;
}

.widget_recent_entries ul li:before, .widget_recent_comments ul li:before, .widget_pages ul li:before, .widget_rss ul li:before, .widget_archive ul li:before, .widget_categories ul li:before, .widget_nav_menu ul li:before, .widget_meta ul li:before {
    color: #484848;
}

.inteco-sidebar-area a:hover, .inteco-sidebar-area .current-menu-item > a {
    color: #323232;
}

.gdlr-core-recent-post-widget .gdlr-core-blog-info i {
    color: #9c9c9c;
}

.gdlr-core-recent-post-widget .gdlr-core-blog-info, .gdlr-core-recent-post-widget .gdlr-core-blog-info a, .gdlr-core-recent-post-widget .gdlr-core-blog-info a:hover {
    color: #a0a0a0;
}

.gdlr-core-post-slider-widget-overlay .gdlr-core-post-slider-widget-title {
    color: #ffffff;
}

.gdlr-core-post-slider-widget-overlay .gdlr-core-blog-info, .gdlr-core-post-slider-widget-overlay .gdlr-core-blog-info i, .gdlr-core-post-slider-widget-overlay .gdlr-core-blog-info a, .gdlr-core-post-slider-widget-overlay .gdlr-core-blog-info a:hover {
    color: #9c9c9c;
}

.widget_search input.search-field {
    color: #727272;
}

.widget_search input::-webkit-input-placeholder {
    color: #727272;
}

.widget_search input:-moz-placeholder {
    color: #727272;
}

.widget_search input::-moz-placeholder {
    color: #727272;
}

.widget_search input:-ms-input-placeholder {
    color: #727272;
}

.widget_search input.search-field {
    border-color: #f3f3f3;
}

.widget_search form:after {
    border-color: #c7c7c7;
}

.inteco-body .tagcloud a, .inteco-body .tagcloud a:hover {
    color: #ffffff;
}

.inteco-body .tagcloud a {
    background-color: #af938f;
}

.inteco-body .tagcloud a {
    border-color: #af938f;
}

ul.gdlr-core-twitter-widget-wrap li:before {
    color: #303030;
}

ul.gdlr-core-twitter-widget-wrap li .gdlr-core-twitter-widget-date a, ul.gdlr-core-twitter-widget-wrap li .gdlr-core-twitter-widget-date a:hover {
    color: #b5b5b5;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list li a {
    color: #4c4c4c;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list li a:hover, ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list li.current-menu-item a, ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list li.current-menu-ancestor a {
    color: #968481;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list li a {
    border-color: #776565;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list li a:before {
    color: #776565;
}

.inteco-footer-wrapper {
    background-color: #1d1d1d;
}

.inteco-footer-wrapper .inteco-widget-title {
    color: #ffffff;
}

.inteco-footer-wrapper h1, .inteco-footer-wrapper h3, .inteco-footer-wrapper h3, .inteco-footer-wrapper h4, .inteco-footer-wrapper h5, .inteco-footer-wrapper h6 {
    color: #ffffff;
}

.inteco-footer-wrapper {
    color: #ababab;
}

.inteco-footer-wrapper .widget_recent_entries ul li:before, .inteco-footer-wrapper .widget_recent_comments ul li:before, .inteco-footer-wrapper .widget_pages ul li:before, .inteco-footer-wrapper .widget_rss ul li:before, .inteco-footer-wrapper .widget_archive ul li:before, .inteco-footer-wrapper .widget_categories ul li:before, .widget_nav_menu ul li:before, .inteco-footer-wrapper .widget_meta ul li:before {
    color: #ababab;
}

.inteco-footer-wrapper a {
    color: #b6b6b6;
}

.inteco-footer-wrapper a:hover {
    color: #ffffff;
}

.inteco-footer-wrapper, .inteco-footer-wrapper * {
    border-color: #444444;
}

.inteco-copyright-wrapper {
    background-color: #111111;
}

.inteco-copyright-wrapper {
    color: #af938f;
}

.inteco-copyright-wrapper a {
    color: #838383;
}

.inteco-copyright-wrapper a:hover {
    color: #838383;
}

.inteco-footer-back-to-top-button {
    background-color: #dbdbdb;
}

.inteco-body .inteco-footer-back-to-top-button, .inteco-body .inteco-footer-back-to-top-button:hover {
    color: #313131;
}

.inteco-body .inteco-blog-title-wrap .inteco-single-article-title {
    color: #ffffff;
}

.inteco-blog-title-wrap .inteco-blog-info-wrapper, .inteco-blog-title-wrap .inteco-blog-info-wrapper a, .inteco-blog-title-wrap .inteco-blog-info-wrapper a:hover, .inteco-blog-title-wrap .inteco-blog-info-wrapper i {
    color: #ffffff;
}

.inteco-blog-title-wrap .inteco-single-article-date-day {
    color: #ffffff;
}

.inteco-blog-title-wrap .inteco-single-article-date-month, .inteco-single-article-date-wrapper .inteco-single-article-date-year {
    color: #b1b1b1;
}

.inteco-blog-title-wrap .inteco-single-article-date-wrapper {
    border-color: #ffffff;
}

.inteco-blog-title-wrap .inteco-blog-title-overlay {
    background-color: #000000;
}

.inteco-blog-title-wrap.inteco-feature-image .inteco-blog-title-top-overlay {
    background: -webkit-linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    background: -o-linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    background: -moz-linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}

.inteco-blog-title-wrap.inteco-feature-image .inteco-blog-title-bottom-overlay {
    background: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    background: -o-linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    background: -moz-linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}

.inteco-single-author .inteco-single-author-title a, .inteco-single-author .inteco-single-author-title a:hover {
    color: #464646;
}

.inteco-single-author .inteco-single-author-caption {
    color: #b1b1b1;
}

.inteco-single-author-tags a, .inteco-single-author-tags a:hover {
    color: #af938f;
    border-color: #af938f;
}

.inteco-single-nav a, .inteco-single-nav a:hover {
    color: #af938f;
}

.inteco-blog-style-2 .inteco-single-nav-area-left .inteco-single-nav-area-overlay {
    background-color: #af938f;
}

.inteco-blog-style-2 .inteco-single-nav-area-right .inteco-single-nav-area-overlay {
    background-color: #af938f;
}

.inteco-blog-style-2 .inteco-single-related-post-wrap {
    background-color: #f5f5f5;
}

.inteco-single-magazine-author-tags a, .inteco-single-magazine-author-tags a:hover {
    color: #272727;
}

.inteco-single-magazine-author-tags a {
    background-color: #f2f2f2;
}

.inteco-blog-magazine .inteco-single-author {
    background-color: #f7f7f7;
}

.inteco-comments-area .inteco-comments-title, .inteco-comments-area .comment-author, .inteco-comments-area .comment-reply-title {
    color: #464646;
}

.inteco-comments-area .comment-respond {
    background-color: #ffffff;
}

.inteco-comments-area .comment-reply a, .inteco-comments-area .comment-reply a:hover {
    color: #bcbcbc;
}

.inteco-comments-area .comment-time a, .inteco-comments-area .comment-time a:hover {
    color: #b1b1b1;
}

.gdlr-core-blog-title a, .inteco-body .inteco-single-article-title, .inteco-body .inteco-single-article-title a {
    color: #191919;
}

.gdlr-core-blog-title a:hover, .inteco-body .inteco-single-article-title a:hover {
    color: #af938f;
}

.gdlr-core-sticky-banner, .inteco-sticky-banner {
    color: #444444;
}

.gdlr-core-sticky-banner, .inteco-sticky-banner {
    background-color: #f3f3f3;
}

.inteco-single-article .inteco-blog-info-wrapper, .inteco-single-article .inteco-blog-info-wrapper a, .inteco-single-article .inteco-blog-info-wrapper a:hover, .inteco-single-article .inteco-blog-info-wrapper i, .gdlr-core-blog-info-wrapper, .gdlr-core-blog-info-wrapper a, .gdlr-core-blog-info-wrapper a:hover, .gdlr-core-blog-info-wrapper i, .gdlr-core-blog-grid .gdlr-core-blog-info-date a {
    color: #959595;
}

.gdlr-core-blog-date-wrapper .gdlr-core-blog-date-day, .inteco-single-article .inteco-single-article-date-day {
    color: #353535;
}

.gdlr-core-blog-date-wrapper .gdlr-core-blog-date-month, .inteco-single-article .inteco-single-article-date-month {
    color: #8a8a8a;
}

.gdlr-core-blog-grid.gdlr-core-blog-grid-with-frame, .gdlr-core-blog-full-frame, .gdlr-core-blog-list-frame, .gdlr-core-blog-link-format {
    background-color: #ffffff;
}

.gdlr-core-opacity-on-hover {
    background: #000;
}

.gdlr-core-style-2 .gdlr-core-blog-thumbnail .gdlr-core-blog-info-category {
    background: #af938f;
}

.gdlr-core-blog-feature .gdlr-core-blog-info-category {
    background: #af938f;
}

.gdlr-core-recent-post-widget-thumbnail .gdlr-core-blog-info-category {
    background: #af938f;
}

.gdlr-core-blog-modern.gdlr-core-with-image .gdlr-core-blog-info-wrapper, .gdlr-core-blog-modern.gdlr-core-with-image .gdlr-core-blog-info-wrapper a, .gdlr-core-blog-modern.gdlr-core-with-image .gdlr-core-blog-info-wrapper i, .gdlr-core-blog-modern.gdlr-core-with-image .gdlr-core-blog-title a {
    color: #ffffff;
}

.gdlr-core-blog-modern.gdlr-core-with-image .gdlr-core-blog-content {
    color: #ffffff;
}

.gdlr-core-blog-metro.gdlr-core-with-image .gdlr-core-blog-info-wrapper, .gdlr-core-blog-metro.gdlr-core-with-image .gdlr-core-blog-info-wrapper a, .gdlr-core-blog-metro.gdlr-core-with-image .gdlr-core-blog-info-wrapper i, .gdlr-core-blog-metro.gdlr-core-with-image .gdlr-core-blog-title a {
    color: #ffffff;
}

.inteco-blog-aside-format .inteco-single-article-content, .gdlr-core-blog-aside-format {
    background-color: #af938f;
}

.inteco-blog-quote-format .inteco-single-article-content, .gdlr-core-blog-quote-format {
    background-color: #af938f;
}

.inteco-blog-aside-format .inteco-single-article-content, .gdlr-core-blog-aside-format .gdlr-core-blog-content {
    color: #ffffff;
}

.inteco-blog-quote-format .inteco-single-article-content blockquote, .inteco-blog-quote-format .inteco-single-article-content q, .inteco-blog-quote-format .inteco-single-article-content, .gdlr-core-blog-quote-format .gdlr-core-blog-content blockquote, .gdlr-core-blog-quote-format .gdlr-core-blog-content q, .gdlr-core-blog-quote-format .gdlr-core-blog-content {
    color: #ffffff;
}

.gdlr-core-pagination a {
    background-color: #f0f0f0;
}

body .page-links > a, body .nav-links > a, body .nav-links > span.dots {
    background-color: #f0f0f0;
}

.gdlr-core-pagination a {
    color: #828282;
}

body .page-links > a, body .nav-links > a, body .nav-links > span.dots {
    color: #828282;
}

.gdlr-core-pagination a:hover, .gdlr-core-pagination a.gdlr-core-active, .gdlr-core-pagination span {
    background-color: #937b79;
}

body .page-links > span, body .page-links > a:hover, body .nav-links > span.current, body .nav-links > a:hover {
    background-color: #937b79;
}

.gdlr-core-pagination a:hover, .gdlr-core-pagination a.gdlr-core-active, .gdlr-core-pagination span {
    color: #ffffff;
}

body .page-links > span, body .page-links > a:hover, body .nav-links > span.current, body .nav-links > a:hover {
    color: #ffffff;
}

.gdlr-core-pagination.gdlr-core-with-border a {
    color: #b4b4b4;
    border-color: #b4b4b4;
}

.gdlr-core-pagination.gdlr-core-style-plain a, .gdlr-core-pagination.gdlr-core-style-plain a:before, .gdlr-core-pagination.gdlr-core-style-plain span:before {
    color: #b4b4b4;
}

.gdlr-core-pagination.gdlr-core-with-border a:hover, .gdlr-core-pagination.gdlr-core-with-border a.gdlr-core-active, .gdlr-core-pagination.gdlr-core-with-border span {
    color: #424242;
    border-color: #424242;
}

.gdlr-core-pagination.gdlr-core-style-plain a:hover, .gdlr-core-pagination.gdlr-core-style-plain a.gdlr-core-active, .gdlr-core-pagination.gdlr-core-style-plain span {
    color: #424242;
}

.gdlr-core-body .gdlr-core-load-more-wrap .gdlr-core-load-more, .gdlr-core-body .gdlr-core-load-more-wrap .gdlr-core-load-more:hover {
    color: #968481;
}

.gdlr-core-body .gdlr-core-load-more-wrap .gdlr-core-load-more {
    border-color: #968481;
}

.gdlr-core-portfolio-single-nav, .gdlr-core-portfolio-single-nav a, .gdlr-core-portfolio-single-nav a:hover {
    color: #828282;
}

.gdlr-core-portfolio-grid.gdlr-core-style-with-frame .gdlr-core-portfolio-grid-frame, .gdlr-core-portfolio-grid2 {
    background-color: #ffffff;
}

.gdlr-core-portfolio-content-wrap .gdlr-core-portfolio-title a {
    color: #191919;
}

.gdlr-core-port-info-item .gdlr-core-port-info-key, .gdlr-core-port-info2 .gdlr-core-port-info2-key {
    color: #343434;
}

.gdlr-core-portfolio-content-wrap .gdlr-core-portfolio-info, .gdlr-core-portfolio-content-wrap .gdlr-core-portfolio-info a, .gdlr-core-portfolio-content-wrap .gdlr-core-portfolio-info a:hover {
    color: #b1b1b1;
}

.gdlr-core-portfolio-grid2 .gdlr-core-portfolio-content-wrap .gdlr-core-portfolio-info {
    background-color: #af938f;
}

.gdlr-core-portfolio-badge {
    color: #0a0a0a;
}

.gdlr-core-portfolio-badge {
    background-color: #af938f;
}

.gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-icon, .gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-title a, .gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-title a:hover {
    color: #ffffff;
}

.gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-info, .gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-info a, .gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-info a:hover {
    color: #cecece;
}

.gdlr-core-filterer-wrap.gdlr-core-style-text a {
    color: #aaaaaa;
}

.gdlr-core-filterer-wrap.gdlr-core-style-text a:hover, .gdlr-core-filterer-wrap.gdlr-core-style-text a.gdlr-core-active {
    color: #161616;
}

.gdlr-core-filterer-wrap.gdlr-core-style-text .gdlr-core-filterer-slide-bar {
    border-bottom-color: #161616
}

.gdlr-core-filterer-wrap.gdlr-core-style-button a {
    color: #838383;
}

.gdlr-core-filterer-wrap.gdlr-core-style-button a {
    background-color: #f1f1f1;
}

.gdlr-core-filterer-wrap.gdlr-core-style-button a:hover, .gdlr-core-filterer-wrap.gdlr-core-style-button a.gdlr-core-active {
    color: #191919;
}

.gdlr-core-filterer-wrap.gdlr-core-style-button a:hover, .gdlr-core-filterer-wrap.gdlr-core-style-button a.gdlr-core-active {
    background-color: #af938f;
}

.gdlr-core-price-table-item .gdlr-core-price-table {
    background-color: #ffffff;
}

.gdlr-core-price-table .gdlr-core-price-table-head {
    background-color: #3e3e3e;
    background: -webkit-linear-gradient(#3e3e3e, #3e3e3e);
    background: -o-linear-gradient(#3e3e3e, #3e3e3e);
    background: -moz-linear-gradient(#3e3e3e, #3e3e3e);
    background: linear-gradient(#3e3e3e, #3e3e3e);
}

.gdlr-core-price-table .gdlr-core-price-table-icon {
    color: #ffffff;
}

.gdlr-core-price-table .gdlr-core-price-table-title {
    color: #bfa4a4;
}

.gdlr-core-price-table .gdlr-core-price-table-caption {
    color: #ffffff;
}

.gdlr-core-price-table .gdlr-core-price-table-price {
    background-color: #f6f6f6;
}

.gdlr-core-price-table .gdlr-core-price-table-price-number, .gdlr-core-price-table .gdlr-core-price-prefix {
    color: #323232;
}

.gdlr-core-price-table .gdlr-core-price-suffix {
    color: #191919;
}

body .gdlr-core-price-table .gdlr-core-price-table-button, body .gdlr-core-price-table .gdlr-core-price-table-button:hover {
    color: #ffffff;
}

body .gdlr-core-price-table .gdlr-core-price-table-button, body .gdlr-core-price-table .gdlr-core-price-table-button:hover {
    background-color: #383838;
    background: -webkit-linear-gradient(#383838, #383838);
    background: -o-linear-gradient(#383838, #383838);
    background: -moz-linear-gradient(#383838, #383838);
    background: linear-gradient(#383838, #383838);
}

.gdlr-core-price-table .gdlr-core-price-table-content * {
    border-color: #e5e5e5;
}

.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-head {
    background-color: #af938f;
    background: -webkit-linear-gradient(#af938f, #af938f);
    background: -o-linear-gradient(#af938f, #af938f);
    background: -moz-linear-gradient(#af938f, #af938f);
    background: linear-gradient(#af938f, #af938f);
}

.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-icon {
    color: #ffffff;
}

.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-title {
    color: #ffffff;
}

.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-caption {
    color: #fff3f2;
}

.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-price {
    background-color: #f6f6f6;
}

.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-price-number, .gdlr-core-price-table .gdlr-core-price-prefix {
    color: #323232;
}

.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-suffix {
    color: #191919;
}

body .gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-button, body .gdlr-core-price-table .gdlr-core-price-table-button:hover {
    color: #ffffff;
}

body .gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-button, body .gdlr-core-price-table .gdlr-core-price-table-button:hover {
    background-color: #af938f;
    background: -webkit-linear-gradient(#af938f, #af938f);
    background: -o-linear-gradient(#af938f, #af938f);
    background: -moz-linear-gradient(#af938f, #af938f);
    background: linear-gradient(#af938f, #af938f);
}

.gdlr-core-accordion-style-icon .gdlr-core-accordion-item-icon, .gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-icon, .gdlr-core-toggle-box-style-icon .gdlr-core-toggle-box-item-icon, .gdlr-core-toggle-box-style-box-icon .gdlr-core-toggle-box-item-icon {
    color: #191919;
}

.gdlr-core-accordion-style-icon .gdlr-core-accordion-item-title .gdlr-core-head, .gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-title .gdlr-core-head, .gdlr-core-toggle-box-style-icon .gdlr-core-toggle-box-item-title .gdlr-core-head, .gdlr-core-toggle-box-style-box-icon .gdlr-core-toggle-box-item-title .gdlr-core-head {
    color: #191919;
}

.gdlr-core-accordion-style-icon .gdlr-core-accordion-item-title, .gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-title, .gdlr-core-toggle-box-style-icon .gdlr-core-toggle-box-item-title, .gdlr-core-toggle-box-style-box-icon .gdlr-core-toggle-box-item-title {
    color: #191919;
}

.gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-icon, .gdlr-core-toggle-box-style-box-icon .gdlr-core-toggle-box-item-icon {
    background-color: #f3f3f3;
}

.gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-icon, .gdlr-core-toggle-box-style-box-icon .gdlr-core-toggle-box-item-icon {
    border-color: #f3f3f3;
}

.gdlr-core-accordion-style-background-title-icon .gdlr-core-accordion-item-title:before, .gdlr-core-toggle-box-style-background-title-icon .gdlr-core-accordion-item-title:before {
    color: #191919;
}

.gdlr-core-accordion-style-background-title .gdlr-core-accordion-item-title .gdlr-core-head, .gdlr-core-accordion-style-background-title-icon .gdlr-core-accordion-item-title .gdlr-core-head, .gdlr-core-toggle-box-style-background-title .gdlr-core-toggle-box-item-title .gdlr-core-head, .gdlr-core-toggle-box-style-background-title-icon .gdlr-core-toggle-box-item-title .gdlr-core-head {
    color: #191919;
}

.gdlr-core-accordion-style-background-title .gdlr-core-accordion-item-title, .gdlr-core-accordion-style-background-title-icon .gdlr-core-accordion-item-title, .gdlr-core-toggle-box-style-background-title .gdlr-core-toggle-box-item-title, .gdlr-core-toggle-box-style-background-title-icon .gdlr-core-toggle-box-item-title {
    color: #191919;
}

.gdlr-core-accordion-style-background-title .gdlr-core-accordion-item-title, .gdlr-core-accordion-style-background-title-icon .gdlr-core-accordion-item-title, .gdlr-core-toggle-box-style-background-title .gdlr-core-toggle-box-item-title, .gdlr-core-toggle-box-style-background-title-icon .gdlr-core-toggle-box-item-title {
    background-color: #f3f3f3;
}

.gdlr-core-accordion-style-background-title-icon .gdlr-core-active .gdlr-core-accordion-item-title:before, .gdlr-core-toggle-box-style-background-title-icon .gdlr-core-active .gdlr-core-accordion-item-title:before {
    color: #191919;
}

.gdlr-core-accordion-style-background-title .gdlr-core-active .gdlr-core-accordion-item-title, .gdlr-core-accordion-style-background-title-icon .gdlr-core-active .gdlr-core-accordion-item-title, .gdlr-core-toggle-box-style-background-title .gdlr-core-active .gdlr-core-toggle-box-item-title, .gdlr-core-toggle-box-style-background-title-icon .gdlr-core-active .gdlr-core-toggle-box-item-title {
    color: #191919;
}

.gdlr-core-accordion-style-background-title .gdlr-core-active .gdlr-core-accordion-item-title, .gdlr-core-accordion-style-background-title-icon .gdlr-core-active .gdlr-core-accordion-item-title, .gdlr-core-toggle-box-style-background-title .gdlr-core-active .gdlr-core-toggle-box-item-title, .gdlr-core-toggle-box-style-background-title-icon .gdlr-core-active .gdlr-core-toggle-box-item-title {
    background-color: #f3f3f3;
}

.gdlr-core-audio, .gdlr-core-audio .mejs-container .mejs-controls {
    background-color: #e7e7e7;
}

.gdlr-core-audio .mejs-container .mejs-controls .mejs-volume-button:before, .gdlr-core-audio .mejs-container .mejs-controls .mejs-playpause-button:before, .gdlr-core-audio .mejs-container .mejs-controls .mejs-time {
    color: #202020;
}

.gdlr-core-audio .mejs-controls .mejs-time-rail .mejs-time-total, .gdlr-core-audio .mejs-controls .mejs-time-rail .mejs-time-loaded {
    background-color: #afafaf;
}

.gdlr-core-audio .mejs-controls .mejs-time-rail .mejs-time-current {
    background-color: #af938f;
}

.gdlr-core-audio .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total {
    background-color: #afafaf;
}

.gdlr-core-audio .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current {
    background-color: #646464;
}

.gdlr-core-alert-box-item .gdlr-core-alert-box-item-inner {
    background-color: #ef5e68;
}

.gdlr-core-alert-box-item .gdlr-core-alert-box-item-inner {
    border-color: #cd515a;
}

.gdlr-core-alert-box-item .gdlr-core-alert-box-item-inner {
    color: #ffffff;
}

.gdlr-core-alert-box-item .gdlr-core-alert-box-item-title {
    color: #ffffff;
}

blockquote, q {
    color: #777777;
}

blockquote, q, pre {
    background-color: #f5f5f5;
}

.inteco-body blockquote, .inteco-body q {
    border-color: #e2e2e2;
}

.gdlr-core-blockquote-item-quote {
    color: #af938f;
}

.gdlr-core-blockquote-item-content, .gdlr-core-blockquote-item-author, pre {
    color: #1c1c1c;
}

.gdlr-core-body .gdlr-core-load-more, .gdlr-core-body .gdlr-core-button, .inteco-body .inteco-button, .inteco-body input[type="button"], .inteco-body input[type="submit"] {
    color: #ffffff;
}

.gdlr-core-body .gdlr-core-load-more:hover, .gdlr-core-body .gdlr-core-button:hover {
    color: #ffffff;
}

.gdlr-core-body .gdlr-core-load-more, .gdlr-core-body .gdlr-core-button, .inteco-body .inteco-button, .inteco-body input[type="button"], .inteco-body input[type="submit"] {
    background-color: #af938f;
}

.gdlr-core-body .gdlr-core-load-more:hover, .gdlr-core-body .gdlr-core-button:hover {
    background-color: #2F2F2F;
}

.gdlr-core-body .gdlr-core-button-with-border {
    border-color: #383838;
}

.gdlr-core-body .gdlr-core-button-with-border.gdlr-core-button-transparent {
    color: #383838;
}

.gdlr-core-body .gdlr-core-button-with-border:hover {
    border-color: #000000;
}

.gdlr-core-body .gdlr-core-button-with-border.gdlr-core-button-transparent:hover {
    color: #000000;
}

.gdlr-core-body .gdlr-core-button.gdlr-core-button-gradient {
    background-color: #af938f;
    background: -webkit-linear-gradient(#dbc0ba, #af938f);
    background: -o-linear-gradient(#dbc0ba, #af938f);
    background: -moz-linear-gradient(#dbc0ba, #af938f);
    background: linear-gradient(#dbc0ba, #af938f);
}

.gdlr-core-body .gdlr-core-button.gdlr-core-button-gradient-v {
    background-color: #af938f;
    background: -webkit-linear-gradient(to right, #dbc0ba, #af938f);
    background: -o-linear-gradient(to right, #dbc0ba, #af938f);
    background: -moz-linear-gradient(to right, #dbc0ba, #af938f);
    background: linear-gradient(to right, #dbc0ba, #af938f);
}

.gdlr-core-call-to-action-item-title {
    color: #2c2c2c;
}

.gdlr-core-call-to-action-item-caption {
    color: #535353;
}

.gdlr-core-counter-item-top-text {
    color: #828282;
}

.gdlr-core-counter-item-top-icon {
    color: #393939;
}

.gdlr-core-counter-item-number {
    color: #393939;
}

.gdlr-core-counter-item-divider {
    border-color: #393939;
}

.gdlr-core-counter-item-bottom-text {
    color: #7d7d7d;
}

.gdlr-core-column-service-item .gdlr-core-column-service-icon {
    color: #9d9d9d;
}

.gdlr-core-column-service-item .gdlr-core-icon-style-round i {
    background-color: #f3f3f3;
}

.gdlr-core-column-service-item .gdlr-core-column-service-title {
    color: #383838;
}

.gdlr-core-column-service-item .gdlr-core-column-service-caption {
    color: #747474;
}

.gdlr-core-dropdown-tab .gdlr-core-dropdown-tab-title, .gdlr-core-dropdown-tab .gdlr-core-dropdown-tab-head-wrap {
    background-color: #af938f;
}

.gdlr-core-dropdown-tab .gdlr-core-dropdown-tab-title {
    color: #252525;
}

.gdlr-core-flipbox-item .gdlr-core-flipbox-front, .gdlr-core-flipbox-item .gdlr-core-flipbox-back, .gdlr-core-feature-box-item .gdlr-core-feature-box {
    background-color: #af938f;
}

.gdlr-core-flipbox-item .gdlr-core-flipbox-front, .gdlr-core-flipbox-item .gdlr-core-flipbox-back, .gdlr-core-flipbox-item .gdlr-core-flipbox-frame, .gdlr-core-feature-box-item .gdlr-core-feature-box, .gdlr-core-feature-box-item .gdlr-core-feature-box-frame {
    border-color: #9b807f;
}

.gdlr-core-flipbox-item .gdlr-core-flipbox-item-icon, .gdlr-core-feature-box-item .gdlr-core-feature-box-item-icon {
    color: #ffffff;
}

.gdlr-core-flipbox-item .gdlr-core-flipbox-item-title, .gdlr-core-feature-box-item .gdlr-core-feature-box-item-title {
    color: #ffffff;
}

.gdlr-core-flipbox-item .gdlr-core-flipbox-item-caption, .gdlr-core-feature-box-item .gdlr-core-feature-box-item-caption {
    color: #ffffff;
}

.gdlr-core-flipbox-item .gdlr-core-flipbox-item-content, .gdlr-core-feature-box-item .gdlr-core-feature-box-item-content {
    color: #ffffff;
}

.gdlr-core-image-overlay.gdlr-core-gallery-image-overlay .gdlr-core-image-overlay-title {
    color: #ffffff;
}

.gdlr-core-image-overlay.gdlr-core-gallery-image-overlay .gdlr-core-image-overlay-caption {
    color: #cecece;
}

.gdlr-core-image-overlay {
    background-color: #000000;
    background-color: rgba(0, 0, 0, 0.6);
}

.gdlr-core-image-overlay-content, .gdlr-core-image-overlay-content a, .gdlr-core-image-overlay-icon {
    color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin] .gdlr-core-image-overlay-icon, .gdlr-core-page-builder-body .gdlr-core-pbf-column[data-skin] .gdlr-core-image-overlay-icon {
    color: #ffffff;
}

.gdlr-core-image-overlay.gdlr-core-round-icon .gdlr-core-image-overlay-icon {
    background-color: #ffffff;
}

.gdlr-core-body .gdlr-core-image-item-wrap {
    border-color: #000000;
}

.gdlr-core-block-item-title-wrap .gdlr-core-block-item-title {
    color: #343434;
}

.gdlr-core-block-item-title-wrap .gdlr-core-block-item-caption {
    color: #747474;
}

.gdlr-core-block-item-title-wrap a, .gdlr-core-block-item-title-wrap a:hover {
    color: #747474;
}

.gdlr-core-icon-list-item i {
    color: #222222;
}

.gdlr-core-icon-list-with-background-round .gdlr-core-icon-list-icon-wrap, .gdlr-core-icon-list-with-background-circle .gdlr-core-icon-list-icon-wrap {
    color: #f3f3f3;
}

.gdlr-core-opening-hour-item .gdlr-core-opening-hour-day {
    color: #a5a5a5;
}

.gdlr-core-opening-hour-item .gdlr-core-opening-hour-open {
    color: #262626;
}

.gdlr-core-opening-hour-item .gdlr-core-opening-hour-close {
    color: #c8c8c8;
}

.gdlr-core-opening-hour-item .gdlr-core-opening-hour-time i {
    color: #8a8989;
}

.gdlr-core-opening-hour-item .gdlr-core-opening-hour-list-item {
    border-color: #a6a6a6;
}

.gdlr-core-personnel-style-grid .gdlr-core-personnel-list-title, .gdlr-core-personnel-style-grid .gdlr-core-personnel-list-title a {
    color: #383838;
}

.gdlr-core-personnel-style-grid .gdlr-core-personnel-list-position {
    color: #9a827d;
}

.gdlr-core-personnel-style-grid .gdlr-core-personnel-list-divider {
    color: #9a827d;
}

.gdlr-core-personnel-style-grid.gdlr-core-with-background .gdlr-core-personnel-list-content-wrap {
    background-color: #ffffff;
}

.gdlr-core-personnel-style-modern .gdlr-core-personnel-list-title, .gdlr-core-personnel-style-modern .gdlr-core-personnel-list-title a {
    color: #ffffff;
}

.gdlr-core-personnel-style-modern .gdlr-core-personnel-list-position {
    color: #ffffff;
}

.gdlr-core-personnel-style-modern .gdlr-core-social-network-item a {
    color: #ffffff;
}

.gdlr-core-promo-box-item .gdlr-core-promo-box-item-title {
    color: #383838;
}

.gdlr-core-promo-box-item .gdlr-core-promo-box-content-wrap {
    border-color: #e8e7e7;
}

.gdlr-core-post-slider-item .gdlr-core-post-slider-title a {
    color: #ffffff;
}

.gdlr-core-post-slider-item .gdlr-core-blog-info, .gdlr-core-post-slider-item .gdlr-core-blog-info a {
    color: #c5c5c5;
}

.gdlr-core-roadmap-item .gdlr-core-roadmap-item-head-title {
    color: #d8c0b1;
}

.gdlr-core-roadmap-item-head.gdlr-core-active .gdlr-core-roadmap-item-head-title {
    color: #ffffff;
}

.gdlr-core-roadmap-item .gdlr-core-roadmap-item-head-caption {
    color: #ddc5b5;
}

.gdlr-core-roadmap-item .gdlr-core-roadmap-item-head-count {
    color: #c5c5c5;
}

.gdlr-core-roadmap-item .gdlr-core-roadmap-item-head-count {
    background-color: #af938f;
}

.gdlr-core-roadmap-item-head.gdlr-core-active .gdlr-core-roadmap-item-head-count {
    color: #af938f;
}

.gdlr-core-roadmap-item-head.gdlr-core-active .gdlr-core-roadmap-item-head-count {
    color: #ffffff;
}

.gdlr-core-roadmap-item .gdlr-core-roadmap-item-head-divider {
    border-color: #ffffff;
}

.gdlr-core-roadmap-item .gdlr-core-roadmap-item-content-title {
    color: #ffffff;
}

.gdlr-core-roadmap-item .gdlr-core-roadmap-item-content-caption {
    color: #ddc5b5;
}

.gdlr-core-roadmap-item .gdlr-core-roadmap-item-content {
    color: #ddc5b5;
}

.gdlr-core-skill-bar-item .gdlr-core-skill-bar-title, .gdlr-core-skill-bar-item .gdlr-core-skill-bar-right {
    color: #505050;
}

.gdlr-core-skill-bar-item .gdlr-core-skill-bar-icon {
    color: #616161;
}

.gdlr-core-skill-bar-item .gdlr-core-skill-bar-progress {
    background-color: #f3f3f3;
}

.gdlr-core-skill-bar-item .gdlr-core-skill-bar-filled {
    background-color: #af938f;
}

.gdlr-core-flexslider-nav .flex-direction-nav li a, .gdlr-core-flexslider.gdlr-core-nav-style-middle-large .flex-direction-nav li a {
    color: #0c0c0c;
    border-color: #0c0c0c;
}

.gdlr-core-flexslider-nav.gdlr-core-round-style li a, .gdlr-core-flexslider-nav.gdlr-core-rectangle-style li a {
    background-color: #f1f1f1;
}

.gdlr-core-flexslider .flex-control-nav li a {
    border-color: #8a8a8a;
}

.gdlr-core-flexslider .flex-control-nav li a.flex-active {
    background-color: #8a8a8a;
}

.gdlr-core-flexslider.gdlr-core-bullet-style-cylinder .flex-control-nav li a.flex-active {
    background-color: #8a8a8a;
}

.gdlr-core-flexslider.gdlr-core-bullet-style-cylinder .flex-control-nav li a {
    background-color: #dfdfdf;
}

.gdlr-core-social-share-item a {
    color: #202020;
}

.gdlr-core-social-share-item.gdlr-core-style-round a, .gdlr-core-social-share-item.gdlr-core-style-round a:hover {
    background-color: #202020;
}

.gdlr-core-social-share-item .gdlr-core-divider {
    border-color: #e5e5e5;
}

.gdlr-core-social-share-item .gdlr-core-social-share-count {
    color: #202020;
}

.gdlr-core-stunning-text-item-caption {
    color: #747474;
}

.gdlr-core-stunning-text-item-title {
    color: #383838;
}

.gdlr-core-tab-item-title {
    color: #8d8d8d;
}

.gdlr-core-tab-style1-horizontal .gdlr-core-tab-item-title, .gdlr-core-tab-style1-vertical .gdlr-core-tab-item-title {
    background-color: #f7f7f7;
}

.gdlr-core-tab-item-title-wrap, .gdlr-core-tab-item-content-wrap, .gdlr-core-tab-item-title {
    border-color: #ebebeb;
}

.gdlr-core-tab-item-title-line {
    border-color: #af938f;
}

.gdlr-core-tab-item-title.gdlr-core-active {
    color: #464646;
}

.gdlr-core-tab-style1-horizontal .gdlr-core-tab-item-title.gdlr-core-active, .gdlr-core-tab-style1-vertical .gdlr-core-tab-item-title.gdlr-core-active {
    background-color: #ffffff;
}

table tr th {
    background-color: #af938f;
}

table tr th, .inteco-body table tr th a, .inteco-body table tr th a:hover {
    color: #ffffff;
}

table tr:nth-child(odd) {
    background-color: #f9f9f9;
}

table tr:nth-child(odd) {
    color: #949494;
}

table tr:nth-child(even) {
    background-color: #f3f3f3;
}

table tr:nth-child(even) {
    color: #949494;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-item-title {
    color: #232323;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-content {
    color: #707070;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-title {
    color: #3b3b3b;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-position .gdlr-core-rating i {
    color: #ffa127;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-position {
    color: #3b3b3b;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-quote {
    color: #282828;
}

.gdlr-core-title-item .gdlr-core-title-item-title, .gdlr-core-title-item .gdlr-core-title-item-title a {
    color: #161616;
}

.gdlr-core-title-item .gdlr-core-title-item-caption {
    color: #747474;
}

.woocommerce .star-rating span, .single-product.woocommerce #review_form #respond p.stars a, .single-product.woocommerce div.product .product_meta, .single-product.woocommerce div.product .product_meta a {
    color: #bd5151;
}

.woocommerce #respond input#submit.alt, .woocommerce a.button.alt, .woocommerce button.button.alt, .woocommerce input.button.alt, .woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button, html .woocommerce input.button, html .woocommerce span.onsale {
    background-color: #bd5151;
}

span.woocommerce-Price-amount.amount {
    color: #707070;
}

.woocommerce .price del, .gdlr-core-product-price del, del span.woocommerce-Price-amount.amount {
    color: #949494;
}

.woocommerce #respond input#submit.alt:hover, .woocommerce a.button.alt:hover, .woocommerce button.button.alt:hover, .woocommerce input.button.alt:hover, .woocommerce #respond input#submit:hover, .woocommerce a.button:hover, .woocommerce button.button:hover, .woocommerce input.button:hover {
    background-color: #af938f;
}

.woocommerce #respond input#submit.alt, .woocommerce a.button.alt, .woocommerce button.button.alt, .woocommerce input.button.alt, .woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button, .woocommerce input.button, .woocommerce #respond input#submit.disabled, .woocommerce #respond input#submit:disabled, .woocommerce #respond input#submit:disabled[disabled], .woocommerce a.button.disabled, .woocommerce a.button:disabled, .woocommerce a.button:disabled[disabled], .woocommerce button.button.disabled, .woocommerce button.button:disabled, .woocommerce button.button:disabled[disabled], .woocommerce input.button.disabled, .woocommerce input.button:disabled, .woocommerce input.button:disabled[disabled] {
    color: #ffffff;
}

.woocommerce #respond input#submit.alt:hover, .woocommerce a.button.alt:hover, .woocommerce button.button.alt:hover, .woocommerce input.button.alt:hover, .woocommerce #respond input#submit:hover, .woocommerce a.button:hover, .woocommerce button.button:hover, .woocommerce input.button:hover {
    color: #ffffff;
}

.single-product.woocommerce div.product .quantity .qty, #add_payment_method #payment, .woocommerce-checkout #payment, .single-product.woocommerce #reviews #comments ol.commentlist li {
    background-color: #f3f3f3;
}

.gdlr-core-product-grid .gdlr-core-product-title a {
    color: #191919;
}

.gdlr-core-product-grid .gdlr-core-product-title a:hover {
    color: #434343;
}

.gdlr-core-product-thumbnail .gdlr-core-product-view-detail, .gdlr-core-product-thumbnail .gdlr-core-product-view-detail:hover {
    color: #ffffff;
}

.gdlr-core-product-thumbnail .gdlr-core-product-view-detail {
    background-color: #000000;
    background-color: rgba(0, 0, 0, 0.9);
}

.gdlr-core-product-thumbnail .added_to_cart, .gdlr-core-product-thumbnail .added_to_cart:hover, .gdlr-core-product-thumbnail .gdlr-core-product-add-to-cart, .gdlr-core-product-thumbnail .gdlr-core-product-add-to-cart:hover {
    color: #ffffff;
}

.gdlr-core-product-thumbnail .added_to_cart, .gdlr-core-product-thumbnail .gdlr-core-product-add-to-cart {
    background-color: #af938f;
    background-color: rgba(175, 147, 143, 0.9);
}

.woocommerce .widget_price_filter .price_slider_wrapper .ui-widget-content {
    background-color: #e6e6e6;
}

.woocommerce .widget_price_filter .ui-slider .ui-slider-range {
    background-color: #af938f;
}

.woocommerce .widget_price_filter .ui-slider .ui-slider-handle {
    background-color: #8c7373;
}

.gdlr-core-body .gdlr-core-privacy-box-wrap .gdlr-core-privacy-box-button {
    background: #1a49a8;
}

.gdlr-core-gdpr-form-checkbox:checked + .gdlr-core-gdpr-form-checkbox-appearance .gdlr-core-gdpr-form-checkbox-button {
    background-color: #1a49a8;
}

.gdlr-core-gdpr-form-checkbox:checked + .gdlr-core-gdpr-form-checkbox-appearance + .gdlr-core-gdpr-form-checkbox-text, .gdlr-core-gdpr-form-checkbox-required + .gdlr-core-gdpr-form-checkbox-appearance + .gdlr-core-gdpr-form-checkbox-text {
    color: #1a49a8;
}

.gdlr-core-body .gdlr-core-gdpr-form-submit input[type="submit"] {
    background: #1a49a8;
}

/*--custom, do not reomve--*/
.gdlr-core-filterer-wrap.gdlr-core-style-text {
    font-family: "Arcamajora Bold";
}

.inteco-comments-area .comment-reply-title small {
    font-size: 13px;
    text-transform: none;
    letter-spacing: 1px;
    margin-top: 11px;
    display: block;
    margin-left: 0;
}

.inteco-comments-area a#cancel-comment-reply-link {
    color: #999;
}

.gdlr-core-page-builder-body [data-skin="White Text"] h1, .gdlr-core-page-builder-body [data-skin="White Text"] h2, .gdlr-core-page-builder-body [data-skin="White Text"] h3, .gdlr-core-page-builder-body [data-skin="White Text"] h4, .gdlr-core-page-builder-body [data-skin="White Text"] h5, .gdlr-core-page-builder-body [data-skin="White Text"] h6, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-title, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-title a {
    color: #ffffff
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-caption, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-caption a, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-caption a:hover {
    color: #a1a1a1
}

.gdlr-core-page-builder-body [data-skin="White Text"], .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-content {
    color: #ffffff
}

.gdlr-core-page-builder-body [data-skin="White Text"] i, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-icon, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-icon:before, .gdlr-core-page-builder-body [data-skin="White Text"] .inteco-widget ul li:before {
    color: #ffffff
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-blog-modern.gdlr-core-with-image .gdlr-core-blog-info-wrapper i {
    color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-flexslider-nav.gdlr-core-plain-circle-style li a {
    border-color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="White Text"] a, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-link {
    color: #af938f
}

.gdlr-core-page-builder-body [data-skin="White Text"] a:hover, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-link:hover {
    color: #c1a19e
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-e-background {
    background-color: #111111
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-flexslider-nav.gdlr-core-round-style li a, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-flexslider-nav.gdlr-core-rectangle-style li a {
    background-color: #111111;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-flexslider .flex-control-nav li a {
    border-color: #111111;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-flexslider .flex-control-nav li a.flex-active {
    background-color: #111111;
}

.gdlr-core-page-builder-body [data-skin="White Text"] input:not([type="button"]):not([type="submit"]):not([type="reset"]), .gdlr-core-page-builder-body [data-skin="White Text"] textarea {
    background-color: #111111;
}

.gdlr-core-page-builder-body [data-skin="Black Text"] h1, .gdlr-core-page-builder-body [data-skin="Black Text"] h2, .gdlr-core-page-builder-body [data-skin="Black Text"] h3, .gdlr-core-page-builder-body [data-skin="Black Text"] h4, .gdlr-core-page-builder-body [data-skin="Black Text"] h5, .gdlr-core-page-builder-body [data-skin="Black Text"] h6, .gdlr-core-page-builder-body [data-skin="Black Text"] .gdlr-core-skin-title, .gdlr-core-page-builder-body [data-skin="Black Text"] .gdlr-core-skin-title a {
    color: #000000
}

.gdlr-core-page-builder-body [data-skin="Black Text"], .gdlr-core-page-builder-body [data-skin="Black Text"] .gdlr-core-skin-content {
    color: #000000
}

.gdlr-core-page-builder-body [data-skin="Black Text"] i, .gdlr-core-page-builder-body [data-skin="Black Text"] .gdlr-core-skin-icon, .gdlr-core-page-builder-body [data-skin="Black Text"] .gdlr-core-skin-icon:before, .gdlr-core-page-builder-body [data-skin="Black Text"] .inteco-widget ul li:before {
    color: #0a0a0a
}

.gdlr-core-page-builder-body [data-skin="Black Text"] .gdlr-core-blog-modern.gdlr-core-with-image .gdlr-core-blog-info-wrapper i {
    color: #0a0a0a;
}

.gdlr-core-page-builder-body [data-skin="Black Text"] .gdlr-core-flexslider-nav.gdlr-core-plain-circle-style li a {
    border-color: #0a0a0a;
}

.gdlr-core-page-builder-body [data-skin="Newsletter"] .gdlr-core-skin-e-background {
    background-color: #ffffff
}

.gdlr-core-page-builder-body [data-skin="Newsletter"] .gdlr-core-flexslider-nav.gdlr-core-round-style li a, .gdlr-core-page-builder-body [data-skin="Newsletter"] .gdlr-core-flexslider-nav.gdlr-core-rectangle-style li a {
    background-color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Newsletter"] .gdlr-core-flexslider .flex-control-nav li a {
    border-color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Newsletter"] .gdlr-core-flexslider .flex-control-nav li a.flex-active {
    background-color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Newsletter"] input:not([type="button"]):not([type="submit"]):not([type="reset"]), .gdlr-core-page-builder-body [data-skin="Newsletter"] textarea {
    background-color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="team column svc  icon"] h1, .gdlr-core-page-builder-body [data-skin="team column svc  icon"] h2, .gdlr-core-page-builder-body [data-skin="team column svc  icon"] h3, .gdlr-core-page-builder-body [data-skin="team column svc  icon"] h4, .gdlr-core-page-builder-body [data-skin="team column svc  icon"] h5, .gdlr-core-page-builder-body [data-skin="team column svc  icon"] h6, .gdlr-core-page-builder-body [data-skin="team column svc  icon"] .gdlr-core-skin-title, .gdlr-core-page-builder-body [data-skin="team column svc  icon"] .gdlr-core-skin-title a {
    color: #968481
}

.gdlr-core-page-builder-body [data-skin="Skin Number"] h1, .gdlr-core-page-builder-body [data-skin="Skin Number"] h2, .gdlr-core-page-builder-body [data-skin="Skin Number"] h3, .gdlr-core-page-builder-body [data-skin="Skin Number"] h4, .gdlr-core-page-builder-body [data-skin="Skin Number"] h5, .gdlr-core-page-builder-body [data-skin="Skin Number"] h6, .gdlr-core-page-builder-body [data-skin="Skin Number"] .gdlr-core-skin-title, .gdlr-core-page-builder-body [data-skin="Skin Number"] .gdlr-core-skin-title a {
    color: #000000
}

.gdlr-core-page-builder-body [data-skin="Skin Number"] .gdlr-core-skin-title a:hover {
    color: #8d8d8d
}

.gdlr-core-page-builder-body [data-skin="Skin Number"] .gdlr-core-skin-caption, .gdlr-core-page-builder-body [data-skin="Skin Number"] .gdlr-core-skin-caption a, .gdlr-core-page-builder-body [data-skin="Skin Number"] .gdlr-core-skin-caption a:hover {
    color: #8d8d8d
}

.gdlr-core-page-builder-body [data-skin="Skin Number"], .gdlr-core-page-builder-body [data-skin="Skin Number"] .gdlr-core-skin-content {
    color: #8d8d8d
}

.gdlr-core-page-builder-body [data-skin="Skin Number"] i, .gdlr-core-page-builder-body [data-skin="Skin Number"] .gdlr-core-skin-icon, .gdlr-core-page-builder-body [data-skin="Skin Number"] .gdlr-core-skin-icon:before, .gdlr-core-page-builder-body [data-skin="Skin Number"] .inteco-widget ul li:before {
    color: #8d8d8d
}

.gdlr-core-page-builder-body [data-skin="Skin Number"] .gdlr-core-blog-modern.gdlr-core-with-image .gdlr-core-blog-info-wrapper i {
    color: #8d8d8d;
}

.gdlr-core-page-builder-body [data-skin="Skin Number"] .gdlr-core-flexslider-nav.gdlr-core-plain-circle-style li a {
    border-color: #8d8d8d;
}

.gdlr-core-page-builder-body [data-skin="Skin Number"] a, .gdlr-core-page-builder-body [data-skin="Skin Number"] .gdlr-core-skin-link {
    color: #8d8d8d
}

.gdlr-core-page-builder-body [data-skin="Skin Number"] a:hover, .gdlr-core-page-builder-body [data-skin="Skin Number"] .gdlr-core-skin-link:hover {
    color: #8d8d8d
}

.gdlr-core-page-builder-body [data-skin="hp2 skin"] h1, .gdlr-core-page-builder-body [data-skin="hp2 skin"] h2, .gdlr-core-page-builder-body [data-skin="hp2 skin"] h3, .gdlr-core-page-builder-body [data-skin="hp2 skin"] h4, .gdlr-core-page-builder-body [data-skin="hp2 skin"] h5, .gdlr-core-page-builder-body [data-skin="hp2 skin"] h6, .gdlr-core-page-builder-body [data-skin="hp2 skin"] .gdlr-core-skin-title, .gdlr-core-page-builder-body [data-skin="hp2 skin"] .gdlr-core-skin-title a {
    color: #f6f6f6
}

.gdlr-core-page-builder-body [data-skin="hp2 skin"] .gdlr-core-skin-caption, .gdlr-core-page-builder-body [data-skin="hp2 skin"] .gdlr-core-skin-caption a, .gdlr-core-page-builder-body [data-skin="hp2 skin"] .gdlr-core-skin-caption a:hover {
    color: #f6f6f6
}

.gdlr-core-page-builder-body [data-skin="hp2 skin"], .gdlr-core-page-builder-body [data-skin="hp2 skin"] .gdlr-core-skin-content {
    color: #f6f6f6
}

.gdlr-core-page-builder-body [data-skin="hp2 skin"] i, .gdlr-core-page-builder-body [data-skin="hp2 skin"] .gdlr-core-skin-icon, .gdlr-core-page-builder-body [data-skin="hp2 skin"] .gdlr-core-skin-icon:before, .gdlr-core-page-builder-body [data-skin="hp2 skin"] .inteco-widget ul li:before {
    color: #f6f6f6
}

.gdlr-core-page-builder-body [data-skin="hp2 skin"] .gdlr-core-blog-modern.gdlr-core-with-image .gdlr-core-blog-info-wrapper i {
    color: #f6f6f6;
}

.gdlr-core-page-builder-body [data-skin="hp2 skin"] .gdlr-core-flexslider-nav.gdlr-core-plain-circle-style li a {
    border-color: #f6f6f6;
}

.gdlr-core-page-builder-body [data-skin="Brown Divider"] *, .gdlr-core-page-builder-body [data-skin="Brown Divider"] .gdlr-core-skin-border {
    border-color: #9a827d
}

.gdlr-core-page-builder-body [data-skin="Brown Divider"] input:not([type="button"]):not([type="submit"]):not([type="reset"]) {
    border-color: #9a827d;
}

.gdlr-core-page-builder-body [data-skin="Dark Filter"], .gdlr-core-page-builder-body [data-skin="Dark Filter"] .gdlr-core-skin-content {
    color: #ffffff
}

.gdlr-core-page-builder-body [data-skin="Dark Filter"] a, .gdlr-core-page-builder-body [data-skin="Dark Filter"] .gdlr-core-skin-link {
    color: #5db0b8
}

.gdlr-core-page-builder-body [data-skin="Dark Filter"] *, .gdlr-core-page-builder-body [data-skin="Dark Filter"] .gdlr-core-skin-border {
    border-color: #5db0b8
}

.gdlr-core-page-builder-body [data-skin="Dark Filter"] input:not([type="button"]):not([type="submit"]):not([type="reset"]) {
    border-color: #5db0b8;
}

.gdlr-core-page-builder-body [data-skin="Dark Filter"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="Dark Filter"] .gdlr-core-button-color {
    color: #ffffff
}

.gdlr-core-page-builder-body [data-skin="Dark Filter"] input[type="button"], .gdlr-core-page-builder-body [data-skin="Dark Filter"] input[type="submit"] {
    color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Dark Filter"] .gdlr-core-pagination a {
    color: #ffffff
}

.gdlr-core-page-builder-body [data-skin="Dark Filter"] .gdlr-core-button:hover, .gdlr-core-page-builder-body [data-skin="Dark Filter"] .gdlr-core-button-color:hover, .gdlr-core-page-builder-body [data-skin="Dark Filter"] .gdlr-core-button-color.gdlr-core-active {
    color: #5db0b8
}

.gdlr-core-page-builder-body [data-skin="Dark Filter"] input[type="button"]:hover, .gdlr-core-page-builder-body [data-skin="Dark Filter"] input[type="submit"]:hover {
    color: #5db0b8;
}

.gdlr-core-page-builder-body [data-skin="Dark Filter"] .gdlr-core-pagination a:hover, .gdlr-core-page-builder-body [data-skin="Dark Filter"] .gdlr-core-pagination a.gdlr-core-active, .gdlr-core-page-builder-body [data-skin="Dark Filter"] .gdlr-core-pagination span {
    color: #5db0b8
}

.gdlr-core-page-builder-body [data-skin="Dark Filter"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="Dark Filter"] .gdlr-core-button-color {
    border-color: #5db0b8
}

.gdlr-core-page-builder-body [data-skin="Dark Filter"] .gdlr-core-pagination a {
    border-color: #5db0b8
}

.gdlr-core-page-builder-body [data-skin="Dark Personnel"] h1, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] h2, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] h3, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] h4, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] h5, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] h6, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-skin-title, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-skin-title a {
    color: #ffffff
}

.gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-skin-caption, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-skin-caption a, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-skin-caption a:hover {
    color: #5db0b8
}

.gdlr-core-page-builder-body [data-skin="Dark Personnel"] i, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-skin-icon, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-skin-icon:before, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] .inteco-widget ul li:before {
    color: #ffffff
}

.gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-blog-modern.gdlr-core-with-image .gdlr-core-blog-info-wrapper i {
    color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-flexslider-nav.gdlr-core-plain-circle-style li a {
    border-color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Dark Personnel"] a, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-skin-link {
    color: #ffffff
}

.gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-skin-divider {
    border-color: #5db0b8;
    column-rule-color: #5db0b8;
    -moz-column-rule-color: #5db0b8;
    -webkit-column-rule-color: #5db0b8;
}

.gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-flexslider.gdlr-core-nav-style-middle-large .flex-direction-nav li a {
    border-color: #5db0b8
}

.gdlr-core-page-builder-body [data-skin="Blog Dark"] h1, .gdlr-core-page-builder-body [data-skin="Blog Dark"] h2, .gdlr-core-page-builder-body [data-skin="Blog Dark"] h3, .gdlr-core-page-builder-body [data-skin="Blog Dark"] h4, .gdlr-core-page-builder-body [data-skin="Blog Dark"] h5, .gdlr-core-page-builder-body [data-skin="Blog Dark"] h6, .gdlr-core-page-builder-body [data-skin="Blog Dark"] .gdlr-core-skin-title, .gdlr-core-page-builder-body [data-skin="Blog Dark"] .gdlr-core-skin-title a {
    color: #5db0b8
}

.gdlr-core-page-builder-body [data-skin="Blog Dark"] .gdlr-core-skin-caption, .gdlr-core-page-builder-body [data-skin="Blog Dark"] .gdlr-core-skin-caption a, .gdlr-core-page-builder-body [data-skin="Blog Dark"] .gdlr-core-skin-caption a:hover {
    color: #ffffff
}

.gdlr-core-page-builder-body [data-skin="Blog Dark"], .gdlr-core-page-builder-body [data-skin="Blog Dark"] .gdlr-core-skin-content {
    color: #b1b1b1
}

.gdlr-core-page-builder-body [data-skin="Blog Dark"] a, .gdlr-core-page-builder-body [data-skin="Blog Dark"] .gdlr-core-skin-link {
    color: #b1b1b1
}

.gdlr-core-page-builder-body [data-skin="Blog Dark"] a:hover, .gdlr-core-page-builder-body [data-skin="Blog Dark"] .gdlr-core-skin-link:hover {
    color: #ffffff
}

.gdlr-core-page-builder-body [data-skin="Blog Dark"] *, .gdlr-core-page-builder-body [data-skin="Blog Dark"] .gdlr-core-skin-border {
    border-color: #5db0b8
}

.gdlr-core-page-builder-body [data-skin="Blog Dark"] input:not([type="button"]):not([type="submit"]):not([type="reset"]) {
    border-color: #5db0b8;
}

.gdlr-core-page-builder-body [data-skin="Blog Dark"] .gdlr-core-skin-e-content {
    color: #ffffff
}

.gdlr-core-page-builder-body [data-skin="Blog Dark"] .gdlr-core-flexslider-nav.gdlr-core-round-style li a i, .gdlr-core-page-builder-body [data-skin="Blog Dark"] .gdlr-core-flexslider-nav.gdlr-core-rectangle-style li a i {
    color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Blog Dark"] input:not([type="button"]):not([type="submit"]):not([type="reset"]), .gdlr-core-page-builder-body [data-skin="Blog Dark"] textarea {
    color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Blog Dark"] ::-webkit-input-placeholder {
    color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Blog Dark"] ::-moz-placeholder {
    color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Blog Dark"] :-ms-input-placeholder {
    color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Blog Dark"] :-moz-placeholder {
    color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="HP5 Portfolio"], .gdlr-core-page-builder-body [data-skin="HP5 Portfolio"] .gdlr-core-skin-content {
    color: #8c8c8c
}

.gdlr-core-page-builder-body [data-skin="HP5 Portfolio"] a, .gdlr-core-page-builder-body [data-skin="HP5 Portfolio"] .gdlr-core-skin-link {
    color: #5db0b8
}

.gdlr-core-page-builder-body [data-skin="HP5 Portfolio"] *, .gdlr-core-page-builder-body [data-skin="HP5 Portfolio"] .gdlr-core-skin-border {
    border-color: #5db0b8
}

.gdlr-core-page-builder-body [data-skin="HP5 Portfolio"] input:not([type="button"]):not([type="submit"]):not([type="reset"]) {
    border-color: #5db0b8;
}

.gdlr-core-page-builder-body [data-skin="HP5 Portfolio"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="HP5 Portfolio"] .gdlr-core-button-color {
    color: #8c8c8c
}

.gdlr-core-page-builder-body [data-skin="HP5 Portfolio"] input[type="button"], .gdlr-core-page-builder-body [data-skin="HP5 Portfolio"] input[type="submit"] {
    color: #8c8c8c;
}

.gdlr-core-page-builder-body [data-skin="HP5 Portfolio"] .gdlr-core-pagination a {
    color: #8c8c8c
}

.gdlr-core-page-builder-body [data-skin="HP5 Portfolio"] .gdlr-core-button:hover, .gdlr-core-page-builder-body [data-skin="HP5 Portfolio"] .gdlr-core-button-color:hover, .gdlr-core-page-builder-body [data-skin="HP5 Portfolio"] .gdlr-core-button-color.gdlr-core-active {
    color: #5db0b8
}

.gdlr-core-page-builder-body [data-skin="HP5 Portfolio"] input[type="button"]:hover, .gdlr-core-page-builder-body [data-skin="HP5 Portfolio"] input[type="submit"]:hover {
    color: #5db0b8;
}

.gdlr-core-page-builder-body [data-skin="HP5 Portfolio"] .gdlr-core-pagination a:hover, .gdlr-core-page-builder-body [data-skin="HP5 Portfolio"] .gdlr-core-pagination a.gdlr-core-active, .gdlr-core-page-builder-body [data-skin="HP5 Portfolio"] .gdlr-core-pagination span {
    color: #5db0b8
}

.gdlr-core-page-builder-body [data-skin="HP5 Portfolio"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="HP5 Portfolio"] .gdlr-core-button-color {
    border-color: #5db0b8
}

.gdlr-core-page-builder-body [data-skin="HP5 Portfolio"] .gdlr-core-pagination a {
    border-color: #5db0b8
}

.gdlr-core-page-builder-body [data-skin="White form"] .gdlr-core-skin-e-background {
    background-color: #ffffff
}

.gdlr-core-page-builder-body [data-skin="White form"] .gdlr-core-flexslider-nav.gdlr-core-round-style li a, .gdlr-core-page-builder-body [data-skin="White form"] .gdlr-core-flexslider-nav.gdlr-core-rectangle-style li a {
    background-color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="White form"] .gdlr-core-flexslider .flex-control-nav li a {
    border-color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="White form"] .gdlr-core-flexslider .flex-control-nav li a.flex-active {
    background-color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="White form"] input:not([type="button"]):not([type="submit"]):not([type="reset"]), .gdlr-core-page-builder-body [data-skin="White form"] textarea {
    background-color: #ffffff;
}
//
//@font-face {
//    font-family: "ArcaMajora Bold";
//    src: url("../upload/ArcaMajora3-Bold.eot");
//    src: url("../upload/ArcaMajora3-Boldd41d.eot?#iefix") format("embedded-opentype"),
//    url("../upload/ArcaMajora3-Bold.ttf") format("truetype");
//    font-weight: normal;
//    font-style: normal;
//}
//
//@font-face {
//    font-family: "ArcaMajora Heavy";
//    src: url("../upload/ArcaMajora3-Heavy.eot");
//    src: url("../upload/ArcaMajora3-Heavyd41d.eot?#iefix") format("embedded-opentype"),
//    url("../upload/ArcaMajora3-Heavy.ttf") format("truetype");
//    font-weight: normal;
//    font-style: normal;
//}

.inteco-item-mgb, .gdlr-core-item-mgb {
    margin-bottom: 40px;
}
